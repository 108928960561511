import React, { useState } from "react";
import { AnnsModal } from "../../../components/Component";

const RecoCommentModal = ({ visible, item, onClosed }) => {
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const close = () => {
    onClosed();
  };

  const onFormSubmit = () => {};
  return (
    <>
      <AnnsModal
        size={"md"}
        isOpen={visible}
        loading={loading}
        toggle={() => close()}
        title={"Comment"}
        isFooterHide={true}
      >
        <div className="">
          <p className="m-0">{item}</p>
        </div>
      </AnnsModal>
    </>
  );
};

export default RecoCommentModal;
