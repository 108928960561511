import React, { useState } from "react";
import classNames from "classnames";
import ChatDropdown from "./dropdown/chat/Chat";
import Toggle from "../sidebar/Toggle";
import Logo from "../logo/Logo";
import Menu from "../menu/Menu";
import User from "./dropdown/user/User";
import MobileMenu from "../menu/MobileMenu";
// import menu from "../menu/MenuData";
import {
  LocationDropDown,
} from "../../components/Component";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { TransactionMoneyType } from "../../utils";
import {
  UserActionType,
  UserPermissionType,
  isAllowToAccess,
  menuItems,
} from "../../services/permission/permission";

const Header = ({
  fixed,
  theme,
  visibility,
  toggleSidebar,
  mobileView,
  className,
  ...props
}) => {
  const history = useHistory();
  const [userLocation, setUserLocation] = useState();
  const headerClass = classNames({
    "nk-header is-regular": true,
    "nk-header-fixed": fixed,
    [`is-light`]: theme === "white",
    [`is-${theme}`]: theme !== "white" && theme !== "light",
    [`${className}`]: className,
  });

  function showTransactionForm(type) {
    history.push({
      pathname: `${process.env.PUBLIC_URL}/add-transaction`,
      state: {
        type: type,
      },
    });
  }

  let items =  menuItems();
  return (
    <div className={headerClass}>
      <div className="container-fluid">
        <div className="nk-header-wrap">
          <div className="nk-menu-trigger me-sm-2 d-lg-none">
            <Toggle
              className="nk-nav-toggle nk-quick-nav-icon"
              icon="menu"
              click={toggleSidebar}
            />
          </div>
          <div className="nk-header-brand">
            <Logo />
          </div>
          <div
            className={`nk-header-menu ${mobileView ? "mobile-menu" : ""}  ${
              visibility ? "nk-header-active" : ""
            }`}
          >
            <div className="nk-header-mobile">
              <div className="nk-header-brand">
                <Logo />
              </div>
              <div className="nk-menu-trigger me-n2">
                <Toggle
                  className="nk-nav-toggle nk-quick-nav-icon"
                  icon="arrow-left"
                  click={toggleSidebar}
                />
              </div>
            </div>
            {mobileView ? (
              <MobileMenu
                data={items}
                sidebarToggle={toggleSidebar}
                mobileView={mobileView}
              />
            ) : (
              <Menu />
            )}
          </div>
          {visibility && (
            <div className="nk-header-overlay" onClick={toggleSidebar}></div>
          )}
          <div className="nk-header-tools">
            <ul className="nk-quick-nav position-relative">
              <li>
                <span className="">
                  <LocationDropDown
                    placeholder={"Locations"}
                    value={userLocation}
                    className="w-250px "
                    onSelect={(location) => {
                      setUserLocation(location);
                    }}
                  />
                </span>
              </li>
              {isAllowToAccess(
                UserPermissionType.transaction,
                UserActionType.add
              ) && (
                <li>
                  <UncontrolledDropdown direction="down">
                    <DropdownToggle className="btn-action" color="primary">
                      <span>Add Transaction</span>
                    </DropdownToggle>
                    <DropdownMenu>
                      <ul className="link-list-opt">
                        <li className="cursor-pointer ">
                          <DropdownItem
                            tag="a"
                            className="success-box"
                            onClick={(ev) => {
                              showTransactionForm(TransactionMoneyType.in);
                            }}
                          >
                            <span className="text-success">
                              {TransactionMoneyType.in}
                            </span>
                          </DropdownItem>
                        </li>
                        <li className="cursor-pointer ">
                          <DropdownItem
                            tag="a"
                            className="danger-box"
                            onClick={(ev) => {
                              showTransactionForm(TransactionMoneyType.out);
                            }}
                          >
                            <span className="text-danger">
                              {TransactionMoneyType.out}
                            </span>
                          </DropdownItem>
                        </li>
                        <li className="cursor-pointer ">
                          <DropdownItem
                            tag="a"
                            className="warning-box"
                            // href="#links"
                            onClick={(ev) => {
                              showTransactionForm(
                                TransactionMoneyType.transfer
                              );
                            }}
                          >
                            <span className="text-warning">
                              {TransactionMoneyType.transfer}
                            </span>
                          </DropdownItem>
                        </li>
                      </ul>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </li>
              )}
              <li className="user-dropdown">
                <User />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
