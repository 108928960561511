import React, { useState } from "react";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import {
  Block,
  BlockContent,
  BlockHead,
  BlockTitle,
  FormInput,
  Icon,
  PreviewCard,
  FormInputType,
  ActionButton,
  ErrorText,
} from "../../components/Component";
import { Form, Spinner, Alert } from "reactstrap";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { signIn } from "./AuthData";
import { windowReload } from "../../utils";
import { useDispatch } from "react-redux";
import { setLoggedInUser } from "../../store/authSlice";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [isDisble, setIsDisble] = useState(true);
  const [errorVal, setErrorVal] = useState("");
  const [authData, setAuthData] = useState({});
  let dispatch = useDispatch();
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      checkFields();
    }, 2000);

    return () => {
      setLoading();
      setIsDisble();
      setErrorVal();
      setAuthData();
    };
  }, []);

  useEffect(() => {
    checkFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authData]);

  function checkFields() {
    if (
      (authData?.email?.length ?? 0) === 0 ||
      (authData?.password?.length ?? 0) === 0 ||
      authData?.isEmailError === true
    ) {
      setIsDisble(true);
    } else {
      setIsDisble(false);
    }
  }

  const onFormSubmit = (ev) => {
    ev.preventDefault();
    if (
      authData?.email?.length === 0 ||
      authData?.password?.length === 0 ||
      authData?.isEmailError === true
    ) {
      setShowError(true);
      return;
    }
    setLoading(true);
    signIn(authData, (data, error) => {
      setLoading(false);
      if (error === null) {
        windowReload();
        dispatch(setLoggedInUser(data));
      } else {
        setErrorVal(error.message);
      }
    });
  };

  return (
    <React.Fragment>
      <Head title="Login" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <img
                className="logo-light logo-img logo-img-lg min-h-120"
                src={Logo}
                alt="logo"
              />
              <img
                className="logo-dark logo-img logo-img-lg min-h-120"
                src={LogoDark}
                alt="logo-dark"
              />
            </Link>
          </div>

          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            {/* <BlockHead className={""}> */}
            <BlockContent className={"mb-2"}>
              <BlockTitle tag="h4">Sign-In</BlockTitle>
            </BlockContent>
            {/* </BlockHead> */}
            {errorVal && (
              <div className="mb-3">
                <Alert color="danger" className="alert-icon">
                  {" "}
                  <Icon name="alert-circle" /> {errorVal}
                </Alert>
              </div>
            )}
            <Form className="">
              <FormInput
                label={"Email"}
                type={FormInputType.email}
                value={authData?.email}
                onError={(isError) => {
                  setAuthData((prev) => ({
                    ...prev,
                    isEmailError: isError,
                  }));
                }}
                onTextChange={(text) => {
                  setAuthData((prev) => ({
                    ...prev,
                    email: text,
                  }));
                }}
              />
              {showError && authData?.email?.length === 0 && (
                <ErrorText className={"mt-n2"}>
                  Please enter your email.
                </ErrorText>
              )}
              <FormInput
                label={"Password"}
                type={FormInputType.passwordWithoutValidation}
                value={authData?.password}
                onTextChange={(text) => {
                  setAuthData((prev) => ({
                    ...prev,
                    password: text,
                  }));
                }}
              />
              {showError && authData?.password?.length === 0 && (
                <ErrorText className={"mt-n2"}>
                  Please enter your password.
                </ErrorText>
              )}

              <div className="form-group">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="password"></label>
                  <Link
                    className="link link-primary link-sm"
                    to={`${process.env.PUBLIC_URL}/auth-reset`}
                  >
                    Forgot password?
                  </Link>
                </div>
              </div>

              <div className="form-group">
                <ActionButton
                  title="Sign in"
                  size="lg"
                  loading={loading}
                  // disabled={isDisble}
                  onClick={(ev) => onFormSubmit(ev)}
                />
              </div>
            </Form>
          </PreviewCard>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default Login;
