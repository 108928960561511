import React from "react";
import { Form, Row } from "reactstrap";
import { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  AnnsModal,
  Col,
  FormCheckbox,
  FormDatePicker,
  FormInput,
  FormInputType,
  IconButton,
  LocationSelectDropdown,
  PhoneInput,
  RadioButtonOptions,
} from "../../../components/Component";
import moment from "moment/moment";
import {
  AppText,
  RadioOptionTypes,
  ServerStatusType,
  showErrorToast,
  showSuccessToast,
} from "../../../utils";
import LeaveReasonDropdown from "../../../components/dropdowns/LeaveReasonDropdown";
import { fetchResidentDetail, fetchResidentSave } from "./ResidentData";

const AddResident = ({
  visible,
  carerId,
  item,
  onSaved,
  onClosed,
  start_Date,
}) => {
  const [isDisable, setIsDisable] = useState(false);
  const [residentData, setResidentData] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    reset();
    return () => {
      setResidentData();
      setIsDisable();
      setIsSaving();
    };
  }, []);

  useEffect(() => {
    if (item?.id && visible) {
      residentDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, visible]);

  useEffect(() => {
    let hasEmptyFields =
      residentData?.kinContacts?.some((item) => item?.name === "") ||
      residentData?.kinContacts?.some((item) => item?.phoneNumber === "") ||
      residentData?.kinContacts?.some((item) => item?.Address === "") ||
      residentData?.firstName === "" ||
      residentData?.lastName === "" ||
      residentData?.dob === null ||
      residentData?.residentNumber === "" ||
      residentData?.roomNo === "" ||
      residentData?.admissionDate === null;

    if (residentData?.isActive === false) {
      if (residentData?.Reason === "" || residentData?.Reason === null) {
        hasEmptyFields = true;
      }
    }
    setIsDisable(hasEmptyFields);
  }, [residentData]);

  const handleAddContact = () => {
    let kinContactsList = [...residentData?.kinContacts];
    kinContactsList.push({
      name: "",
      email: "",
      phoneNumber: "",
      Address: "",
      relationship: "",
      dob: "",
    });
    setResidentData({ ...residentData, kinContacts: kinContactsList });
  };

  const handleRemoveContact = (contactIndex) => {
    let kinContactsList = [...residentData?.kinContacts];
    kinContactsList.splice(contactIndex, 1);
    setResidentData({ ...residentData, kinContacts: kinContactsList });
  };

  const reset = () => {
    setResidentData({
      firstName: "",
      lastName: "",
      dob: null,
      admissionDate: null,
      residentNumber: "",
      roomNo: "",
      location: "",
      discription: "",
      kinContacts: [
        {
          name: "",
          email: "",
          phoneNumber: "",
          Address: "",
          relationship: "",
        },
      ],
      isActive: true,
      Reason: null,
      reasonDiscription: "",
      leaveDate: null,
    });
  };

  const onFormSubmit = () => {
    residentSave();
  };

  const close = () => {
    reset();
    onClosed();
  };

  // API------------------->

  const residentSave = () => {
    let convertedKins =
      (residentData?.kinContacts?.length ?? 0) > 0 &&
      residentData?.kinContacts.map((item) => {
        let object = {
          kinName: item?.name ?? "",
          kinRelation: item?.relationship ?? "",
          email: item?.email ?? "",
          countryCode: item?.phoneNumber?.dialCode ?? "",
          phone: item?.phoneNumber?.phoneNumber ?? "",
          address: item?.Address ?? "",
        };
        return object;
      });

    let params = {
      id: residentData?.id ?? null,
      firstName: residentData?.firstName ?? null,
      lastName: residentData?.lastName ?? null,
      birthDate: residentData?.dob
        ? moment(residentData?.dob).format("YYYY-MM-DD")
        : null,
      residentNo: residentData?.residentNumber ?? null,
      locationId: residentData?.location?.id,
      roomNo: residentData?.roomNo ?? null,
      description: residentData?.discription ?? null,
      admissionDate: residentData?.admissionDate
        ? moment(residentData?.admissionDate).format("YYYY-MM-DD")
        : null,
      kins: convertedKins,
      status: residentData?.isActive
        ? ServerStatusType?.active
        : ServerStatusType?.inactive,
      leaveReason:
        residentData?.isActive === false ? residentData?.Reason?.value : null,
      leaveDate: residentData?.leaveDate
        ? moment(residentData?.leaveDate).format("YYYY-MM-DD")
        : null,
      leaveDescription:
        residentData?.isActive === false
          ? residentData?.reasonDiscription
          : null,
    };
    setIsSaving(true);
    fetchResidentSave(params, (data, error) => {
      if (error === null) {
        let text =
          "Resident" +
          (item ? AppText.EditedSuccessfully : AppText.AddedSuccessfully);
        showSuccessToast(text);
        onSaved();
        close();
      } else {
        showErrorToast(error.message);
      }
      setIsSaving(false);
    });
  };

  const residentDetails = () => {
    let params = {
      residentId: item?.id ?? null,
    };
    fetchResidentDetail(params, (data, error) => {
      if (error === null) {
        if ((data?.kinContacts?.length ?? 0) === 0) {
          data.kinContacts = [
            {
              name: "",
              email: "",
              phoneNumber: "",
              Address: "",
              relationship: "",
            },
          ];
        }
        setResidentData(data);
      }
    });
  };


  return (
    <AnnsModal
      size={"lg"}
      isOpen={visible}
      className={"modal-dialog-scrollable"}
      toggle={() => close()}
      title={item ? "Edit resident" : "Add Resident"}
      onClick={() => onFormSubmit()}
      disabled={isDisable}
      loading={isSaving}
    >
      <Form className="row form-validation">
        <div className="">
          <p className="fs-5">Basic</p>
          <Row className="border rounded-2 mx-auto py-1">
            <Col className="col-md-6 mb-2">
              <FormInput
                label={"First Name"}
                required={true}
                type={FormInputType.name}
                value={residentData?.firstName}
                onError={(isError) => {}}
                onTextChange={(text) => {
                  setResidentData({
                    ...residentData,
                    firstName: text,
                  });
                }}
              />
            </Col>
            <Col className="col-md-6 mb-2">
              <FormInput
                label={"Last Name"}
                required={true}
                type={FormInputType.name}
                value={residentData?.lastName}
                onError={(isError) => {
                  // setResidentData({
                  //   ...residentData,
                  //   isFirstNameError: isError,
                  // });
                }}
                onTextChange={(text) => {
                  setResidentData({
                    ...residentData,
                    lastName: text,
                  });
                }}
              />
            </Col>

            <Col md="6" className={"mb-2"}>
              <div className="form-group">
                <label className="form-label">
                  Admission Date <span className="text-danger fs-6">*</span>
                </label>
                <div className="form-control-wrap">
                  <FormDatePicker
                    selected={residentData?.admissionDate}
                    onChange={(start) => {
                      setResidentData({
                        ...residentData,
                        admissionDate: start,
                      });
                    }}
                    // maxDate={new Date()}
                    // maxDate={moment().subtract(18, "years").toDate()}
                  />
                </div>
              </div>
            </Col>

            <Col md="6" className={"mb-2"}>
              <div className="form-group">
                <label className="form-label">
                  Date Of Birth <span className="text-danger fs-6">*</span>
                </label>
                <div className="form-control-wrap">
                  <FormDatePicker
                    selected={residentData?.dob}
                    onChange={(start) => {
                      setResidentData({
                        ...residentData,
                        dob: start,
                      });
                    }}
                    maxDate={new Date()}
                    // maxDate={moment().subtract(18, "years").toDate()}
                  />
                </div>
              </div>
            </Col>

            <Col className="col-md-6 mb-2">
              <FormInput
                label={"Resident Number"}
                required={true}
                type={FormInputType.default}
                maxLength={10}
                value={residentData?.residentNumber}
                onError={(isError) => {
                  // updateData("isFirstNameError", isError);
                }}
                onTextChange={(text) => {
                  setResidentData({
                    ...residentData,
                    residentNumber: text,
                  });
                }}
              />
            </Col>
            <Col className="col-md-6 mb-2">
              <FormInput
                label={"Room No"}
                required={true}
                type={FormInputType.default}
                maxLength={5}
                value={residentData?.roomNo}
                onError={(isError) => {}}
                onTextChange={(text) => {
                  setResidentData({
                    ...residentData,
                    roomNo: text,
                  });
                }}
              />
            </Col>
            <Col className="col-md-6 mb-2">
              <div className="form-group">
                <label className="form-label">
                  Select Location <span className="text-danger fs-6">*</span>
                </label>
                <div className="form-control-wrap">
                  <LocationSelectDropdown
                    value={residentData?.location ?? ""}
                    disabled={residentData?.id}
                    onSelect={(location) => {
                      setResidentData((prev) => ({
                        ...prev,
                        location: location,
                      }));
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col className="col-12 mb-2">
              <div className="form-group">
                <label className="form-label">Description</label>
                <div className="form-control-wrap">
                  <textarea
                    className="form-control"
                    placeholder="Description"
                    value={residentData?.discription}
                    onChange={(e) => {
                      setResidentData({
                        ...residentData,
                        discription: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="mt-4">
          <p className="fs-5">Next of kin</p>
          <Row className="border rounded-2 mx-auto py-1">
            {(residentData?.kinContacts?.length ?? 0) > 0 &&
              residentData?.kinContacts?.map((contact, contactIndex) => (
                <React.Fragment key={"contact" + contactIndex}>
                  {contactIndex === 0 ? null : <hr className="px-2" />}
                  <Col
                    className={`col-10 col-sm-11 ${
                      residentData?.kinContacts?.length - 1 === contactIndex
                        ? ""
                        : "mb-2"
                    }`}
                  >
                    <span className="mb-2 d-block">
                      #Contact {contactIndex + 1}
                    </span>
                    <Row className="gy-2">
                      <Col className="col-md-6">
                        <FormInput
                          label={"Name"}
                          required={true}
                          type={FormInputType.name}
                          value={contact?.name ?? ""}
                          onTextChange={(text) => {
                            const updatedData = [...residentData.kinContacts];
                            updatedData[contactIndex].name = text;
                            setResidentData({
                              ...residentData,
                              kinContacts: updatedData,
                            });
                          }}
                        />
                      </Col>
                      <Col className="col-md-6">
                        <FormInput
                          label={"Relationship"}
                          type={FormInputType?.default}
                          value={contact?.relationship}
                          onTextChange={(text) => {
                            const updatedData = [...residentData.kinContacts];
                            updatedData[contactIndex].relationship = text;
                            setResidentData({
                              ...residentData,
                              kinContacts: updatedData,
                            });
                          }}
                        />
                      </Col>
                      <Col className="col-md-6">
                        <FormInput
                          label={"Email"}
                          type={FormInputType?.email}
                          value={contact?.email}
                          onTextChange={(text) => {
                            const updatedData = [...residentData.kinContacts];
                            updatedData[contactIndex].email = text;
                            setResidentData({
                              ...residentData,
                              kinContacts: updatedData,
                            });
                          }}
                        />
                      </Col>
                      <Col className="col-md-6">
                        <PhoneInput
                          label={"Phone Number"}
                          required={true}
                          phone={contact?.phoneNumber?.phoneNumber}
                          countryCode={contact?.phoneNumber?.dialCode}
                          onChange={(phoneNumber) => {
                            const updatedData = [...residentData.kinContacts];
                            updatedData[contactIndex].phoneNumber = phoneNumber;
                            setResidentData({
                              ...residentData,
                              kinContacts: updatedData,
                            });
                          }}
                        />
                      </Col>
                      <Col className="col-12">
                        <div className="form-group mb-2">
                          <label className="form-label">
                            Address <span className="text-danger fs-6">*</span>
                          </label>
                          <div className="form-control-wrap">
                            <textarea
                              className="form-control"
                              placeholder="Address"
                              value={
                                residentData?.kinContacts[contactIndex]?.Address
                              }
                              onChange={(e) => {
                                const updatedData = [
                                  ...residentData.kinContacts,
                                ];
                                updatedData[contactIndex].Address =
                                  e.target.value;
                                setResidentData({
                                  ...residentData,
                                  kinContacts: updatedData,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="col-2 col-sm-1">
                    {contactIndex === 0 ? (
                      <div className="form-group">
                        <div className="form-control-wrap">
                          <IconButton
                            className="btn btn-primary "
                            icon="plus"
                            onClick={() => handleAddContact()}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="form-group">
                        <div className="form-control-wrap">
                          <IconButton
                            icon="minus"
                            className="btn btn-danger"
                            onClick={() => handleRemoveContact(contactIndex)}
                          />
                        </div>
                      </div>
                    )}
                  </Col>
                </React.Fragment>
              ))}
          </Row>
        </div>
        <div className="mt-4">
          <Row className="mx-auto">
            <Col className="col-md-6 mb-2">
              <RadioButtonOptions
                name="status"
                size={6}
                optionType={RadioOptionTypes.status}
                onChange={(index) => {
                  setResidentData((prev) => ({
                    ...prev,
                    isActive: index === 0,
                    Reason: "",
                    reasonDiscription: "",
                    leaveDate: "",
                  }));
                }}
                activeIndex={residentData?.isActive ? 0 : 1}
              />
            </Col>
          </Row>
          {!residentData?.isActive && (
            <>
              <Row className="border rounded-2 mx-auto py-1">
                <Col className="mb-2" md={"6"}>
                  <div className="form-group">
                    <label className="form-label">
                      Reason <span className="text-danger fs-6">*</span>
                    </label>
                    <div className="form-control-wrap">
                      <LeaveReasonDropdown
                        value={residentData?.Reason ?? ""}
                        onSelect={(reason) => {
                          setResidentData({
                            ...residentData,
                            Reason: reason,
                          });
                        }}
                      />
                    </div>
                  </div>
                </Col>
                <Col className={"mb-2 col-md-6"}>
                  <div className="form-group">
                    <label className="form-label">
                      Date <span className="text-danger fs-6">*</span>
                    </label>
                    <div className="form-control-wrap">
                      <FormDatePicker
                        selected={residentData?.leaveDate}
                        onChange={(start) => {
                          setResidentData({
                            ...residentData,
                            leaveDate: start,
                          });
                        }}
                      />
                    </div>
                  </div>
                </Col>
                <Col className="col-md-12 mb-2">
                  <div className="form-group">
                    <label className="form-label">Description</label>
                    <div className="form-control-wrap">
                      <textarea
                        className="form-control"
                        placeholder="Description"
                        value={residentData?.reasonDiscription}
                        onChange={(e) => {
                          setResidentData({
                            ...residentData,
                            reasonDiscription: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </div>
      </Form>
    </AnnsModal>
  );
};

export default AddResident;
