import { Redirect } from "react-router-dom";
import { Icon } from "../components/Component";
import moment from "moment";
import { toast } from "react-toastify";
import { AppText } from "./AppText";

export const RedirectAs404 = ({ location }) => (
  <Redirect to={Object.assign({}, location, { state: { is404: true } })} />
);

// Set deadlines for projects
export const setDeadlineDays = (deadline) => {
  var currentDate = new Date();
  var difference = deadline.getTime() - currentDate.getTime();
  var days = Math.ceil(difference / (1000 * 3600 * 24));
  return days;
};

// Function to structure date ex: YYYY-MM-DD
export const setDateForPicker = (rdate) => {
  let d = rdate.getDate();
  d < 10 && (d = "0" + d);
  let m = rdate.getMonth() + 1;
  m < 10 && (m = "0" + m);
  let y = rdate.getFullYear();
  rdate = y + "-" + m + "-" + d;

  return rdate;
};

//Function that returns the first or first two letters from a name
export const findUpper = (string) => {
  let extractedString = [];

  for (var i = 0; i < string.length; i++) {
    if (
      string.charAt(i) === string.charAt(i).toUpperCase() &&
      string.charAt(i) !== " "
    ) {
      extractedString.push(string.charAt(i));
    }
  }
  if (extractedString.length > 1) {
    return extractedString[0] + extractedString[1];
  } else {
    return extractedString[0];
  }
};

export function windowReload() {
  window.history.pushState(
    `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`,
    "auth-login",
    `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`
  );
  window.location.reload();
}

export const removeTimeFromServerDateString = (dateString) => {
  let date = dateString?.replace("T00:00:00.000Z", "") ?? "";
  return date;
};

export const dateStringToShowFormat = (date, format = "DD-MM-YYYY") => {
  let dueDate = moment(removeTimeFromServerDateString(date), "YYYY-MM-DD");
  return dueDate.format(format);
};

export const serverDateToDate = (date) => {
  let dueDate = moment(removeTimeFromServerDateString(date)).toDate();
  return dueDate;
};

export const dateToShow = (date, format = "DD-MM-YYYY") => {
  return moment(date).format(format);
};

export const isNumberOnly = (value) => {
  return !isNaN(+value);
};

export const covertToNumberOnly = (value) => {
  return value.replaceAll(/[^-\d.]/g, "");
};

export const toCapitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const convertToUpperCase = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toUpperCase();
};

export const openLinkInNewTab = (url, event) => {
  event?.preventDefault();
  if (url === null || url === undefined) {
    return;
  }
  if (event?.metaKey) {
    window.open(url, "_blank");
  } else {
    window.open(url, "_blank");
  }
};

export const showErrorToast = (message) => {
  let text = message.length > 0 ? message : AppText.connectError;
  toast.error(text, {
    position: "top-right",
    autoClose: true,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: false,
    closeButton: <CloseButton />,
  });
};

export const showSuccessToast = (message) => {
  toast.success(message, {
    position: "top-right",
    autoClose: true,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: false,
    closeButton: <CloseButton />,
  });
};
const CloseButton = () => {
  return (
    <span className="btn-trigger toast-close-button" role="button">
      <Icon name="cross"></Icon>
    </span>
  );
};

export function formatPhoneNumber(prefix, value) {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) {
    return value;
  }

  if (value === prefix) {
    return "";
  }
  var phoneNumber = value.replace(prefix, "");
  // clean the input for any non-digit values.
  phoneNumber = phoneNumber.replace(/[^\d]/g, "");
  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you  format the area code to early

  if (phoneNumberLength < 5) {
    return prefix + phoneNumber.slice(0);
  }

  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  // if (phoneNumberLength < 7) {
  //   return `${prefix}${phoneNumber.slice(0, 3)} ${phoneNumber.slice(3)}`;
  // }

  // finally, if the phoneNumberLength is greater then seven, we add the last
  // bit of formatting and return it.
  return `${prefix}${phoneNumber.slice(0, 4)} ${phoneNumber.slice(4, 10)}`;
}

export function formatPostalCode(prefix, value) {
  // If input value is falsy, e.g., if the user deletes the input, then just return
  if (!value) {
    return value;
  }

  // Remove the prefix from the value
  var postalCode = value.replace(prefix, "");

  // Clean the input for any non-alphanumeric values
  postalCode = postalCode.replace(/[^A-Za-z0-9]/g, "");

  // Apply the format based on the given regex pattern
  postalCode = postalCode.replace(
    /^([A-Z]{1,2}\d{1,2}[A-Z]?)\s?(\d[A-Z]{2})$/i,
    "$1 $2"
  );

  // Apply minimum and maximum length constraints
  const minLength = 5; // Minimum length requirement
  const maxLength = 8; // Maximum length requirement

  if (postalCode.length < minLength) {
    return prefix + postalCode;
  } else if (postalCode.length > maxLength) {
    postalCode = postalCode.slice(0, maxLength);
  }

  // Add the prefix back to the formatted postal code
  return prefix + postalCode;
}

export const convertDropValue = (item) => {
  let value = {
    id: item?.id,
    value: item?.name ?? item?.title ?? "",
    label: toCapitalize(item?.name ?? item?.title ?? ""),
  };
  return value;
};

export const convertDropMultiValue = (items) => {
  let list = [];
  items.map((item, index) => {
    let value = {
      id: item?.id,
      value: item?.name ?? item?.title ?? "",
      label: item?.name ?? item?.title ?? "",
    };
    return list.push(value);
  });
  return list;
};

export const numberFormatter = (prefix, value) => {
  if (value === undefined || value === null || value === "") {
    return "";
  }

  var number = value.replace(/[^-\d.]/g, "");
  number = parseFloat(number);
  let isNegative = number < 0.0;

  var amount = value.replace(/[^\d.]/g, "");
  amount = parseFloat(amount);
  let isDecimal = amount % 1 !== 0;
  amount =
    (isNegative ? "-" : "") +
    prefix +
    amount.toFixed(isDecimal ? 2 : 0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  return amount;
};

export const convertNameToDropdownList = (items) => {
  let list = [];
  items.map((item, index) => {
    let value = {
      id: item?.id,
      value: item?.firstName + " " + item?.lastName,
      label: item?.firstName + " " + item?.lastName,
    };
    return list.push(value);
  });
  return list;
};

export const updateTimeFromShift = (shiftTimeId, shiftTime) => {
  if (shiftTimeId) {
    const selectedShift = shiftTime.find(
      (shift) => shift.shiftTimeId === shiftTimeId.id
    );
    if (selectedShift) {
      const updatedStartTime = moment(selectedShift.startTime, "HH:mm:ss")
        .utc()
        .toDate();
      const updatedEndTime = moment(selectedShift.endTime, "HH:mm:ss")
        .utc()
        .toDate();
      return {
        startTime: updatedStartTime,
        endTime: updatedEndTime,
      };
    }
  }
  return {
    startTime: null,
    endTime: null,
  };
};

export const convertYearsDropValue = (item) => {
  if (!item) return null;

  let value = {
    id: item?.id,
    value: item?.name ?? item?.title ?? "",
    label: item?.name ?? item?.title ?? "",
  };
  return value;
};

export const convertToNotNull = (object) => {
  Object.keys(object).forEach((key) => {
    if (!object[key]) {
      delete object[key];
    }
  });
  return object;
};

export const toFindDuplicates = (arry) =>
  arry.filter((item, index) => arry.indexOf(item) !== index);
