import { DropdownToggle, UncontrolledDropdown } from "reactstrap";
import {
  FormInput,
  FormInputType,
  Icon,
  LocationDropDown,
  TableTitle,
} from "../../../components/Component";
import {
  ChequeStatusType,
  FromType,
  TransactionMoneyType,
} from "../../../utils/Utils";
import { APIServicePath } from "../../../services/api/api-service-impl";
import { apiService } from "../../../services/api";
import { toPageInfo } from "../../_common/DataConverter";
import { convertDropValue } from "../../../utils";
import { serverDateToDate, toCapitalize } from "../../../utils/Functions";
import { convertAccountDropValue } from "../../../components/dropdowns/DropDownData";
import { TranscationTypes } from "./TranscationType";
import moment from "moment";

export const trasactionDataList = [
  {
    item: "2023-08-24T00:00:00.000Z",
    discription: "asdasd",
  },
  {
    item: "2023-08-24T00:00:00.000Z",
    discription: "asdasd",
  },
];

export const TransactionTableData = (onValueChange, onEdit, onRemove) => {
  return [
    {
      name: <TableTitle title={"Item"} />,
      cell: (row, rowIndex, column) => (
        <FormInput
          inputRootClass={"w-100 h-100"}
          inputBaseClass={"w-100 h-100"}
          value={row?.item}
          baseClass={"w-100 h-100"}
          inputClass={"border-0 w-100 h-100"}
          type={FormInputType.default}
          onError={(isError) => {}}
          onTextChange={(text) => {
            onValueChange(row, rowIndex, FromType.ITEM, text);
          }}
        />
      ),
    },
    {
      name: <TableTitle title={"Discription"} />,
      cell: (row, rowIndex, column) => (
        <FormInput
          inputRootClass={"w-100 h-100"}
          inputBaseClass={"w-100 h-100"}
          value={row?.discription}
          baseClass={"w-100 h-100"}
          inputClass={"border-0 w-100 h-100"}
          type={FormInputType.default}
          onError={(isError) => {}}
          onTextChange={(text) => {
            onValueChange(row, rowIndex, FromType.DISCRIPTION, text);
          }}
        />
      ),
    },
    {
      name: <TableTitle title={"Account"} />,
      cell: (row, rowIndex, column) => (
        <LocationDropDown
          className="w-100 "
          value={row?.account ?? ""}
          onSelect={(location) => {
            onValueChange(row, rowIndex, FromType.ACCOUNT, location);
          }}
        />
      ),
    },
    {
      name: <TableTitle title={"Invoice No"} />,
      cell: (row, rowIndex, column) => (
        <FormInput
          inputRootClass={"w-100 h-100"}
          inputBaseClass={"w-100 h-100"}
          value={row?.invoiceNo}
          baseClass={"w-100 h-100"}
          inputClass={"border-0 w-100 h-100"}
          type={FormInputType.default}
          onError={(isError) => {}}
          onTextChange={(text) => {
            onValueChange(row, rowIndex, FromType.INVOICENO, text);
          }}
        />
      ),
    },
    {
      name: <TableTitle title={"Cheque No"} />,
      cell: (row, rowIndex, column) => (
        <FormInput
          inputRootClass={"w-100 h-100"}
          inputBaseClass={"w-100 h-100"}
          value={row?.chequeNo}
          baseClass={"w-100 h-100"}
          inputClass={"border-0 w-100 h-100"}
          type={FormInputType.default}
          onError={(isError) => {}}
          onTextChange={(text) => {
            onValueChange(row, rowIndex, FromType.CHEQUENO, text);
          }}
        />
      ),
    },
    {
      name: <TableTitle title={"Amount"} />,
      cell: (row, rowIndex, column) => (
        <FormInput
          inputRootClass={"w-100 h-100"}
          inputBaseClass={"w-100 h-100"}
          value={row?.amount}
          baseClass={"w-100 h-100"}
          inputClass={"border-0 w-100 h-100"}
          type={FormInputType.default}
          onError={(isError) => {}}
          onTextChange={(text) => {
            onValueChange(row, rowIndex, FromType.AMOUNT, text);
          }}
        />
      ),
    },
    {
      name: <TableTitle title={"Reference No"} />,
      cell: (row, rowIndex, column) => (
        <FormInput
          inputRootClass={"w-100 h-100"}
          inputBaseClass={"w-100 h-100"}
          value={row?.referenceNo}
          baseClass={"w-100 h-100"}
          inputClass={"border-0 w-100 h-100"}
          type={FormInputType.default}
          onError={(isError) => {}}
          onTextChange={(text) => {
            onValueChange(row, rowIndex, FromType.REFNO, text);
          }}
        />
      ),
    },
    {
      name: <TableTitle title={""} align="end" />,
      right: true,
      width: "50px",
      center: true,
      cell: (row, rowIndex) => (
        <UncontrolledDropdown>
          <DropdownToggle
            tag="a"
            className="text-soft dropdown-toggle btn btn-icon btn-trigger table-edit-btn"
          >
            <span
              onClick={() => {
                onRemove(rowIndex);
              }}
            >
              <Icon name="trash"></Icon>
            </span>
          </DropdownToggle>
        </UncontrolledDropdown>
      ),
    },
  ];
};

//------------------------------API------------------------------->
export function fetchTransactionSave(params, callback) {
  apiService
    .postCall(APIServicePath.transactionSave, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
export function fetchTransactionList(params, callback) {
  apiService
    .postCall(APIServicePath.transactionList, params)
    .then((data) => {
      let list = toTransactionList(data?.list);
      let pageInfo = toPageInfo(data?.pageInfo);
      callback({ list, pageInfo }, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
export function fetchChequeStatusUpdate(params, callback) {
  apiService
    .postCall(APIServicePath.chequeStatusUpdate, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function fetchTransactionDetails(params, callback) {
  apiService
    .postCall(APIServicePath.transactionDetails, params)
    .then((data) => {
      let cDate = toTransactionDetails(data?.data);
      // let pageInfo = toPageInfo(data?.pageInfo);
      callback(cDate, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
export function fetchTransactionRemove(params, callback) {
  apiService
    .postCall(APIServicePath.transactionRemove, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
export function fetchTransactionTransPdf(params, callback) {
  apiService
    .postCall(APIServicePath.transactionTransPdf, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
export function fetchTransactionExportXlsx(params, callback) {
  apiService
    .postCall(APIServicePath.transactionExportXlsx, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

  export function fileUploadApi(file, uploadFileType, callback) {
    apiService
      .fileUploader(file, uploadFileType, APIServicePath.fileUpload)
      .then((data) => {
        callback(data, null);
      })
      .catch((error) => {
        callback(null, error);
      });
  }

export function fetchMaxLockDateForTransaction(params, callback) {
  apiService
    .postCall(APIServicePath.transactionMaxLockDate, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

//-------------------------- converter---------------------->

export const toTransactionList = (item) => {
  let list = [];
  item?.length > 0 &&
    item?.map((lItem) => {
      return list.push(toTransaction(lItem));
    });
  return list;
};

export function toTransaction(lItem) {
  let transactionAccounts = [];
  lItem?.respectiveTransactions?.map((tItem) => {
    let name =
      tItem?.account.type === TranscationTypes.resident
        ? tItem?.account?.resident?.firstName +
          " " +
          tItem?.account?.resident?.lastName
        : toCapitalize(tItem?.account?.name) ?? "";
    let object = {
      id: tItem?.id,
      label: name ?? "",
      value: name,
      amount: tItem?.amount,
    };
    return transactionAccounts?.push(object);
  });
  let object = {
    id: lItem?.id,
    date: lItem?.date,
    transId: lItem?.transId,
    receiptNumber: lItem?.receiptNumber,
    lodgementNumber: lItem?.lodgementNumber,
    amount: lItem?.amount,
    type: lItem?.type,
    description: lItem?.description,
    expenseNo: lItem?.expenseNumber,
    balance: lItem?.balance,
    status: lItem?.status,
    chequeNo: lItem?.chequeNumber,
    clearanceStatus: lItem?.clearanceStatus,
    clearanceDate: lItem?.clearanceDate,
    transactionAccounts: transactionAccounts,
    recoIsLocked: lItem?.reco?.isLocked,
    account: {
      id: lItem?.account?.id,
      name: lItem?.account?.name,
      resident: {
        id: lItem?.account?.resident?.id,
        firstName: lItem?.account?.resident?.firstName,
        lastName: lItem?.account?.resident?.lastName,
      },
    },
  };
  return object;
}
const toTransactionDetails = (data) => {
  let cFromAccount = [];

  data?.items
    ?.filter((item) => item?.for === "FROM")
    .map((item) => {
      let serverExpenseNo = item?.expenseNumber ?? "";
      let prefixExpense =
        serverExpenseNo.length === 8 ? serverExpenseNo.slice(0, 4) : "";
      let expenseNo =
        serverExpenseNo.length === 8
          ? serverExpenseNo.slice(4, 8)
          : serverExpenseNo;

      let object = {
        account: convertAccountDropValue(item?.account),
        receiptNo: item?.receiptNumber,
        LodgementNo: item?.lodgementNumber,
        expenseNo: expenseNo,
        preExpenseNo: prefixExpense,
        chequeStatus: item?.clearanceStatus === ChequeStatusType.unpresented,
        clearanceDate: item?.clearanceDate
          ? moment(item?.clearanceDate).format("YYYY-MM-DD")
          : "",
        isCheque: item?.isCheque === 1,
        chequeNo: item?.chequeNumber,
        description: item?.description,
        amount: (item?.amount).toString(),
        originalAmount: item?.amount,
        type: item?.type,
      };
      return cFromAccount?.push(object);
    });

  let cToAccount = [];
  data?.items
    ?.filter((item) => item?.for === "TO")
    .map((item) => {
      let object = {
        account: convertAccountDropValue(item?.account),
        description: item?.description,
        amount: (item?.amount).toString(),
        originalAmount: item?.amount,
        type: item?.type,
      };
      return cToAccount?.push(object);
    });

  let files = [];
  for (let index = 0; index < data?.files?.length ?? 0; index++) {
    const element = data?.files[index];
    element.isOldFile = true;
    files.push(element);
  }

  let type = null;
  if (cFromAccount?.length > 0) {
    type =
      cFromAccount[0].type === "IN"
        ? TransactionMoneyType.in
        : data?.isTransfer === 1
        ? TransactionMoneyType.transfer
        : TransactionMoneyType.out;
  }

  let cDetailsData = {
    date: serverDateToDate(data?.date),
    fromAccount: cFromAccount,
    toAccount: cToAccount,
    type: type,
    selectedDropValue: data?.items?.map((item) =>
      convertDropValue(item?.account)
    ),
    transId: data?.id,
    files: files,
    location: convertDropValue(data?.location),
    reco: data?.reco?.isLocked === 1 ? true : false,
    status: data?.status,
  };
  return cDetailsData;
};
