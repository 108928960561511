import React from "react";
import {
  UserActionType,
  UserPermissionType,
  isAllowToAccess,
} from "../../../services/permission/permission";
import {
  ActionButton,
  FormDatePicker,
  FormInput,
  FormInputType,
} from "../../../components/Component";

const RecoDateNameComponent = ({
  maxDate,
  datePickerDisable,
  dateSelected,
  onDateChange,
  nameDisable,
  nameValue,
  onNameChange,
  signOffDisable,
  onSignOff,
  headTitle,
}) => {
  return (
    <div className="container">
      <div className="row py-1 align-items-center">
        <div className="col-sm-2 col-12">
          <p className="mb-0">{headTitle}</p>
        </div>
        <div className="col-sm-4 col-12">
          <FormDatePicker
            maxDate={maxDate}
            disabled={datePickerDisable}
            selected={dateSelected}
            onChange={onDateChange}
          />
        </div>
        <div className="col-sm-4 col-12">
          <FormInput
            type={FormInputType.name}
            disabled={nameDisable}
            value={nameValue}
            onTextChange={onNameChange}
          />
        </div>
        <div className="col-sm-2 col-12">
          <p className="mb-0 d-flex justify-content-end">
            {isAllowToAccess(
              UserPermissionType.reco,
              UserActionType.signoff
            ) && (
              <>
                <ActionButton
                  title="Sign Off"
                  size="md"
                  className=""
                  disabled={signOffDisable}
                  onClick={onSignOff}
                />
              </>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default RecoDateNameComponent;
