import React, { useEffect, useState } from "react";
import { RSelect } from "../Component";
import { accountDropdownData } from "./DropDownData";
import { TranscationTypes } from "../../pages/tabs/Transaction/TranscationType";

export const AccountDropDown = ({
  value,
  isMulti = false,
  onSelect,
  placeholder,
  locationId,
  onceTimeSelected,
  compareValue,
  accountTypes,
  loactionId,
  disabled,
  ...props
}) => {
  const [list, setList] = useState();
  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    // return () => {
    //   setList();
    //   setSearchString();
    // };
  }, []);

  useEffect(() => {
    searchAccounts(searchString);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString, locationId, accountTypes]);

  useEffect(() => {
    if (compareValue?.length > 0 && onceTimeSelected) {
      searchAccounts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compareValue]);

  const searchAccounts = (searchText) => {
    if (!locationId) return;
    accountDropdownData(searchText, accountTypes, locationId, (data, error) => {
      if (error === null) {
        if (compareValue?.length > 0 && onceTimeSelected) {
          if (onceTimeSelected ?? false) {
            let missing = data?.filter((item) => {
              return !compareValue?.some((sitem) => sitem?.id === item?.id);
            });
            setList(missing);
          }
        } else {
          setList(data);
        }
      }
    });
  };

  const handleSearch = (text) => {
    setSearchString(text);
  };

  return (
    <RSelect
      options={list ?? []}
      isMulti={isMulti}
      placeholder={placeholder}
      disabled={disabled}
      onInputChange={handleSearch}
      value={value ?? { id: 1, label: "This Month", value: "THISMONTH" }}
      onChange={(e) => {
        onSelect(e);

        // if (!selectedDropValue?.some((item) => item === e)) {
        //   setSelectedDropValue([...selectedDropValue, e]);
        // }
      }}
      {...props}
    />
  );
};
