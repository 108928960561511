import React, { useState } from "react";
import { Dropdown, DropdownToggle } from "reactstrap";
import { Icon } from "../../../../components/Component";
import { withRouter } from "react-router";

const ChatDropdown = () => {
  const [open, setOpen] = useState(false);
  const toggle = () => {
    setOpen(!open);
  };
  return (
    <Dropdown isOpen={open} toggle={toggle}>
      <DropdownToggle tag="a" href="#dropdown" onClick={(ev) => ev.preventDefault()} className="nk-quick-nav-icon">
        <div className="icon-status icon-status-na">
          <Icon name="comments"></Icon>
        </div>
      </DropdownToggle>
      {/* <DropdownMenu end className="dropdown-menu-xl">
      
      </DropdownMenu> */}
    </Dropdown>
  );
};

export default withRouter(ChatDropdown);
