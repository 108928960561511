import React, { useEffect, useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  ActionButton,
  BackButton,
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Col,
  ConfirmationAlert,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  FormCheckbox,
  FormDatePicker,
  FormInput,
  FormInputType,
  Icon,
  IconButton,
  LocationDropDown,
  PreviewCard,
  RadioButtonOptions,
  ReactDataTable,
  Row,
  TooltipComponent,
} from "../../../components/Component";
import {
  TransactionTableData,
  fetchMaxLockDateForTransaction,
  fetchTransactionDetails,
  fetchTransactionRemove,
  fetchTransactionSave,
  fileUploadApi,
  trasactionDataList,
} from "./TransactionData";
import {
  ChequeStatusType,
  FromType,
  RadioOptionTypes,
  TransactionMoneyType,
  bytesToMegaBytes,
  iconsType,
} from "../../../utils/Utils";
import { DropdownToggle, Spinner, UncontrolledDropdown } from "reactstrap";
import { AccountDropDown } from "../../../components/dropdowns/AccountDropDown";
import { useLocation } from "react-router-dom";
import { AppText, CURRENCY_CODE, covertToNumberOnly } from "../../../utils";
import {
  dateStringToShowFormat,
  dateToShow,
  numberFormatter,
  openLinkInNewTab,
  serverDateToDate,
  showErrorToast,
  showSuccessToast,
  toFindDuplicates,
} from "../../../utils/Functions";
import moment from "moment/moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useSelector } from "react-redux";
import { DeleteButton } from "../../../components/button/ActionButton";
import TranscationType, { TranscationTypes } from "./TranscationType";
import { PermissionType } from "../../../components/dropdowns/PermissionDropdown";
import ClearDateModal from "../../../components/ClearDateModal/ClearDateModal";
import AuditTrailDatatable from "../AuditTrail/AuditTrailDatatable";
import {
  UserPermissionType,
  isCheckToList,
} from "../../../services/permission/permission";

const AddTransaction = ({ item }) => {
  const location = useLocation();
  const history = useHistory();
  const user = useSelector((state) => state?.auth?.sessionUser);
  const locationId = user?.location?.id;
  const userType = user?.userType;

  const [files, setFiles] = useState([]);
  const [removeFileIds, setRemoveFileIds] = useState([]);
  const [date, setDate] = useState();
  const [isDisable, setIsDisable] = useState(true);
  const [selectedDropValue, setSelectedDropValue] = useState([]);
  const [isCompairAmount, setIsCompairAmount] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState();
  const [showClearDateModal, setShowClearDateModal] = useState(false);
  const [isLocked, setIsLocked] = useState();
  const [maxLockDate, setMaxLockDate] = useState();
  const [status, setStatus] = useState();
  const [isExpenseNoTouched, setIsExpenseNoTouched] = useState(false);
  const locationByMainDropdown = useSelector(
    (state) => state?.auth?.currentLocation
  );

  const [fromAccountList, setFromAccountList] = useState([
    {
      account: "",
      receiptNo: "",
      amount: "",
      referenceNo: "",
      chequeStatus: true,
      isCheque: false,
    },
  ]);
  const [toAccountList, setToAccountList] = useState([
    {
      account: "",
      description: "",
      amount: "",
    },
  ]);
  const [transactionType, setTransactionType] = useState(location.state?.type);

  useEffect(() => {
    if (
      selectedLocation &&
      selectedLocation.id !== locationByMainDropdown?.id &&
      location?.state?.TransactionId
    ) {
      history.push(`${process.env.PUBLIC_URL}/transaction`);
    } else {
      setSelectedLocation(locationByMainDropdown);
      locationByMainDropdown?.id &&
        fetchMaxLockDate(locationByMainDropdown?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationByMainDropdown]);

  useEffect(() => {
    setDate(new Date());
  }, []);

  useEffect(() => {
    if (location?.state?.TransactionId) {
      loadTransactionDetails();
    }

    if (location.state?.type !== transactionType) {
      setTransactionType(location.state?.type);
    }
  }, [location]);

  useEffect(() => {
    let compairAmount =
      toAccountList
        ?.map((item) => parseFloat(covertToNumberOnly(item?.amount ?? 0)))
        .reduce((prev, next) => (prev = prev + next))
        .toFixed(2) ===
      fromAccountList
        ?.map((item) => parseFloat(covertToNumberOnly(item?.amount ?? 0)))
        .reduce((prev, next) => (prev = prev + next))
        .toFixed(2);
    setIsCompairAmount(compairAmount);

    let isNagativeEntry =
      transactionType === TransactionMoneyType.out &&
      toAccountList?.filter(
        (item) => item?.account?.balance - item?.diffAmount < 0
      )?.length > 0;

    let isExpenseNumberValid = fromAccountList.every(
      (item) => item?.expenseNo?.length >= 4
    );

    let disable =
      !compairAmount ||
      date === undefined ||
      date === null ||
      // isNagativeEntry ||
      (transactionType === TransactionMoneyType.out && !isExpenseNumberValid) ||
      !toAccountList.every(
        (item) => typeof item?.account === "object" && item?.account?.id
      ) ||
      !fromAccountList.every(
        (item) => typeof item?.account === "object" && item?.account?.id
      );
    setIsDisable(disable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromAccountList, toAccountList, date, transactionType]);

  // functions----------------------------------------------->
  const handleAddRowFrom = () => {
    let list = [...fromAccountList];
    list.push({
      account: "",
      receiptNo: "",
      amount: "",
      referenceNo: "",
    });
    setFromAccountList(list);
  };

  const handleAddRowTo = () => {
    let list = [...toAccountList];
    list.push({
      account: "",
      description:
        fromAccountList?.length > 0 ? fromAccountList[0].description : " ",
      amount: "",
    });
    setToAccountList(list);
  };

  const onRemove = (rowIndex) => {
    if (fromAccountList?.length > 1) {
      let list = [...fromAccountList];
      list.splice(rowIndex, 1);
      setFromAccountList(list);
    }
  };

  const onRemoveTo = (rowIndex, rowData) => {
    if (toAccountList?.length > 1) {
      setSelectedDropValue(
        selectedDropValue?.filter((item) => item?.id !== rowData?.account?.id)
      );
      let list = [...toAccountList];
      list.splice(rowIndex, 1);
      setToAccountList(list);
    }
  };

  const handleFileSelect = (file, rootIndex) => {
    if (bytesToMegaBytes(file.size) > 100) {
      showErrorToast(AppText.maxFileSizeError);
    } else {
      const fileDetails = file;
      fileDetails.isLoading = true;
      fileDetails.isError = false;
      fileDetails.id = null;
      fileDetails.isOldFile = false;

      const updatedfiles = [...files]; // Create a copy of the files array
      updatedfiles.push(fileDetails);
      setFiles(updatedfiles);

      // for API
      uploadFile(fileDetails, updatedfiles.length - 1);
    }
  };

  const reset = () => {
    setFromAccountList([
      {
        account: "",
        receiptNo: "",
        amount: "",
        referenceNo: "",
        chequeStatus: true,
        isCheque: false,
      },
    ]);
    setToAccountList([
      {
        account: "",
        description: "",
        amount: "",
      },
    ]);
    setDate(new Date());
    setSelectedDropValue([]);
    setFiles([]);
  };

  const handleFileRemove = (index) => {
    const updatedfiles = [...files];
    const removeFile = updatedfiles[index];
    updatedfiles.splice(index, 1);
    setFiles(updatedfiles);

    // Add the removed file's ID to the removedFileIds state
    if (removeFile?.isOldFile === true) {
      setRemoveFileIds([...removeFileIds, removeFile.id]);
    }
  };

  const onSaveTransiction = () => {
    if (selectedLocation?.id) {
      saveTransaction();
    }
  };

  let preExpenseNo = "ES" + moment(date).format("YY");
  //--------------------------------------- API------------------------------------------>

  const saveTransaction = () => {
    let duplicateEntries = toFindDuplicates(
      toAccountList?.map((item) => item.account.id) ?? []
    );
    if (duplicateEntries.length > 0) {
      showErrorToast("Please correct duplicate entries");
      return;
    }

    setIsLoading(true);
    let cFromAccountList = [];
    let fromItem = null;
    fromAccountList?.map((item) => {
      fromItem = item;
      let object = {
        accountId: item?.account?.id ?? null,
        amount: parseFloat(covertToNumberOnly(item?.amount)),
        receiptNo: item?.receiptNo ?? null,
        lodgementNo: item?.LodgementNo ?? null,
        expenseNo:
          transactionType === TransactionMoneyType.out
            ? preExpenseNo + item?.expenseNo
            : null,
        chequeNo: item?.chequeNo ?? null,
        description: item?.description ?? null,
        isCheque: item?.isCheque,
        clearanceStatus:
          item?.isCheque === true
            ? item?.chequeStatus === true
              ? ChequeStatusType.unpresented
              : ChequeStatusType.clear
            : null,
        clearanceDate: item?.clearanceDate
          ? moment(item?.clearanceDate).format("YYYY-MM-DD")
          : null,
      };
      return cFromAccountList.push(object);
    });
    let cToAccountList = [];
    toAccountList?.map((item) => {
      let object = {
        accountId: item?.account?.id ?? null,
        amount: parseFloat(covertToNumberOnly(item?.amount)),
        description: item?.description ?? null,
        receiptNo: fromItem?.receiptNo ?? null,
        lodgementNo: fromItem?.LodgementNo ?? null,
        expenseNo: fromItem?.expenseNo
          ? preExpenseNo + fromItem?.expenseNo ?? null
          : null,
        chequeNo: fromItem?.chequeNo ?? null,
      };
      return cToAccountList.push(object);
    });

    let params = {
      transId: location?.state?.TransactionId ?? null,
      date: date ? moment(date).format("YYYY-MM-DD") : null,
      type: transactionType === TransactionMoneyType.in ? "IN" : "OUT",
      isInternalTransfer: transactionType === TransactionMoneyType.transfer,
      fromTransaction: cFromAccountList,
      toTransaction: cToAccountList,
      fileIds: files
        ?.filter((item) => item.isOldFile === false)
        ?.map((item) => item.id),
      removeFileIds: removeFileIds,
      locationId: selectedLocation?.id,
    };
    fetchTransactionSave(params, (data, error) => {
      if (error === null) {
        if (data?.lowBalanceAccounts?.length > 0) {
          let lowBalanceNames = data?.lowBalanceAccounts.map(
            (item) => item?.name
          );
          let lowBalanceText = `Low balance accounts: ${lowBalanceNames.join(
            ", "
          )}`;
          showErrorToast(lowBalanceText);
        } else {
          let text =
            "Transaction" +
            (item ? AppText.EditedSuccessfully : AppText.AddedSuccessfully);
          showSuccessToast(text);
        }
        history.goBack();
      } else {
        showErrorToast(error.message);
      }
      setIsLoading(false);
    });
  };

  const loadTransactionDetails = () => {
    let params = {
      transId: location?.state?.TransactionId,
    };
    setIsLoadingData(true);
    fetchTransactionDetails(params, (data, error) => {
      setIsLoadingData(false);
      if (error === null) {
        setFromAccountList(data?.fromAccount);
        setToAccountList(data?.toAccount);
        setDate(data?.date);
        setSelectedDropValue(data?.selectedDropValue);
        setFiles(data?.files);
        setTransactionType(data?.type);
        setSelectedLocation(data?.location);
        setIsLocked(data?.reco || data?.status === "DELETED");
        setStatus(data?.status);
      } else {
        showSuccessToast(
          "Sorry, we are not able to fetch your tranaction details"
        );
        history.goBack();
      }
    });
  };

  const onRemoveTransaction = () => {
    ConfirmationAlert(
      "Remove Transaction",
      "Do you want to remove this transaction ?",
      () => {
        removeTransaction();
      }
    );
  };

  const removeTransaction = () => {
    let params = {
      transId: location?.state?.TransactionId,
    };
    fetchTransactionRemove(params, (data, error) => {
      if (error === null) {
        history.goBack();
      } else {
        showErrorToast(error.message);
      }
    });
  };

  const uploadFile = (file, index) => {
    fileUploadApi(file, "TRANSACTION", (data, error) => {
      const updatedfiles = [...files];
      // Create a copy of the files array
      if (error === null) {
        file.id = data.id;
        file.fileUrl = URL.createObjectURL(file);
        file.isLoading = false;
        updatedfiles[index] = file;
        setFiles(updatedfiles);
      } else {
        updatedfiles.splice(index, 1);
        setFiles(updatedfiles);
        showErrorToast(
          error.message.length > 0 ? error.message : AppText.connectError
        );
      }
    });
  };

  const fetchMaxLockDate = (id) => {
    let params = {
      locationId: id,
    };
    fetchMaxLockDateForTransaction(params, (data, error) => {
      if (!error && data) {
        let date =
          (data?.maxLockDate ?? "") === ""
            ? undefined
            : serverDateToDate(data?.maxLockDate);
        setMaxLockDate(date);
      }
    });
  };
  return (
    <React.Fragment>
      <Head title="Transaction" />
      <Content>
        {isLoadingData ? (
          <div className="spinner">
            <Spinner size="sm" color="dark" />
          </div>
        ) : (
          <>
            <BlockHead size="sm">
              <BlockBetween>
                <BlockHeadContent>
                  <BlockTitle page>
                    Add Transaction ({transactionType})
                  </BlockTitle>
                </BlockHeadContent>
                <BlockHeadContent>
                  <ul className="align-center justify-content-end flex-wrap flex-sm-nowrap gx-4 gy-2">
                    {status === "DELETED" ? (
                      <li>
                        <span className="text-danger">DELETED</span>
                      </li>
                    ) : location?.state?.isEditTransaction ? (
                      <li>
                        <DeleteButton
                          disabled={isLocked}
                          title={"Remove Transaction"}
                          onDelete={() => onRemoveTransaction()}
                        />
                      </li>
                    ) : (
                      <></>
                    )}
                    <li>
                      <BackButton
                        backUrl={
                          location?.state?.isAuditTrailScreen
                            ? `${process.env.PUBLIC_URL}/audit-trail`
                            : `${process.env.PUBLIC_URL}/transaction`
                        }
                      />
                    </li>
                  </ul>
                </BlockHeadContent>
              </BlockBetween>
            </BlockHead>
            <Block>
              <PreviewCard>
                <Block>
                  <div className="mt-4">
                    {transactionType === TransactionMoneyType.transfer && (
                      <span className="fs-4">Out</span>
                    )}

                    {fromAccountList?.length > 0 &&
                      fromAccountList?.map((rItem, rindex) => (
                        <React.Fragment key={rindex}>
                          <Row
                            className={
                              "cursor table-rows mb-1 position-relative"
                            }
                            onClick={() => ""}
                          >
                            <Col className={"col-md-4 mb-2"}>
                              <div className="form-group">
                                <label className="form-label">Date</label>
                                <div className="form-control-wrap">
                                  <FormDatePicker
                                    disabled={isLocked}
                                    selected={date}
                                    onChange={(selectedDate) => {
                                      let date = moment(
                                        moment(selectedDate).format(
                                          "YYYY-MM-DD"
                                        )
                                      ).toDate();
                                      if (!maxLockDate || maxLockDate < date) {
                                        setDate(selectedDate);
                                      } else {
                                        showErrorToast(
                                          "This period is locked, Please contact to admin"
                                        );
                                      }
                                    }}
                                    maxDate={new Date()}
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col className={"col-md-4 mb-2"}>
                              <div className="form-group">
                                <label className="form-label">Account</label>
                                <div className="form-control-wrap">
                                  <AccountDropDown
                                    disabled={isLocked}
                                    locationId={
                                      selectedLocation?.id ?? locationId
                                    }
                                    className="w-100 "
                                    accountTypes={[
                                      TranscationTypes.bank,
                                      TranscationTypes.cash,
                                    ]}
                                    value={fromAccountList[rindex]?.account}
                                    placeholder={"Select Account"}
                                    accountType={false}
                                    compareValue={selectedDropValue}
                                    onceTimeSelected={true}
                                    onSelect={(account) => {
                                      let list = [...fromAccountList];
                                      let object = {
                                        ...list[rindex],
                                        account: account,
                                      };
                                      list[rindex] = object;
                                      setFromAccountList(list);
                                      // avoid acount to reselect
                                      if (
                                        !selectedDropValue?.some(
                                          (item) => item === account
                                        )
                                      ) {
                                        let selectedDropValueList = [
                                          ...selectedDropValue,
                                        ];
                                        selectedDropValueList[rindex] = account;
                                        setSelectedDropValue(
                                          selectedDropValueList
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col className={"col-md-4 mb-2"}>
                              <FormInput
                                disabled={isLocked}
                                inputRootClass={"w-100 h-100"}
                                label={"Description"}
                                inputBaseClass={"w-100 h-100"}
                                value={fromAccountList[rindex]?.description}
                                baseClass={"w-100 h-100"}
                                inputClass={"w-100 h-100"}
                                type={FormInputType.default}
                                onError={(isError) => {}}
                                onTextChange={(text) => {
                                  let list = [...fromAccountList];
                                  let object = {
                                    ...list[rindex],
                                    description: text,
                                  };
                                  list[rindex] = object;
                                  setFromAccountList(list);
                                }}
                                onDidEnd={(text) => {
                                  // set description on to section too
                                  if (
                                    toAccountList.length > 0 &&
                                    (toAccountList[0].description?.length ??
                                      0) === 0
                                  ) {
                                    let toList = [...toAccountList];
                                    let toObject = {
                                      ...toList[0],
                                      description: text,
                                    };
                                    toList[0] = toObject;
                                    setToAccountList(toList);
                                  }
                                }}
                              />
                            </Col>

                            {transactionType === TransactionMoneyType.in && (
                              <Col className={"col-md-4 mb-2"}>
                                <FormInput
                                  disabled={isLocked}
                                  inputRootClass={"w-100 h-100"}
                                  label={"Receipt No"}
                                  inputBaseClass={"w-100 h-100"}
                                  value={fromAccountList[rindex]?.receiptNo}
                                  baseClass={"w-100 h-100"}
                                  inputClass={"w-100 h-100"}
                                  type={FormInputType.default}
                                  onError={(isError) => {}}
                                  onTextChange={(text) => {
                                    let list = [...fromAccountList];
                                    let object = {
                                      ...list[rindex],
                                      receiptNo: text,
                                    };
                                    list[rindex] = object;
                                    setFromAccountList(list);
                                  }}
                                />
                              </Col>
                            )}
                            {transactionType === TransactionMoneyType.out && (
                              <Col className={"col-md-4 mb-2"}>
                                <FormInput
                                  disabled={isLocked}
                                  inputRootClass={"w-100 h-100"}
                                  label={"Expense No"}
                                  preExpenseNo={preExpenseNo}
                                  // inputBaseClass={"w-100 h-100"}
                                  value={fromAccountList[rindex]?.expenseNo}
                                  // baseClass={"w-100 h-100"}
                                  inputClass={"h-100"}
                                  type={FormInputType.expenseNo}
                                  maxLength={4}
                                  minLength={4}
                                  onError={(isError) => {}}
                                  onTextChange={(text) => {
                                    let list = [...fromAccountList];
                                    let object = {
                                      ...list[rindex],
                                      expenseNo: text,
                                    };
                                    list[rindex] = object;
                                    setFromAccountList(list);
                                    setIsExpenseNoTouched(true);
                                  }}
                                />
                                {isExpenseNoTouched &&
                                  fromAccountList[fromAccountList.length - 1]
                                    .expenseNo !== undefined &&
                                  fromAccountList[fromAccountList.length - 1]
                                    .expenseNo.length < 4 && (
                                    <div>
                                      <span className="invalid">
                                        Expense No must be 4 digits
                                      </span>
                                    </div>
                                  )}
                              </Col>
                            )}

                            <Col className={"col-md-4 mb-2"}>
                              {transactionType === TransactionMoneyType.out && (
                                <>
                                  <FormInput
                                    disabled={isLocked}
                                    label={"Cheque/Ref No"}
                                    baseClass={"mb-1"}
                                    value={fromAccountList[rindex]?.chequeNo}
                                    type={FormInputType.default}
                                    onError={(isError) => {}}
                                    onTextChange={(text) => {
                                      let list = [...fromAccountList];
                                      let object = {
                                        ...list[rindex],
                                        chequeNo: text,
                                      };
                                      list[rindex] = object;
                                      setFromAccountList(list);
                                    }}
                                  />
                                </>
                              )}
                              {transactionType === TransactionMoneyType.in && (
                                <>
                                  <FormInput
                                    disabled={isLocked}
                                    label={"Lodgement/Ref No"}
                                    value={fromAccountList[rindex]?.LodgementNo}
                                    baseClass={"mb-1"}
                                    type={FormInputType.default}
                                    onError={(isError) => {}}
                                    onTextChange={(text) => {
                                      let list = [...fromAccountList];
                                      let object = {
                                        ...list[rindex],
                                        LodgementNo: text,
                                      };
                                      list[rindex] = object;
                                      setFromAccountList(list);
                                    }}
                                  />
                                </>
                              )}
                              {(transactionType === TransactionMoneyType.in ||
                                transactionType === TransactionMoneyType.out) &&
                                fromAccountList[rindex]?.account
                                  ?.accountType === TranscationTypes.bank && (
                                  <div className="d-flex align-items-center flex-wrap">
                                    <div className="form-group me-5 mb-0">
                                      <FormCheckbox
                                        disabled={isLocked}
                                        id={"check-box"}
                                        label={"Is Cheque"}
                                        className=""
                                        name="status"
                                        checked={
                                          fromAccountList[rindex]?.isCheque ??
                                          false
                                        }
                                        onChange={(e) => {
                                          let list = [...fromAccountList];
                                          let object = {
                                            ...list[rindex],
                                            isCheque: e.target.checked,
                                          };
                                          list[rindex] = object;
                                          setFromAccountList(list);
                                        }}
                                      />
                                    </div>
                                    {fromAccountList[rindex]?.isCheque && (
                                      <>
                                        <div className="form-group width-300px_-39px mb-0">
                                          <div className="form-control-wrap">
                                            <RadioButtonOptions
                                              disabled={isLocked}
                                              name="chequeStatus"
                                              optionType={
                                                RadioOptionTypes.ChequeStatus
                                              }
                                              onChange={(index) => {
                                                let list = [...fromAccountList];
                                                let object = {
                                                  ...list[rindex],
                                                  chequeStatus: index === 0,
                                                };

                                                if (index === 0) {
                                                  object.clearanceDate = null;
                                                } else if (index === 1) {
                                                  setShowClearDateModal(true);
                                                }

                                                list[rindex] = object;
                                                setFromAccountList(list);
                                              }}
                                              activeIndex={
                                                fromAccountList[rindex]
                                                  ?.chequeStatus
                                                  ? 0
                                                  : 1
                                              }
                                            />
                                            {showClearDateModal && (
                                              <ClearDateModal
                                                isOpen={showClearDateModal}
                                                item={rItem?.clearanceDate}
                                                onClose={() => {
                                                  setShowClearDateModal(false);
                                                  let list = [
                                                    ...fromAccountList,
                                                  ];
                                                  let object = {
                                                    ...list[rindex],
                                                    chequeStatus: true,
                                                    clearanceDate: null,
                                                  };
                                                  list[rindex] = object;
                                                  setFromAccountList(list);
                                                }}
                                                onSave={(date) => {
                                                  let list = [
                                                    ...fromAccountList,
                                                  ];
                                                  let object = {
                                                    ...list[rindex],
                                                    clearanceDate: date,
                                                  };
                                                  list[rindex] = object;
                                                  setFromAccountList(list);
                                                  setShowClearDateModal(false);
                                                }}
                                              />
                                            )}
                                          </div>
                                        </div>

                                        {fromAccountList[rindex]
                                          .clearanceDate &&
                                          fromAccountList[rindex]
                                            .chequeStatus === false && (
                                            <div className="selected-date">
                                              {moment(
                                                fromAccountList[rindex]
                                                  .clearanceDate
                                              ).format("DD-MM-YYYY")}
                                            </div>
                                          )}
                                      </>
                                    )}
                                  </div>
                                )}
                            </Col>
                            <Col className={"col-md-4 mb-2"}>
                              <FormInput
                                disabled={isLocked}
                                label={"Amount"}
                                inputRootClass={"w-100 h-100"}
                                value={fromAccountList[rindex]?.amount}
                                baseClass={"w-100 h-100"}
                                inputClass={"h-100"}
                                type={FormInputType.amount}
                                onError={(isError) => {}}
                                onTextChange={(text) => {
                                  let list = [...fromAccountList];
                                  let object = {
                                    ...list[rindex],
                                    amount: text,
                                  };
                                  list[rindex] = object;
                                  setFromAccountList(list);
                                }}
                              />
                            </Col>

                            {rindex > 0 && (
                              <UncontrolledDropdown className="position-absolute end-0 w-auto top-n12">
                                <DropdownToggle
                                  tag="a"
                                  className="text-soft dropdown-toggle btn btn-icon btn-trigger table-edit-btn"
                                >
                                  <span
                                    onClick={() => {
                                      onRemove(rindex);
                                    }}
                                  >
                                    <Icon name="trash"></Icon>
                                  </span>
                                </DropdownToggle>
                              </UncontrolledDropdown>
                            )}
                          </Row>
                        </React.Fragment>
                      ))}
                  </div>
                  <div className="">
                    <div className="d-flex align-items-center justify-content-end">
                      <span className="fs-5 fw-medium">Total : </span>
                      <span className="ms-1">
                        {numberFormatter(
                          CURRENCY_CODE,
                          " " +
                            `${fromAccountList
                              ?.map((item) =>
                                item?.amount?.length > 0
                                  ? parseFloat(
                                      covertToNumberOnly(item?.amount ?? 0)
                                    )
                                  : 0
                              )
                              .reduce((prev, next) => (prev = prev + next))}`
                        )}
                      </span>
                    </div>
                  </div>
                </Block>

                <Block className={""}>
                  <div className="form-group ">
                    <div className="form-control-wrap d-flex ">
                      <IconButton
                        className="btn btn-primary ms-auto"
                        icon="plus"
                        onClick={() => handleAddRowTo()}
                        disabled={isLocked}
                      />
                    </div>
                  </div>
                  {transactionType === TransactionMoneyType.transfer && (
                    <span className="fs-4">In</span>
                  )}

                  <DataTable className="card-stretch transaction-ui mt-1">
                    <DataTableBody>
                      <DataTableHead>
                        <DataTableRow>
                          <span className="sub-text fw-medium fs-14px">
                            Account
                          </span>
                        </DataTableRow>
                        <DataTableRow>
                          <span className="sub-text fw-medium fs-14px">
                            Description
                          </span>
                        </DataTableRow>
                        <DataTableRow>
                          <span className="sub-text fw-medium fs-14px">
                            Amount
                          </span>
                        </DataTableRow>
                        <DataTableRow></DataTableRow>
                      </DataTableHead>
                      {/*Head*/}
                      {toAccountList?.length > 0 &&
                        toAccountList?.map((rItem, rindex) => (
                          <React.Fragment key={rindex}>
                            <DataTableItem
                              className={"cursor table-rows"}
                              onClick={() => ""}
                            >
                              <DataTableRow>
                                <AccountDropDown
                                  locationId={
                                    selectedLocation?.id ?? locationId
                                  }
                                  className="w-100"
                                  disabled={isLocked}
                                  placeholder={"Select Resident / Account"}
                                  accountTypes={
                                    transactionType !==
                                    TransactionMoneyType.transfer
                                      ? [
                                          TranscationTypes.other,
                                          TranscationTypes.resident,
                                        ]
                                      : [
                                          TranscationTypes.cash,
                                          TranscationTypes.bank,
                                        ]
                                  }
                                  value={toAccountList[rindex]?.account}
                                  compareValue={selectedDropValue}
                                  onceTimeSelected={true}
                                  onSelect={(account) => {
                                    let list = [...toAccountList];
                                    let object = {
                                      ...list[rindex],
                                      account: account,
                                    };
                                    list[rindex] = object;
                                    setToAccountList(list);
                                    if (
                                      !selectedDropValue?.some(
                                        (item) => item === account
                                      )
                                    ) {
                                      let selectedDropValueList = [
                                        ...selectedDropValue,
                                      ];
                                      selectedDropValueList[rindex] = account;
                                      setSelectedDropValue(
                                        selectedDropValueList
                                      );
                                    }
                                  }}
                                />
                              </DataTableRow>
                              <DataTableRow>
                                <FormInput
                                  disabled={isLocked}
                                  inputRootClass={"w-100 h-100"}
                                  inputBaseClass={"w-100 h-100"}
                                  value={toAccountList[rindex]?.description}
                                  baseClass={"w-100 h-100"}
                                  inputClass={"border-0 w-100 h-100"}
                                  type={FormInputType.default}
                                  // isError={toAccountList[rindex]?.isdescription}
                                  onTextChange={(text) => {
                                    let list = [...toAccountList];
                                    let object = {
                                      ...list[rindex],
                                      description: text,
                                    };
                                    list[rindex] = object;
                                    setToAccountList(list);
                                  }}
                                />
                              </DataTableRow>
                              <DataTableRow>
                                <FormInput
                                  disabled={isLocked}
                                  inputRootClass={"w-100 h-100"}
                                  inputBaseClass={"w-100 h-100"}
                                  value={toAccountList[rindex]?.amount}
                                  baseClass={"w-100 h-100"}
                                  inputClass={"border-0 h-100"}
                                  type={FormInputType.amount}
                                  onTextChange={(text) => {
                                    let list = [...toAccountList];
                                    let item = list[rindex];
                                    // Remove condition for other account such as Bank fee and Suspence
                                    // As discuss with Nilay/Preyam during zoom call on Friday 1 Dec, 2023
                                    // Need to allow mins entry in other others too.

                                    let diffAmount =
                                      item.account.accountType !==
                                      TranscationTypes.other
                                        ? covertToNumberOnly(text) -
                                          (item?.originalAmount ?? 0)
                                        : 0;
                                    let object = {
                                      ...item,
                                      amount: text,
                                      diffAmount: diffAmount,
                                    };
                                    list[rindex] = object;
                                    setToAccountList(list);
                                  }}
                                  rightIcon={
                                    toAccountList[rindex]?.account?.balance -
                                      toAccountList[rindex]?.diffAmount <
                                      0 &&
                                    transactionType ===
                                      TransactionMoneyType.out ? (
                                      <span className="input-group-text bg-danger-dim">
                                        <TooltipComponent
                                          icon="info-fill"
                                          iconClass="card-hint text-danger"
                                          direction="right"
                                          id={"tooltip" + rindex}
                                          // text={`${toAccountList[rindex]?.account?.value} Account balance is become negative so tranaction is not allowed.`}
                                          text={`${toAccountList[rindex]?.account?.value} Account balance is negative.`}
                                        />
                                      </span>
                                    ) : null
                                  }
                                />
                              </DataTableRow>

                              <DataTableRow>
                                {rindex > 0 && !isLocked && (
                                  <UncontrolledDropdown>
                                    <DropdownToggle
                                      tag="a"
                                      className="text-soft dropdown-toggle btn btn-icon btn-trigger table-edit-btn"
                                    >
                                      <span
                                        onClick={() => {
                                          onRemoveTo(rindex, rItem);
                                        }}
                                      >
                                        <Icon name="trash"></Icon>
                                      </span>
                                    </DropdownToggle>
                                  </UncontrolledDropdown>
                                )}
                              </DataTableRow>
                            </DataTableItem>
                          </React.Fragment>
                        ))}
                    </DataTableBody>
                  </DataTable>
                  <div className="mt-2">
                    <div className="d-flex align-items-end flex-column justify-content-end">
                      <div className="d-flex align-items-center ">
                        <span className="fs-5 fw-medium">Total : </span>
                        <span className="ms-1">
                          {numberFormatter(
                            CURRENCY_CODE,
                            " " +
                              `${toAccountList
                                ?.map((item) =>
                                  item?.amount?.length > 0
                                    ? parseFloat(
                                        covertToNumberOnly(item?.amount ?? 0)
                                      )
                                    : 0
                                )
                                .reduce((prev, next) => (prev = prev + next))}`
                          )}
                        </span>
                      </div>
                      {!isCompairAmount &&
                        fromAccountList.some((item) => item?.amount) &&
                        toAccountList.some((item) => item?.amount) && (
                          <div>
                            <span className="invalid">
                              Both total amount must be same, please check
                            </span>
                          </div>
                        )}
                    </div>
                  </div>
                </Block>
                {!isLocked && (
                  <div
                    className="form-group mb-0 ms-3"
                    style={{ marginTop: 14 }}
                  >
                    <label htmlFor="files">
                      <Icon name="clip" className="mb-1 text-primary" />
                      <span className="text-primary"> Attach file</span>
                    </label>
                    <input
                      disabled={isLocked}
                      type="file"
                      name="files"
                      accept=".pdf,.doc,.docx,.xlsx,.xls,image/*"
                      className="form-control"
                      id={"files"}
                      multiple
                      style={{ display: "none" }}
                      onChange={(e) => {
                        let temp = Array.prototype.slice.call(e.target.files);
                        if (temp.length > 0) {
                          handleFileSelect(temp[0]);
                        }
                      }}
                    />
                  </div>
                )}
                {files && files.length > 0 ? (
                  <div className="selected-file-container ">
                    {files.map((file, ind) => (
                      <div className="nk-upload-item" key={`file-${ind}`}>
                        <div
                          className="nk-upload-icon cursor-pointer"
                          onClick={() => {
                            openLinkInNewTab(file?.fileUrl);
                          }}
                        >
                          {file?.isOldFile ? (
                            <>
                              {iconsType[file?.mimeType]
                                ? iconsType[file?.mimeType]
                                : iconsType["others"]}
                            </>
                          ) : (
                            <>
                              {iconsType[file?.type]
                                ? iconsType[file?.type]
                                : iconsType["others"]}
                            </>
                          )}
                        </div>
                        <div
                          className="nk-upload-info cursor-pointer"
                          onClick={() => openLinkInNewTab(file?.fileUrl)}
                        >
                          <div className="nk-upload-title">
                            <span className="title ">{file?.name}</span>
                          </div>
                        </div>
                        <div className="nk-upload-action">
                          <a
                            href="#delete"
                            onClick={(ev) => {
                              ev.preventDefault();
                            }}
                            className="btn btn-icon btn-trigger round-40  d-flex justify-content-center align-items-center"
                          >
                            {file?.isLoading ? (
                              <Spinner size="sm" color="dark" />
                            ) : (
                              <>
                                {!isLocked && (
                                  <span onClick={() => handleFileRemove(ind)}>
                                    <Icon name="trash"></Icon>
                                  </span>
                                )}
                              </>
                            )}
                          </a>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
                <ul className="align-center justify-content-end flex-wrap flex-sm-nowrap gx-4 gy-2 mt-3">
                  <li>
                    <BackButton cancel />
                  </li>
                  <li>
                    <ActionButton
                      title="Save"
                      loading={isLoading}
                      type={"submit"}
                      disabled={isDisable || isLocked}
                      onClick={() => onSaveTransiction()}
                    />
                  </li>
                </ul>
              </PreviewCard>
              {isCheckToList(UserPermissionType.auditTrail) &&
                location?.state?.TransactionId && (
                  <AuditTrailDatatable
                    title={"Audit Trail"}
                    transactionId={location?.state?.TransactionId}
                  />
                )}
            </Block>
          </>
        )}
        {/* <Block></Block> */}
      </Content>
    </React.Fragment>
  );
};

export default AddTransaction;
