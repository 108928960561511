import moment from "moment";
import {
  AnnsBadge,
  BalanceBadge,
  TableTitle,
  UserAvatar,
} from "../../../components/Component";
import Icon from "./Icon";
import { TranscationTypes } from "./TranscationType";
import { DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { Unpresent } from "./Unpresent";
import { useState } from "react";
import CustomPopOver from "./CustomPopOver";
import { CURRENCY_CODE, ChequeStatusType } from "../../../utils";
import { numberFormatter, toCapitalize } from "../../../utils/Functions";
import {
  UserActionType,
  UserPermissionType,
  isAllowToAccess,
} from "../../../services/permission/permission";

export const TransactionData = () => [
  {
    date: moment().format("YYYY-MM-DD"),
    residentName: "Dinial Shoff",
    description: "Tea",
    residentno: "R12345",
    refno: "SROO84",
    chequeno: "N/A",
    balance: "£500",
    amount: "£10",
    status: "Unpresent",
    clearanceDate: null,
  },
  {
    date: moment().format("YYYY-MM-DD"),
    residentName: "Dinial Shoff",
    description: "Tea",
    residentno: "R12345",
    refno: "SROO84",
    chequeno: "N/A",
    balance: "£200",
    amount: "£5",
  },
  {
    date: moment().format("YYYY-MM-DD"),
    residentName: "Dinial Shoff",
    description: "Tea",
    residentno: "R12345",
    refno: "SROO84",
    chequeno: "N/A",
    balance: "£300",
    amount: "£20",
  },
];

export const transactionDataTable = (
  type,
  onEdit,
  onChangeChequeStatus,
  isDashboardPage
) => {
  return [
    {
      name: <TableTitle title={"Date"} />,
      selector: (row) => {
        const formattedDate = moment(row?.date).format("DD-MM-YYYY");
        return formattedDate;
      },
      width: "120px",
    },
    {
      name: <TableTitle title={"Account"} />,
      selector: (row) => "Account Type",
      cell: (row) => (
        <CustomPopOver data={row}>
          {`${row?.transactionAccounts[0]?.label} `}
          {row?.transactionAccounts?.length > 1 && (
            <AnnsBadge
              color={"primary"}
              label={`+ ${row?.transactionAccounts?.length - 1}`}
            />
          )}
        </CustomPopOver>
      ),
    },
    {
      name: <TableTitle title={"Description"} />,
      selector: (row) => row?.description ?? "",
      omit: type === TranscationTypes.dashboard ? true : false,
    },
    {
      name: <TableTitle title={"Receipt/Expense No"} />,
      selector: (row) =>
        row?.receiptNumber?.length > 0 ? row?.receiptNumber : row?.expenseNo,
      width: "200px",
    },
    {
      name: <TableTitle title={"Lodgement/Cheque No"} />,
      selector: (row) => row?.lodgementNumber ?? row?.chequeNo,
      omit: type === TranscationTypes.cash ? true : false,
      width: "200px",
    },
    {
      name: <TableTitle title={"Amount"} />,
      selector: (row) => row?.amount ?? "",
      cell: (row) => (
        <>
          {row?.type === "OUT" ? (
            <div className=" justify-content-center align-center bg-danger-dim rounded-5 me-1 rounded-circle">
              <Icon name="arrow-up-left danger" className={"text-danger"} />
            </div>
          ) : (
            <div className=" justify-content-center align-center bg-success-dim rounded-5 me-1 rounded-circle">
              <Icon name="arrow-down-right sucess" />
            </div>
          )}
          <div>{numberFormatter(CURRENCY_CODE, "" + row?.amount ?? "")}</div>
        </>
      ),
    },
    {
      name: <TableTitle title={"Status"} />,
      omit:
        type === TranscationTypes.cash ||
        type === TranscationTypes.other ||
        type === TranscationTypes.resident ||
        type === TranscationTypes.dashboard
          ? true
          : false,
      cell: (row, rowIndex) => (
        <>
          {row?.amount > 0 &&
          row?.clearanceStatus === ChequeStatusType.unpresented ? (
            <div className="d-flex align-items-center">
              <Unpresent
                selected={
                  row?.clearanceStatus
                    ? toCapitalize(row?.clearanceStatus)
                    : null
                }
                options={[
                  {
                    text: "Unpresent",
                    action: () => {
                      onChangeChequeStatus(
                        row,
                        rowIndex,
                        ChequeStatusType.unpresented
                      );
                    },
                  },
                  {
                    text: "Clear",
                    action: () => {
                      onChangeChequeStatus(
                        row,
                        rowIndex,
                        ChequeStatusType.clear
                      );
                      return row?.clearanceDate
                        ? moment(row?.clearanceDate).format("DD-MM-YYYY")
                        : null;
                    },
                  },
                ]}
              />
            </div>
          ) : row?.clearanceStatus === ChequeStatusType.clear ? (
            <div>
              {row?.clearanceDate
                ? moment(row?.clearanceDate).format("DD-MM-YYYY")
                : null}
            </div>
          ) : null}
        </>
      ),
      width: "150px",
    },
    {
      name: <TableTitle title={"Balance"} />,
      omit: type === "",
      selector: (row) => (
        // row?.balance >= 0 ? (
        <span className="text-secondary fw-medium">
          {numberFormatter(CURRENCY_CODE, "" + (row?.balance ?? 0))}
          {/* {row?.balance} */}
        </span>
      ),
      // ) : // <BalanceBadge balance={row?.balance} isLow={false} />
      // null,
    },
    isAllowToAccess(UserPermissionType.transaction, UserActionType.edit) && {
      name: <TableTitle title={""} align="end" />,
      right: true,
      width: "70px",
      omit: isDashboardPage ? true : false,
      cell: (row) => {
        const isLocked = row?.recoIsLocked === 1;
        return (
          <>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="text-soft dropdown-toggle btn btn-icon btn-trigger table-edit-btn"
                onClick={() => onEdit(row?.type, row?.transId)}
              >
                <Icon name={isLocked ? "eye" : "edit"}></Icon>
              </DropdownToggle>
            </UncontrolledDropdown>
          </>
        );
      },
    },
  ];
};
