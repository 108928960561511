import React, { useEffect } from "react";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";

import {
  ActionButton,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  ConfirmationAlert,
  LoadingComponent,
  PreviewCard,
  ReactDataTable,
  StatusActiveDropDown,
} from "../../../../components/Component";
import { useState } from "react";
import {
  DataBranch,
  LocationTableData,
  branchTableData,
  listBranchAPI,
  listLocationAPI,
} from "./LocationTableData";
import AddBranch from "./AddLocation";
import { PER_PAGE_ITEMS, ServerStatusType } from "../../../../utils";
import {
  UserActionType,
  UserPermissionType,
  isAllowToAccess,
  isCheckToList,
} from "../../../../services/permission/permission";

const LocationList = (props) => {
  const [branchList, setBranchList] = useState([]);
  const [initScreen, setInitScreen] = useState(false);
  const [status, setStatus] = useState();
  const [modal, setModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    loadBranch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (initScreen) return;
    if (currentPage === 1) {
      loadBranch();
    } else {
      setCurrentPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const onEdit = (row) => {
    setSelectedItem(row);
    setModal(true);
  };

  function onRemove(row) {
    ConfirmationAlert(
      "Removing Branch!",
      "Do you want to remove branch " + row.name + "?",
      () => {
        setBranchList((prevData) => {
          const newData = [...prevData];
          newData.splice(row.index, 1);
          return newData;
        });
      }
    );
  }

  function loadBranch() {
    isCheckToList(UserPermissionType.location) && setInitScreen(true);
    listLocationAPI(
      {
        status: status?.value ?? ServerStatusType.active,
        pageInfo: {
          pageIndex: currentPage,
          itemsPerPage: PER_PAGE_ITEMS,
        },
      },
      (data, error) => {
        setInitScreen(true);
        if (error === null) {
          setBranchList(data?.list ?? []);
          if (currentPage === 1) {
            setTotalItems(data?.pageInfo?.totalItems ?? 0);
          }
        }
        setInitScreen(false);
      }
    );
  }

  return (
    <React.Fragment>
      <Head title="Location" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Location</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="d-flex align-items-center">
                <span className="me-2">
                  <StatusActiveDropDown
                    value={status}
                    className="w-100px "
                    onSelect={(e) => {
                      setStatus(e);
                    }}
                  />
                </span>
                {isAllowToAccess(
                  UserPermissionType.location,
                  UserActionType.add
                ) && (
                  <span>
                    <ActionButton
                      title="Add"
                      size="md"
                      onClick={() => setModal(true)}
                    />
                  </span>
                )}
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <PreviewCard>
            <ReactDataTable
              data={branchList}
              isAllowed={isCheckToList(UserPermissionType.location)}
              columns={LocationTableData(onEdit, onRemove)}
              expandableRows
              pagination
              totalItemCount={totalItems}
              onPageChanged={(page, count) => {
                setCurrentPage(page);
              }}
            />
          </PreviewCard>
        </Block>
        <AddBranch
          visible={modal}
          item={selectedItem}
          onClosed={() => {
            setModal(false);
            setSelectedItem();
          }}
          onSaved={() => {
            loadBranch();
          }}
        />
      </Content>
      <LoadingComponent isLoading={initScreen} />
    </React.Fragment>
  );
};

export default LocationList;
