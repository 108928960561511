import Phone from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useState } from "react";
import { AppText } from "../../../utils";
import { ErrorText } from "../../Component";

export const PhoneInput = ({
  label = "Phone number",
  phone,
  countryCode,
  required,
  baseClass,
  labelGroupClass,
  labelClass,
  onError = () => {},
  onChange = () => {},
}) => {
    const [error, setError] = useState(null);

    const isValidateInput = (data) => {
        // let text = data?.format?.replace(/[^.]/g, '');
        // let length = text?.length;
        let valueLength = (phone ?? "").length;
        if(valueLength) {
            let IsError = valueLength !== 10;
            onError(IsError)
            setError(IsError ? AppText.invalidPhone : null);
        }       
      };
    
  return (
    <div className={"form-group margin-t-n3" + (baseClass ? baseClass : "")}>
      <div className={"form-label-group " + (labelGroupClass ? labelGroupClass : "")}>
        <label className={"form-label " + (labelClass ? labelClass : "")} htmlFor={label?.toLowerCase()}>
          {label} {required && <span className="text-danger fs-6">*</span>}
        </label>
      </div>
      <div className="form-control-wrap">
        <Phone
          countryCodeEditable={false}
          country={"gb"}
          value={(countryCode ?? "") + (phone ?? "")}
          onChange={(value, data, event, formattedValue) => {
            event.preventDefault();
            let phoneNumber = value.slice(data.dialCode.length , value.length);
            let dialCode = data.dialCode;
            onChange({
                phoneNumber: phoneNumber,
                dialCode: dialCode,
                formattedValue: formattedValue,
            })
            if(data.dialCode !== countryCode && phone?.length > 0) {
                isValidateInput(data);
            }
          }}
          containerClass={"h-36"}
          inputClass="w-100"
          onBlur={(value, data) => {
            isValidateInput(data);
          }}
        />
        {error && <ErrorText>{error}</ErrorText>}
      </div>
    </div>
  );
};
