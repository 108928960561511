import React from "react"; // Replace 'your-ui-library' with the actual UI library you are using
import { useHistory } from "react-router";
import Button from "./Button";
import { Icon } from "../Component";
const BackButton = ({ cancel, backUrl }) => {
  const history = useHistory();

  const handleGoBack = () => {
    if (backUrl) {
      history.push(backUrl);
    } else {
      history.goBack();
    }
  };

  return (
    <>
      <Button
        color="light"
        type="button"
        outline
        className="bg-white d-none d-sm-inline-flex"
        onClick={handleGoBack}
      >
        {!cancel ? (
          <>
            <Icon name="arrow-left" />
            <span>Back</span>
          </>
        ) : (
          "Cancel"
        )}
      </Button>
    </>
  );
};

export default BackButton;
