import React from "react";
import { Button, Icon } from "../Component";

const AnnsBadge = ({
  color,
  label,
  onRemove,
  onText,
  onAdd,
  className,
  status,
  ...props
}) => {
  return (
    <li className="btn-group">
      <Button
        color={color || "light"}
        size="xs"
        className={`btn-dim ${className}`}
        onClick={onText}
      >
        {label ?? props.children}
      </Button>
      {onRemove && (status === "OPEN" || status === "APPLIED") && (
        <Button
          color={color || "light"}
          size="xs"
          className="btn-icon btn-dim"
          onClick={onRemove}
        >
          <Icon name="cross"></Icon>
        </Button>
      )}
      {onAdd && (status === "OPEN" || status === "APPLIED") && (
        <Button
          color={color || "light"}
          size="xs"
          className="btn-icon btn-dim"
          onClick={onAdd}
        >
          <Icon name={"plus"}></Icon>
        </Button>
      )}
    </li>
  );
};

export default AnnsBadge;
