import React, { useEffect, useState } from "react";
import { RSelect } from "../Component";

export const PermissionList = [
  { id: 1, label: "Head Office", value: "ADMIN" },
  { id: 2, label: "Manager", value: "MANAGER" },
  { id: 3, label: "Home Admin", value: "STAFF" },
];

export const PermissionType = {
  admin: "ADMIN",
  manager: "MANAGER",
  staff: "STAFF",
};

export const PermissionDropdown = ({
  value,
  isMulti = false,
  isShowAll = false,
  onSelect,
  placeholder,
  ...props
}) => {
  const [list, setList] = useState([]);

  useEffect(() => {
    loadRolesList();
  }, []);

  const loadRolesList = () => {
    if (isShowAll) {
      let all = {
        id: 0,
        label: "All Roles",
        value: "All",
      };
      setList([all, ...PermissionList]);
    } else {
      setList(PermissionList);
    }
  };

  return (
    <RSelect
      options={list}
      isMulti={isMulti}
      placeholder={placeholder}
      value={value}
      onChange={(e) => {
        if (isShowAll && e.id === 0) {
          onSelect(undefined);
        } else {
          onSelect(e);
        }
      }}
      {...props}
    />
  );
};
