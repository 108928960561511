import React, { useEffect, useState } from "react";
import {
  LoadingComponent,
  ReactDataTable,
} from "../../../components/Component";

import { transactionDataTable } from "./TransactionDataTable";
import { fetchTransactionList } from "./TransactionData";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import {
  ChequeStatusType,
  PER_PAGE_ITEMS,
  TransactionMoneyType,
} from "../../../utils";
import moment from "moment/moment";
import { useLocation } from "react-router-dom";
import { DateModal } from "../../../components/DateModal/DateModal";
import {
  UserPermissionType,
  isCheckToList,
} from "../../../services/permission/permission";

export const TranscationTypes = {
  cash: "CASH",
  bank: "BANK",
  resident: "RESIDENT",
  other: "OTHER",
  dashboard: "DASHBOARD",
};

const TranscationType = ({
  type,
  accountId,
  locationId,
  startDate,
  endDate,
}) => {
  const history = useHistory();
  const [initScreen, setInitScreen] = useState(false);
  const [data, setData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rowData, setRowData] = useState();

  useEffect(() => {
    loadTransactionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (currentPage === 1) {
      loadTransactionList();
      // loadTransactionDetails();
    } else {
      setCurrentPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, startDate, endDate, locationId]);

  const loadTransactionList = () => {
    if (!locationId || !startDate || !endDate) {
      return;
    }
    setInitScreen(true);
    let params = {
      pageInfo: {
        pageIndex: currentPage,
        itemsPerPage: PER_PAGE_ITEMS,
      },
      accountTypes:
        type === "" ? [TranscationTypes.cash, TranscationTypes.bank] : [type],
      locationId: locationId,
      accountId: accountId,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
    };
    isCheckToList(UserPermissionType.transaction) &&
      fetchTransactionList(params, (data, error) => {
        setInitScreen(true);
        if (error === null) {
          setData(data?.list);
          if (currentPage === 1) {
            setTotalItems(data?.pageInfo?.totalItems ?? 0);
          }
        }
        setInitScreen(false);
      });
  };
  const onEdit = (tType, TransactionId) => {
    history.push({
      pathname: `${process.env.PUBLIC_URL}/add-transaction`,
      state: {
        type:
          tType === "IN" ? TransactionMoneyType.in : TransactionMoneyType.out,
        TransactionId: TransactionId,
        isEditTransaction: true,
      },
    });
  };
  // API------------------------------------------------------------>

  const onChangeChequeStatus = (row, rowIndex, chequeSatus) => {
    // let params = {
    //   transItemId: row?.id,
    //   status: chequeSatus,
    //   clearanceDate: moment(selectedModalDate).format("YYYY-MM-DD"),
    // };
    // fetchChequeStatusUpdate(params, (_, error) => {
    //   if (error === null) {
    //     let item = row;
    //     item.clearanceStatus = chequeSatus;
    //     let list = [...data];
    //     list[rowIndex] = item;
    //     setData(list);
    //   }
    // });
    if (chequeSatus === ChequeStatusType.clear) {
      setIsModalOpen(true);
      setRowData(row);
    }
  };

  const toggleModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div
        className={`mt-4 ${
          type === TranscationTypes.dashboard ? "table-scroll-body" : ""
        }`}
      >
        <ReactDataTable
          data={data}
          isAllowed={isCheckToList(UserPermissionType.transaction)}
          columns={transactionDataTable(
            type,
            onEdit,
            onChangeChequeStatus,
            location.pathname === "/"
          )}
          pagination={location.pathname === "/transaction"}
          totalItemCount={totalItems}
          onPageChanged={(page, count) => {
            setCurrentPage(page);
          }}
        />
      </div>
      <LoadingComponent isLoading={initScreen} />
      <DateModal
        title={"Select Clear Date"}
        isOpen={isModalOpen}
        toggle={toggleModal}
        handleSave={() => loadTransactionList()}
        rowId={rowData?.id}
      />
    </>
  );
};

export default TranscationType;
