import React, { useEffect, useState } from "react";
import { RSelect } from "../Component";
import { locationDropdownData } from "./DropDownData";
import { useDispatch, useSelector } from "react-redux";
import { PermissionType } from "./PermissionDropdown";
import { convertDropValue } from "../../utils";
import { setCurrentLocation } from "../../store/authSlice";

export const LocationDropDown = ({
  value,
  isMulti = false,
  onSelect,
  selectedId,
  isLoadList = true,
  placeholder,
  ...props
}) => {
  const dispatch = useDispatch();
  const sessionUser = useSelector((state) => state?.auth?.sessionUser);
  const location = useSelector((state) => state?.auth?.currentLocation);
  const userType = sessionUser?.userType;

  const [list, setList] = useState();

  useEffect(() => {
    onSelect(location);
  }, [location]);

  useEffect(() => {
    if (
      (userType === PermissionType.admin ||
        userType === PermissionType.manager) &&
      isLoadList
    ) {
      loadLocationList();
    } else if (userType === PermissionType.staff) {
      onSelect(location);
    }
  }, []);

  const loadLocationList = () => {
    locationDropdownData((data, error) => {
      if (error === null) {
        setList(data);
        dispatch(setCurrentLocation(data[0]));
        // onSelect(data[0] ?? null);
      }
    });
  };

  useEffect(() => {
    if (selectedId) {
      const selectedOption = list.find((option) => option.id === selectedId);
      if (selectedOption) {
        // onSelect(selectedOption);
        dispatch(setCurrentLocation(selectedOption));
      }
    }
  }, [list]);

  return (
    <RSelect
      options={list ?? []}
      isMulti={isMulti}
      placeholder={placeholder}
      isDisabled={userType === PermissionType.staff}
      value={value ?? (list ? list[0] : null)}
      onChange={(e) => {
        // onSelect(e);
        dispatch(setCurrentLocation(e));
      }}
      {...props}
    />
  );
};
