import { Storage } from "../storage/storage";
import { APIDataConverter } from "./api-data-converter";
import { APIError, APIErrorCode, APIServiceBase } from "./api-service-base";

export const APIServicePath = {
  //Auth
  signin: "auth/sign-in",
  forgotPassword: "auth/reset-password",
  generateSession: "auth/login-with-token",
  checkPassword: "user/check-password",
  changePassword: "user/change-password",
  logout: "user/sign-out",
  verifyResetPasswordToken: "auth/verify-reset-password-token",
  resendEmail: "auth/resend-email-link",
  recoverPassword: "auth/recover-password",

  // Master List
  masterList: "common/master-list",

  // current user
  currentUser: "user/current-user",

  // Staff
  addUser: "user/save",
  listUser: "user/list",

  //Staff
  staffList: "staff/list",
  addstaff: "staff/save",

  // Notetype
  addNoteType: "note-type/save",
  listNoteType: "note-type/list",

  // user Role
  roleMaster: "role/Master",
  roleList: "role/list",
  roleSave: "role/save",
  roleDetail: "role/detail",

  // Branch
  addBranch: "branch/save",
  listBranch: "branch/list",

  //Location
  listLocation: "location/list",
  addLocation: "location/save",

  // RESIDENT
  residentSave: "resident/save",
  residentList: "resident/list",
  residentDetail: "resident/detail",
  residentExportXlsx: "resident/export-xlsx",
  residentExportPdf: "resident/export-pdf",

  // ACCOUNT
  accountList: "account/list",
  accountExportXlsx: "account/export-xlsx",
  accountExportPdf: "account/export-pdf",

  // TRANSACTION
  transactionSave: "transaction/save",
  transactionList: "transaction/list",
  transactionDetails: "transaction/detail",
  transactionRemove: "transaction/remove",
  transactionTransPdf: "transaction/trans-pdf",
  transactionExportXlsx: "transaction/export-xlsx",
  chequeStatusUpdate: "transaction/cheque-status-update",
  transactionMaxLockDate: "reco/lock-date",

  // RECO
  transactionReco: "transaction/reco",
  realBalance: "reco/real-balance",
  lockUnlock: "reco/lock-unlock",
  recoSignOff: "reco/sign-off",
  questionlist: "reco/question-list",
  recoPdf: "transaction/reco-pdf",
  requestUnlock: "reco/unlock-request",
  recoDateList: "reco/date-list",
  pendingReco: "reco/sign-off-pending",

  // Audit Trail
  auditTrail: "transaction/audit-trail",

  // LOCATION
  locationDetail: "location/detail",

  // COMMON
  fileUpload: "common/file-upload",

  // APP SETTING
  appSetting: "common/app-setting",
};

export const APIServiceMethod = {
  post: "POST",
  get: "GET",
  delete: "DELETE",
  put: "PUT",
};

export class APIServiceImpl extends APIServiceBase {
  constructor() {
    super();
    this.dataConverter = new APIDataConverter();
  }

  async handleAPIResopnse(response) {
    const json = await response.json();
    if ((response.status === 200 || response.status === 201) && json) {
      return json;
    } else {
      let error = new APIError(
        json?.errorCode ?? APIErrorCode.UnkonwnError,
        json?.message ?? null,
        json?.title ?? null,
        json?.isForceLogout ?? false
      );
      if (json?.isForceLogout === true) {
        Storage.clearAll();
        window.location.reload();
      }
      throw error;
    }
  }

  setUserToken(accessToken) {
    this.accessToken = accessToken;
  }

  handleAPIException(exception) {
    if (exception instanceof APIError) {
      return exception;
    } else {
      if (exception.code === 20 || exception.message === "Aborted") {
        // Abort Api call
        return new APIError(APIErrorCode.Aborted);
      } else {
        return new APIError(APIErrorCode.UnkonwnError);
      }
    }
  }

  handleAuthResponse(authResponse) {
    if (!authResponse.accessToken) {
      throw new APIError(APIErrorCode.UnexpectedAuthResponse);
    }
    this.accessToken = authResponse.accessToken;
  }

  // ************************Common API's*******************************//
  async postCall(path, req, timeout) {
    return await this.sessionAPICall(
      path,
      APIServiceMethod.post,
      req,
      null,
      timeout
    );
  }

  async postCallWithCancelSignal(path, req, token) {
    const controller = new window.AbortController();
    const signal = controller.signal;
    token.cancel = () => {
      controller.abort();
    };
    return await this.sessionAPICall(path, APIServiceMethod.post, req, signal);
  }

  async postPublicCall(path, req) {
    return await this.publicAPICall(path, APIServiceMethod.post, req);
  }

  async getCall(path, req) {
    return await this.sessionAPICall(path, APIServiceMethod.get, req);
  }

  async fileUploader(file, type, path) {
    if (file === undefined || file === null) {
      return null;
    }
    let body = new FormData();
    body.append("file", file);
    body.append("type", type);
    body.append("name", file.name);

    const result = await this.uploadImageApiCall(
      path,
      APIServiceMethod.post,
      body,
      120000
    );
    return result.data;
  }
}
