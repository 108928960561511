import React from "react";
import { Badge } from "reactstrap";
import { CURRENCY_CODE, numberFormatter } from "../../utils";

export const BalanceBadge = ({
  className,
  balance,
  isLow,
  isDashboard,
  ...props
}) => {
  const numericBalance = parseFloat(balance.replace(/[^\d.-]/g, "")); // Extracting numeric value

  const colorSelect = (balance) => {
    if (isDashboard) {
      if (numericBalance < 0) {
        return "danger"; // Red for negative balance
      } else if (isLow) {
        return "orange"; // Orange for low balance only for dashboard
      } else {
        return "success"; // Green for normal balance
      }
    } else {
      return isLow ? "danger" : "success";
    }
  };
  let selectedColor = colorSelect(balance);

  return (
    <Badge className="fs-14px fw-normal p-1" color={selectedColor}>
      {balance}
    </Badge>
  );
};
