import moment from "moment";
import {
  MoreOptions,
  StatusBadge,
  TableTitle,
} from "../../../../components/Component";
import { apiService } from "../../../../services/api";
import { APIServicePath } from "../../../../services/api/api-service-impl";
import { ServerStatusType } from "../../../../utils";
import {
  convertDropMultiValue,
  convertDropValue,
  covertToNumberOnly,
  removeTimeFromServerDateString,
  toCapitalize,
} from "../../../../utils/Functions";
import { toPageInfo } from "../../../_common/DataConverter";
import { PermissionList } from "../../../../components/dropdowns/PermissionDropdown";
import { UserActionType, UserPermissionType, isAllowToAccess } from "../../../../services/permission/permission";

export const userDataTableColumns = (onEdit, onRemove) => {
  return [
    {
      name: <TableTitle title={"Title"} />,
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: <TableTitle title={"Email"} />,
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: <TableTitle title={"Role"} />,
      selector: (row) => row.role?.label ?? "",
      sortable: true,
    },

    {
      name: <TableTitle title={"Last login"} />,
      selector: (row) =>
        row?.lastLogin ? row.lastLogin.utc().format("DD-MM-YYYY") : "-",
      sortable: true,
    },
    {
      name: <TableTitle title={"Status"} />,
      cell: (row) => <StatusBadge status={row?.isActive} />,
    },
    isAllowToAccess(UserPermissionType.staff, UserActionType.edit) && {
      name: <TableTitle title={""} align="end" />,
      width: "85px",
      cell: (row) => (
        <MoreOptions
          options={[
            {
              icon: "edit",
              text: "Edit",
              action: () => {
                onEdit(row);
              },
            },
          ]}
        />
      ),
    },
  ];
};

//***************************API's*********************************/
export function addStaffAPI(data, callback) {
  let params = {
    id: data?.id,
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    locationIds: data?.location?.map((item) => item.id) || [],
    userType: data?.permission?.value,
    roleId: data?.role?.id,
    status: data.isActive ? ServerStatusType.active : ServerStatusType.inactive,
    extraInfo: data?.extraInfo,
  };
  apiService
    .postCall(APIServicePath.addstaff, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function listStaffAPI(params, callback) {
  apiService
    .postCall(APIServicePath.staffList, params)
    .then((data) => {
      let list = data?.list ? toUserList(data?.list) : null;
      let pageInfo = data?.pageInfo ? toPageInfo(data?.pageInfo) : null;
      let result = { list, pageInfo };
      callback(result, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

//***************************Converters*********************************/

function toUserList(data) {
  let list = [];
  data?.forEach((element) => {
    list.push(toUser(element));
  });
  return list;
}

function toUser(data) {
  let user = {
    id: data.id,
    firstName: data?.firstName ?? "",
    lastName: data?.lastName ?? "",
    title:
      toCapitalize(data?.firstName ?? "") +
      " " +
      toCapitalize(data?.lastName ?? ""),
    email: data?.email ?? "",
    permission: data?.userType
      ? PermissionList.find((item) => item.value === data?.userType)
      : null,
    role: data?.role ? convertDropValue(data?.role) : null,
    location:
      data?.userLocations?.length > 0
        ? convertDropMultiValue(data?.userLocations)
        : null,
    extraInfo: data?.extraInfo ?? "",
    lastLogin: data?.lastLogin ? moment(data?.lastLogin) : null,
    isActive: data?.status === ServerStatusType.active ? true : false,
  };
  return user;
}
