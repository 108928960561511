import React from "react";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import {
  ActionButton,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  LoadingComponent,
  PreviewCard,
  ReactDataTable,
  StatusActiveDropDown,
} from "../../../../components/Component";
import { useEffect, useState } from "react";
import { fetchRoleList, userRoleTableData } from "./UserRoleTableData";
import { useHistory } from "react-router";
import { ServerStatusType } from "../../../../utils";
import {
  UserActionType,
  UserPermissionType,
  isAllowToAccess,
  isCheckToList,
} from "../../../../services/permission/permission";

const UserRoleList = () => {
  const [userRoleList, setUserRoleList] = useState([]);
  const [initScreen, setInitScreen] = useState(false);
  const [status, setStatus] = useState();
  const [searchText, setSearchText] = useState("");
  const [selectedItem, setSelectedItem] = useState();
  const history = useHistory();

  useEffect(() => {
    loadRoleList();
  }, [status]);

  const onEdit = (row) => {
    setSelectedItem(row); // Pass the id of the selected item
    history.push({
      pathname: `${process.env.PUBLIC_URL}/add-userrole`,
      state: { data: row },
    });
  };

  const onAddClick = () => {
    history.push(`${process.env.PUBLIC_URL}/add-userrole`);
  };

  // API--------------------------------

  const loadRoleList = () => {
    isCheckToList(UserPermissionType.userRole) && setInitScreen(true);
    fetchRoleList(
      {
        status: status?.value ?? ServerStatusType.active,
        searchText: searchText,
        sort: { field: "name" },
      },
      (data, error) => {
        setInitScreen(true);
        if (error === null) {
          setUserRoleList(data.list);
          setInitScreen(false);
        }
        setInitScreen(false);
      }
    );
  };

  return (
    <React.Fragment>
      <Head title="User Role" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>User Role</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="d-flex align-items-center">
                <span className="me-2">
                  <StatusActiveDropDown
                    value={status}
                    className="w-100px "
                    onSelect={(e) => {
                      setStatus(e);
                    }}
                  />
                </span>
                {isAllowToAccess(
                  UserPermissionType.userRole,
                  UserActionType.add
                ) && (
                  <span>
                    <ActionButton
                      title="Add"
                      size="md"
                      // onClick={() => setModal(true)}
                      onClick={onAddClick}
                    />
                  </span>
                )}
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <PreviewCard>
            <ReactDataTable
              data={userRoleList}
              isAllowed={isCheckToList(UserPermissionType.userRole)}
              columns={userRoleTableData(onEdit)}
            />
          </PreviewCard>
        </Block>
      </Content>
      <LoadingComponent isLoading={initScreen} />
    </React.Fragment>
  );
};

export default UserRoleList;
