import React, { useEffect, useState } from "react";
import { RSelect } from "../Component";
import { rolesDropDownData } from "./DropDownData";
import { ServerStatusType } from "../../utils";

export const RoleDropDown = ({
  value,
  isMulti = false,
  isShowAll = false,
  onSelect,
  userType,
  selectedId,
  placeholder,
  ...props
}) => {
  const [list, setList] = useState();
  useEffect(() => {
    loadLocationList();
  }, []);

  useEffect(() => {
    if (userType) {
      loadLocationList();
    }
  }, [userType]);

  const loadLocationList = () => {
    let params = {
      status: ServerStatusType.active,
      type: userType ?? null,
    };
    rolesDropDownData(params, (data, error) => {
      if (error === null) {
        if (isShowAll === false) {
          setList(data);
        } else {
          let all = {
            id: 0,
            label: "All",
            value: "All",
          };
          setList([all, ...data]);
        }
      }
    });
  };

  useEffect(() => {
    if (selectedId) {
      const selectedOption = list?.find((option) => option.id === selectedId);
      if (selectedOption) {
        onSelect(selectedOption);
      }
    }
  }, [list]);

  return (
    <RSelect
      options={list ?? []}
      isMulti={isMulti}
      placeholder={placeholder}
      value={value ?? (isShowAll ? (list ? list[0] : null) : null)}
      onChange={(e) => {
        if (isShowAll === false) {
          onSelect(e);
        } else {
          e.id === 0 ? onSelect(undefined) : onSelect(e);
        }
      }}
      {...props}
    />
  );
};
