import React, { useState } from 'react';

export const NavigationTabs = ({ tabs, activeTab, setActiveTab }) => {

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div >
      <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
        {tabs.map((tab, index) => (
          <li className="nav-item" key={index}>
            <button
              className={`nav-link ${activeTab === index ? 'active' : ''} ${tab.disabled ? 'disabled' : ''}`}
              onClick={() => handleTabClick(index)}
            >
              <span>{tab.text}</span>
            </button>
          </li>
        ))}
      </ul>
      <div>
        {tabs[activeTab].content}
      </div>
    </div>
  );
};
