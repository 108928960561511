import React from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { Icon } from "../Component";

const ExportButton = ({ title, options, className, onChange }) => {
  return (
    <UncontrolledDropdown direction="down" className={`me-1 ${className}`}>
      <DropdownToggle
        outline
        color="light"
        className="btn-action bg-white d-none d-sm-inline-flex  fw-normal"
      >
        <span>
          <Icon className={""} name="download"></Icon>
        </span>
      </DropdownToggle>
      <DropdownMenu>
        <ul className="link-list-opt">
          {options?.length > 0 &&
            options?.map((oItem, oIndex) => (
              <li key={"option" + oIndex}>
                <DropdownItem
                  tag="a"
                  href="#links"
                  onClick={(ev) => {
                    ev.preventDefault();
                    onChange(oItem?.value);
                  }}
                >
                  <span>{oItem?.label}</span>
                </DropdownItem>
              </li>
            ))}
        </ul>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default ExportButton;
