import React, { useState } from "react";
import {
  ActionButton,
  Block,
  BlockContent,
  BlockHead,
  BlockTitle,
  FormInput,
  FormInputType,
  PreviewCard,
} from "../../components/Component";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { AppText, NewPasswordType, showErrorToast, showSuccessToast } from "../../utils";
import { changePassword, recoverNewPassword } from "./AuthData";
import { ConfirmationType } from "./Confirmation";
import { Storage } from "../../services/storage/storage";
import { useEffect } from "react";
import { Form } from "reactstrap";

const CreatePassword = () => {
  const { handleSubmit } = useForm();
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [isDisble, setIsDisble] = useState(true);
  // const [newPassState, setNewPassState] = useState(false);
  const [authData, setAuthData] = useState({});

  useEffect(() => {
    if ((authData?.newPassword?.length ?? 0) === 0 || authData?.isPasswordError === true) {
      setIsDisble(true);
    } else {
      setIsDisble(false);
    }
  }, [authData]);

  const onSubmit = () => {
    var params = {
      token: location?.state?.token,
      newPassword: authData?.newPassword,
    };
    setLoading(true);
    if (location?.state?.type === NewPasswordType?.changePassword) {
      changePassword(params, (data, error) => {
        if (error === null) {
          setLoading(false);
          showSuccessToast("Account password has been changed successfully!");
          history.push({
            pathname: `${process.env.PUBLIC_URL}/`,
          });
        } else {
          setLoading(false);
          showErrorToast(error.message);
        }
      });
    } else {
      recoverNewPassword(params, (data, error) => {
        if (error === null) {
          setLoading(false);
          showSuccessToast("Account password has been changed successfully!");
          history.push({
            pathname: `${process.env.PUBLIC_URL}/confirmation`,
            state: {
              type: ConfirmationType.createPassword,
              token: data.token,
            },
          });
        } else {
          setLoading(false);
          Storage.clearAll();
          history.push(`${process.env.PUBLIC_URL}/auth-login`);
        }
      });
    }
  };

  return (
    <React.Fragment>
      <Head title="Reset your password" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center mx-4">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <img className="logo-light logo-img logo-img-lg min-h-120" src={Logo} alt="logo" />
              <img className="logo-dark logo-img logo-img-lg min-h-120" src={LogoDark} alt="logo-dark" />
            </Link>
          </div>
          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
              <BlockContent className={"mb-2"}>
                <BlockTitle tag="h5">{AppText.resetYourPassword}</BlockTitle>
              </BlockContent>
            <Form className="form-validation" onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <div className="form-control-wrap">
                  <FormInput
                    label={"New password"}
                    type={FormInputType.password}
                    value={authData?.newPassword}
                    onError={(isPassword) => {
                      setAuthData((prev) => ({
                        ...prev,
                        isPasswordError: isPassword,
                      }));
                    }}
                    onTextChange={(text) => {
                      setAuthData((prev) => ({
                        ...prev,
                        newPassword: text,
                      }));
                    }}
                  />
                </div>
              </div>
              <ActionButton
                title="Continue"
                size="lg"
                loading={loading}
                disabled={isDisble}
              />
            </Form>
            {location?.state?.type === NewPasswordType?.forgotPassword && (
              <div className="form-note-s2 text-center pt-4">
                <Link to={`${process.env.PUBLIC_URL}/auth-login`}>
                  <strong>Sign in</strong>
                </Link>
              </div>
            )}
          </PreviewCard>
        </Block>
        <div className="gap-100px" />
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default CreatePassword;
