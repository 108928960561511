import React, { useState } from "react";
import { Card, Modal, ModalBody, ModalHeader } from "reactstrap";
import {
  ActionButton,
  Icon,
  LoadingComponent,
  PaginationComponent,
} from "../../../components/Component";
import { CURRENCY_CODE, PER_PAGE_ITEMS, numberFormatter } from "../../../utils";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchAuditTrail } from "./AuditTrailData";
import moment from "moment";

const AuditTrailDatatable = ({
  title,
  transactionId,
  startDate,
  endDate,
  onRowClicked = () => {},
}) => {
  const location = useSelector((state) => state?.auth?.currentLocation);
  const [initScreen, setInitScreen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [viewModal, setViewModal] = useState(false);
  const [modalData, setModalData] = useState();
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    loadAuditTrail();
  }, [currentPage]);

  useEffect(() => {
    if (currentPage === 1) {
      loadAuditTrail();
    } else {
      setCurrentPage(1);
    }
  }, [location]);

  useEffect(() => {
    if (!transactionId) {
      if (currentPage === 1) {
        loadAuditTrail();
      } else {
        setCurrentPage(1);
      }
    }
  }, [startDate, endDate]);

  // ************ API ****************** //
  const loadAuditTrail = () => {
    if (!location) {
      return;
    } else if (!transactionId && (!location || !startDate || !endDate)) {
      return;
    }
    !transactionId && setInitScreen(true);
    let params = {
      pageInfo: {
        pageIndex: currentPage,
        itemsPerPage: PER_PAGE_ITEMS,
      },
      locationId: location ? location.id : undefined,
      transId: transactionId ?? null,
      startDate: transactionId
        ? undefined
        : moment(startDate).format("YYYY-MM-DD"),
      endDate: transactionId ? undefined : moment(endDate).format("YYYY-MM-DD"),
    };
    fetchAuditTrail(params, (data, error) => {
      if (error === null) {
        setData(data?.list);
        if (currentPage === 1) {
          setTotalItems(data?.pageInfo?.totalItems ?? 0);
        }
      }
      setInitScreen(false);
    });
  };

  // ************ Functions ****************** //
  const handleModal = (item) => {
    setModalData(item);
    setViewModal(true);
  };
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <>
      <Card className="card-bordered mt-2">
        {title && (
          <>
            <div className="px-2 py-1 fs-6">
              <p>{title}</p>
            </div>
            <div className="border-card-end"></div>
          </>
        )}
        {data?.length > 0 ? (
          <div className="px-2">
            <div className="align-items-center">
              <div className="row">
                <div className="col-sm-2 col-12 audit-trail-seprater py-1">
                  <p className="mb-0">Date & Time</p>
                </div>
                <div className="col-sm-2 col-12 audit-trail-seprater py-1">
                  <p className="mb-0">From Account</p>
                </div>
                <div className="col-sm-2 col-12 audit-trail-seprater py-1">
                  <p className="mb-0">Transaction Date</p>
                </div>
                <div className="col-sm-6 col-12 py-1">
                  <p className="mb-0 w-80">Description</p>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="border-card-end"></div>
        <div className="px-2">
          <div className="align-items-center">
            {data?.length > 0 ? (
              data?.map((item, index) => (
                <>
                  <div
                    className="row align-center cursor-pointer"
                    key={index}
                    onClick={() => {
                      if (item?.transAction !== "TRANSACTION_REMOVED") {
                        onRowClicked(item);
                      }
                    }}
                  >
                    <div className="col-sm-2 col-12 audit-trail-seprater py-2">
                      <p className="mb-0">
                        {moment(item?.createdAt).format("DD-MM-YYYY")} at {""}
                        {moment(item?.createdAt).format("HH:mm")}
                      </p>
                    </div>
                    <div className="col-sm-2 col-12 audit-trail-seprater py-2 ">
                      <p className="mb-0">{item?.fromAccountType ?? "-"}</p>
                    </div>
                    <div className="col-sm-2 col-12 audit-trail-seprater py-2">
                      <p className="mb-0">
                        {moment(item?.transData?.date?.new).format(
                          "DD-MM-YYYY"
                        )}
                      </p>
                    </div>
                    <div className="col-sm-6 col-12 align-center py-2 d-flex justify-content-between">
                      <div>
                        <span>
                          {item?.transAction === "TRANSACTION_CREATE"
                            ? "Added"
                            : item?.transAction === "TRANSACTION_MODIFY"
                            ? "Modified"
                            : item?.transAction === "TRANSACTION_REMOVED"
                            ? "Removed"
                            : null}
                        </span>
                        <span className="mx-1">{` by `}</span>
                        <span>
                          {item?.userData?.firstName +
                            " " +
                            item?.userData?.lastName}
                        </span>
                      </div>
                      {item?.transAction === "TRANSACTION_MODIFY" && (
                        <div>
                          <span
                            className="px-2 cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleModal(item);
                            }}
                          >
                            <Icon
                              name="eye"
                              style={{ fontSize: "17px" }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleModal(item);
                              }}
                            ></Icon>
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="border-card-end"></div>
                  </div>
                </>
              ))
            ) : (
              <div className="">
                <div className="py-2">
                  <p className="text-center">There are no records found</p>
                </div>
              </div>
            )}
          </div>
        </div>
        <LoadingComponent isLoading={initScreen} />
      </Card>
      <div className="mt-2">
        <PaginationComponent
          itemPerPage={PER_PAGE_ITEMS}
          totalItems={totalItems}
          paginate={paginate}
          currentPage={currentPage}
        />
      </div>
      <AuditTrailViewModal
        modalData={modalData}
        visible={viewModal}
        onClosed={() => {
          setViewModal(false);
        }}
      />
    </>
  );
};

export default AuditTrailDatatable;

export const AuditTrailViewModal = ({ visible, modalData, onClosed }) => {
  const close = () => {
    onClosed();
  };
  function getTitle(key) {
    switch (key) {
      case "date":
        return "Date";
      case "amount":
        return "Amount";
      case "cheque":
        return "Cheque";
      case "receiptNo":
        return "ReceiptNo";
      case "description":
        return "Description";
      case "lodgementNo":
        return "LodgementNo";
      case "clearanceDate":
        return "ClearanceDate";
      case "clearanceStatus":
        return "ClearanceStatus";
      default:
        break;
    }
  }

  let keys = Object.keys(modalData?.transData ?? {});
  return (
    <Modal size={"lg"} isOpen={visible} toggle={() => close()}>
      <ModalHeader toggle={() => close()}>
        {moment(modalData?.transData?.date?.new).format("DD-MM-YYYY")}
      </ModalHeader>
      <ModalBody className="mb-1">
        <div className="m-n1">
          <span className="fs-6">From</span>
          <table className="table table-bordered mb-0">
            <thead>
              <tr>
                <th scope="col">Type</th>
                <th scope="col">Old</th>
                <th scope="col">New</th>
              </tr>
            </thead>
            <tbody>
              {keys
                .filter((item) => item !== "items")
                .map((key, index) => {
                  return (
                    <>
                      {(Object.keys(modalData?.transData?.[key])?.length ?? 0) >
                      0 ? (
                        <>
                          {key === "account" ? (
                            <tr>
                              <td scope="row" key={index}>
                                Account
                              </td>
                              <td>
                                {/* Display old account data */}
                                {modalData?.transData?.account?.removed
                                  ?.accountName ?? "-"}
                              </td>
                              <td>
                                {/* Display new account data */}
                                {modalData?.transData?.account?.added
                                  ?.accountName ?? "-"}
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td scope="row">{getTitle(key)}</td>
                              <td>
                                {/* Display old date */}
                                {modalData?.transData?.[key]?.old && (
                                  <>
                                    {key === "date"
                                      ? moment(
                                          modalData?.transData?.[key]?.old
                                        ).format("DD-MM-YYYY")
                                      : key === "clearanceDate"
                                      ? moment(
                                          modalData?.transData?.[key]?.old
                                        ).format("DD-MM-YYYY")
                                      : key === "amount"
                                      ? numberFormatter(
                                          CURRENCY_CODE,
                                          "" + modalData?.transData?.[key]?.old
                                        )
                                      : modalData?.transData?.[key]?.old}
                                  </>
                                )}
                              </td>
                              <td>
                                {/* Display new date */}
                                {modalData?.transData?.[key]?.new && (
                                  <>
                                    {key === "date"
                                      ? moment(
                                          modalData?.transData?.[key]?.new
                                        ).format("DD-MM-YYYY")
                                      : key === "clearanceDate"
                                      ? moment(
                                          modalData?.transData?.[key]?.new
                                        ).format("DD-MM-YYYY")
                                      : key === "amount"
                                      ? numberFormatter(
                                          CURRENCY_CODE,
                                          "" + modalData?.transData?.[key]?.new
                                        )
                                      : modalData?.transData?.[key]?.new}
                                  </>
                                )}
                              </td>
                            </tr>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  );
                })}
            </tbody>
          </table>
        </div>
        {(modalData?.transData?.items?.modified?.length ?? 0) > 0 ? (
          <div className="m-n1 pt-4">
            <span className="fs-6">To</span>
            <table className="table table-bordered mb-0">
              <thead>
                <tr>
                  <th scope="col">Type</th>
                  <th scope="col">Old</th>
                  <th scope="col">New</th>
                </tr>
              </thead>
              <tbody>
                {modalData?.transData?.items?.modified.map(
                  (modifiedItem, index) => {
                    let modifiedKeys = modifiedItem.new
                      ? Object.keys(modifiedItem.new).filter(
                          (itemkey) =>
                            itemkey !== "accountId" && itemkey !== "accountName"
                        )
                      : [];
                    return (
                      <React.Fragment key={index}>
                        {modifiedKeys.map((modifiedKey, innerIndex) => {
                          return (
                            <tr>
                              <td scope="row" key={innerIndex}>
                                {getTitle(modifiedKey)}
                              </td>
                              <td>
                                {/* Display old account data */}
                                {modifiedKey === "amount"
                                  ? numberFormatter(
                                      CURRENCY_CODE,
                                      "" + modifiedItem?.old?.[modifiedKey]
                                    )
                                  : modifiedItem?.old?.[modifiedKey]}
                              </td>
                              <td>
                                {/* Display new account data */}
                                {modifiedKey === "amount"
                                  ? numberFormatter(
                                      CURRENCY_CODE,
                                      "" + modifiedItem?.new?.[modifiedKey]
                                    )
                                  : modifiedItem?.new?.[modifiedKey]}
                              </td>
                            </tr>
                          );
                        })}
                      </React.Fragment>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>
        ) : (
          <></>
        )}
        {(modalData?.transData?.items?.added?.length ?? 0) > 0 ? (
          <div className="m-n1 pt-4">
            <span className="fs-6">To Add</span>
            <table className="table table-bordered mb-0">
              <thead>
                <tr>
                  <th scope="col">Account Name</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Description</th>
                </tr>
              </thead>
              <tbody>
                {modalData?.transData?.items?.added?.map((addedItem, index) => {
                  return (
                    <>
                      <tr>
                        <td scope="row" key={index}>
                          {addedItem?.accountName}
                        </td>
                        <td>{addedItem?.amount}</td>
                        <td>{addedItem?.description}</td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <></>
        )}
        {(modalData?.transData?.items?.removed?.length ?? 0) > 0 ? (
          <div className="m-n1 pt-4">
            <span className="fs-6">To Remove</span>
            <table className="table table-bordered mb-0">
              <thead>
                <tr>
                  <th scope="col">Account Name</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Description</th>
                </tr>
              </thead>
              <tbody>
                {modalData?.transData?.items?.removed?.map(
                  (removedItem, index) => {
                    return (
                      <>
                        <tr>
                          <td scope="row" key={index}>
                            {removedItem?.accountName}
                          </td>
                          <td>{removedItem?.amount}</td>
                          <td>{removedItem?.description}</td>
                        </tr>
                      </>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>
        ) : (
          <></>
        )}
      </ModalBody>
    </Modal>
  );
};
