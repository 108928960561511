import React, { useEffect, useState } from "react";
import {
  Block,
  LoadingComponent,
  PreviewCard,
  ReactDataTable,
} from "../../../components/Component";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { ChequeStatusType } from "../../../utils";
import { useLocation } from "react-router-dom";
import { fetchPendingRecoList, pendingRecoDataTable } from "./RecoData";
import { PermissionType } from "../../../components/dropdowns/PermissionDropdown";

export const TranscationTypes = {
  cash: "CASH",
  bank: "BANK",
  resident: "RESIDENT",
  other: "OTHER",
  dashboard: "DASHBOARD",
};

const PendingReco = ({ userType, locationId }) => {
  const history = useHistory();
  const [initScreen, setInitScreen] = useState(false);
  const [data, setData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const location = useLocation();

  useEffect(() => {
    loadPendingRecoList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, locationId]);

  // API------------------------------------------------------------>
  const loadPendingRecoList = () => {
    setInitScreen(true);
    fetchPendingRecoList(locationId, (data, error) => {
      setInitScreen(true);
      if (error === null) {
        setData(data?.list);
        if (currentPage === 1) {
          setTotalItems(data?.pageInfo?.totalItems ?? 0);
        }
      }
      setInitScreen(false);
    });
  };
  const onMoveReco = (type, date) => {
    history.push({
      pathname: `${process.env.PUBLIC_URL}/reco`,
      state: {
        recoType: type,
        recoDate: date,
        isRecoView: true,
      },
    });
  };

  return (
    <>
      {data?.length > 0 && (
        <Block>
          {(userType === PermissionType.admin ||
            userType === PermissionType.manager) && (
            <PreviewCard
              bodyClass={"mt-n4"}
              // className={"mt-2 w-xxl-50 w-100"}
              className={`mt-2 ${
                userType === PermissionType.admin ||
                userType === PermissionType.manager
                  ? ""
                  : ""
              }`}
            >
              <div className="mt-3 d-flex justify-content-between align-items-center">
                <p className="fs-5 mb-0">Pending Reco</p>
              </div>
              <div className="mt-n3">
                {locationId && (
                  <div className="mt-4 table-scroll-body">
                    <ReactDataTable
                      data={data}
                      // isAllowed={isCheckToList(UserPermissionType.transaction)}
                      columns={pendingRecoDataTable(
                        userType,
                        onMoveReco,
                        location.pathname === "/"
                      )}
                      // pagination={location.pathname === "/transaction"}
                      totalItemCount={totalItems}
                      // onPageChanged={(page, count) => {
                      //   setCurrentPage(page);
                      // }}
                    />
                  </div>
                )}
              </div>
            </PreviewCard>
          )}
        </Block>
      )}

      <LoadingComponent isLoading={initScreen} />
    </>
  );
};

export default PendingReco;
