import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Spinner } from "reactstrap";
import PageContainer from "../../layout/page-container/PageContainer";
import { Storage } from "../../services/storage/storage";
import { verifyResetPasswordToken } from "./AuthData";
import { NewPasswordType } from "../../utils";

const ResetPassword = (props) => {
  const [loading, setLoading] = useState();
  const history = useHistory();

  useEffect(() => {
    let path = props.location.search.replace("?email=", "");
    let list = path.split("&tkn=");
    if (list.length === 2) {
      verifyResetPassword(list[0], list[1]);
    } else {
      moveToLogin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function moveToLogin() {
    Storage.clearAll();
    history.push(`${process.env.PUBLIC_URL}/auth-login`);
  }

  function verifyResetPassword(email, token) {
    setLoading(true);
    let params = { email: email, token: token };
    verifyResetPasswordToken(params, (data, error) => {
      setLoading(false);
      if (error === null) {
        history.push({
          pathname: `${process.env.PUBLIC_URL}/auth-create-password`,
          state: { token: data.recoveryToken, type: NewPasswordType.forgotPassword },
        });
      } else {
        moveToLogin();
      }
    });
  }
  return (
    <PageContainer>
      {loading ? (
        <div className="spinner">
          <Spinner className="spinner-border-md" color="primary" />
        </div>
      ) : (
        <></>
      )}
    </PageContainer>
  );
};

export default ResetPassword;
