import React from "react";
import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from "reactstrap";
import { ActionButton, CancelButton, FormDatePicker } from "../Component";
import { fetchChequeStatusUpdate } from "../../pages/tabs/Transaction/TransactionData";
import { ChequeStatusType } from "../../utils";
import { useState } from "react";
import moment from "moment";

export const DateModal = ({
  selectedItem,
  isOpen,
  selected,
  toggle,
  handleSave,
  rowId,
  ...props
}) => {
  const [selectedModalDate, setSelectedModalDate] = useState(new Date());
  const [loading, setLoading] = useState(false);

  const handleModalDateChange = (date) => {
    setSelectedModalDate(date);
  };
  const onChangeChequeStatus = () => {
    setLoading(true);
    let params = {
      transItemId: rowId ? rowId : selectedItem?.id,
      status: ChequeStatusType.clear,
      clearanceDate: moment(selectedModalDate).format("YYYY-MM-DD"),
    };
    fetchChequeStatusUpdate(params, (response, error) => {
      setLoading(false);
      if (error === null) {
        handleSave();
      }
      toggle();
    });
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Select Clear Date</ModalHeader>
      <ModalBody>
        <FormDatePicker
          selected={selectedModalDate}
          onChange={handleModalDateChange}
        />
      </ModalBody>
      <ModalFooter>
        <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
          <li>
            <CancelButton onCancel={toggle} />
          </li>
          <li>
            <ActionButton
              title={"Save"}
              size="md"
              loading={loading}
              onClick={onChangeChequeStatus}
            />
          </li>
        </ul>
      </ModalFooter>
    </Modal>
  );
};
