import React from "react";
import { apiService } from "../../../services/api";
import { APIServicePath } from "../../../services/api/api-service-impl";

// ************** API ********************* //
export function fetchAuditTrail(params, callback) {
  apiService
    .postCall(APIServicePath.auditTrail, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
