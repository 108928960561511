import { DropdownToggle, UncontrolledDropdown } from "reactstrap";
import {
  BalanceBadge,
  StatusBadge,
  TableTitle,
  UserAvatar,
} from "../../../components/Component";
import { ColorsArray, File_TYPE, ServerStatusType } from "../../../utils/Utils";
import { CURRENCY_CODE, findUpper } from "../../../utils";
import {
  convertDropValue,
  numberFormatter,
  toCapitalize,
} from "../../../utils/Functions";
import Icon from "../Transaction/Icon";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { APIServicePath } from "../../../services/api/api-service-impl";
import { apiService } from "../../../services/api";
import { toPageInfo } from "../../_common/DataConverter";

export const AccountTableData = (onEdit, onRemove) => {
  return [
    {
      name: <TableTitle title={"Name"} />,
      selector: (row) => (
        <Link
          to={{
            pathname: `${process.env.PUBLIC_URL}/transaction/${
              row.id + "-" + row?.accountName?.toLowerCase()
            }`,
            state: { row },
          }}
        >
          <label className="user-info text-dark">
            <span>{row?.accountName ?? ""}</span>
          </label>
        </Link>
      ),
    },
    {
      name: <TableTitle title={"Balance"} />,
      cell: (row) => (
        <BalanceBadge
          balance={
            row?.balance
              ? numberFormatter(CURRENCY_CODE, row.balance + "")
              : CURRENCY_CODE + "0"
          }
          isLow={false}
        />
      ),
    },
  ];
};

//***************************API's*********************************/

export function listAccountAPI(params, callback) {
  apiService
    .postCall(APIServicePath.accountList, params)
    .then((data) => {
      let list = data?.list ? toAccountList(data?.list) : null;
      let pageInfo = data?.pageInfo ? toPageInfo(data?.pageInfo) : null;
      let result = { list, pageInfo };
      callback(result, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function fetchAccountExport(fileType, params, callback) {
  apiService
    .postCall(
      fileType === File_TYPE.xlxs
        ? APIServicePath.accountExportXlsx
        : APIServicePath.accountExportPdf,
      params
    )
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
// accountExportPdf;

//***************************Converters*********************************/

function toAccountList(data) {
  let list = [];
  data?.forEach((element) => {
    list.push(toAccount(element));
  });
  return list;
}

function toAccount(data) {
  return {
    id: data.accountId,
    accountName: data?.accountName ?? "",
    accountType: data?.accountType ?? "",
    balance: data?.balance ?? 0,
  };
}
