import React, { useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";

import {
  Block,
  BlockDes,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewCard,
  ReactDataTable,
  Row,
  Col,
} from "../../../components/Component";
import {
  ResidentTableData,
  fetchLocationDetail,
  fetchResidentList,
} from "../Resident/ResidentData";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  CURRENCY_CODE,
  PER_PAGE_ITEMS,
  ServerStatusType,
  numberFormatter,
} from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import { setRecoSetting } from "../../../store/authSlice";
import DataCard from "../../../components/partials/datacard/DataCard";
import TranscationType, {
  TranscationTypes,
} from "../Transaction/TranscationType";
import PendingReco from "../Reco/PendingReco";
import { PermissionType } from "../../../components/dropdowns/PermissionDropdown";

const Dashboard = ({ match }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const sessionUser = useSelector((state) => state?.auth?.sessionUser);
  const userType = sessionUser?.userType;
  const location = useSelector((state) => state?.auth?.currentLocation);
  const recoSetting = useSelector((state) => state?.auth?.comRecoSettings);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [residentList, setResidentList] = useState();
  const [locationDetails, setLocationDetails] = useState();

  useEffect(() => {
    if (recoSetting === true) {
      loadResidentList();
      dispatch(setRecoSetting(false));
    }
  }, [recoSetting]);

  useEffect(() => {
    if (location) {
      loadLocationDetail();
      loadResidentList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // API-------------------------------------------------------------->

  const loadResidentList = () => {
    if (!location) return;
    let params = {
      pageInfo: {
        pageIndex: currentPage,
        itemsPerPage: PER_PAGE_ITEMS,
      },
      status: ServerStatusType.active,
      locationId: location ? location.id : null,
      isLowBalance: true,
      date: moment().format("YYYY-MM-DD"),
    };
    fetchResidentList(params, (data, error) => {
      if (error === null) {
        setResidentList(data?.list);
        if (currentPage === 1) {
          setTotalItems(data?.pageInfo?.totalItems ?? 0);
        }
      }
    });
  };

  const loadLocationDetail = () => {
    if (!location) return;
    let params = {
      locationId: location?.id ?? sessionUser?.location?.id,
    };
    fetchLocationDetail(params, (data, error) => {
      if (error === null) {
        setLocationDetails(data);
      }
    });
  };

  let cash =
    locationDetails?.accounts?.find(
      (item) => item?.type === TranscationTypes.cash
    )?.balance ?? 0;

  let bank =
    locationDetails?.accounts?.find(
      (item) => item?.type === TranscationTypes.bank
    )?.balance ?? 0;

  let totalResidentBalance = locationDetails?.totalResidentBalance ?? 0;

  let totalActiveResidentBalance =
    locationDetails?.totalActiveResidentBalance ?? 0;

  let totalInactiveResidentBalance =
    locationDetails?.totalInactiveResidentBalance ?? 0;

  let totalActiveBeds = locationDetails?.totalActiveBeds ?? 0;
  let totalBeds = locationDetails?.totalBeds ?? 0;

  return (
    <React.Fragment>
      <Head title="Dashboard" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Dashboard</BlockTitle>
              <BlockDes className="text-soft">
                <p>Welcome to {process.env.REACT_APP_APP_NAME}</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Row className={"g-gs mb-3"}>
          <Col size={"12"} md={"4"} sm="6">
            <DataCard
              title={"Residents Balance"}
              amount={numberFormatter(CURRENCY_CODE, "" + totalResidentBalance)}
              backgroundcolor={"primary"}
            />
          </Col>
          <Col size={"12"} md={"4"} sm="6">
            <DataCard
              title={"Bank Balance"}
              amount={numberFormatter(CURRENCY_CODE, "" + bank)}
              backgroundcolor={"dark"}
            />
          </Col>
          <Col size={"12"} md={"4"} sm="6">
            <DataCard
              title={"Cash Balance"}
              amount={numberFormatter(CURRENCY_CODE, "" + cash)}
              backgroundcolor={"info"}
            />
          </Col>
          <Col size={"12"} md={"4"} sm="6">
            <DataCard
              title={"Active Resident Balance"}
              amount={numberFormatter(
                CURRENCY_CODE,
                "" + totalActiveResidentBalance
              )}
              backgroundcolor={"success"}
            />
          </Col>
          <Col size={"12"} md={"4"} sm="6">
            <DataCard
              title={"Inactive Resident Balance"}
              amount={numberFormatter(
                CURRENCY_CODE,
                "" + totalInactiveResidentBalance
              )}
              backgroundcolor={"danger"}
            />
          </Col>
          <Col size={"12"} md={"4"} sm="6">
            <DataCard
              title={"Beds Occupied"}
              amount={totalActiveBeds + "/" + totalBeds}
              backgroundcolor={"warning"}
            />
          </Col>
        </Row>

        {location && (
          <PendingReco
            locationId={location?.id}
            startDate={moment().subtract(1, "year").toDate()}
            endDate={new Date()}
            userType={userType}
            // type={TranscationTypes.dashboard}
          />
        )}

        <Block>
          <PreviewCard>
            <p className="fs-5">Low Balance Resident</p>
            <ReactDataTable
              data={residentList}
              columns={ResidentTableData(true)}
              expandableRows
              totalItemCount={totalItems}
              pagination
              onPageChanged={(page, count) => {
                setCurrentPage(page);
              }}
            />
          </PreviewCard>
        </Block>

        <Block className={"d-xxl-flex"}>
          <PreviewCard
            bodyClass={"mt-n4"}
            // className={"mt-2 w-xxl-50 w-100"}
            className={`mt-2 ${
              userType === PermissionType.admin ||
              userType === PermissionType.manager
                ? "w-xxl-50 w-100"
                : "w-100"
            }`}
          >
            <div className="mt-3 d-flex justify-content-between align-items-center">
              <p className="fs-5 mb-0">Recent Transactions</p>
              <p
                className="fs-7"
                onClick={() => history.push("/transaction")}
                style={{ cursor: "pointer" }}
              >
                View All
              </p>
            </div>
            <div className="mt-n3">
              {location && (
                <TranscationType
                  locationId={location?.id}
                  startDate={moment().subtract(1, "year").toDate()}
                  endDate={new Date()}
                  type={TranscationTypes.dashboard}
                />
              )}
            </div>
          </PreviewCard>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default Dashboard;
