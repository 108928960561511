import React, { useEffect, useState } from "react";
import PrivateRoute from "./route/PrivateRoute";
import Layout from "./layout/Index";

import Error404Classic from "./pages/error/404-classic";
import Error404Modern from "./pages/error/404-modern";
import Error504Modern from "./pages/error/504-modern";
import Error504Classic from "./pages/error/504-classic";

import Faq from "./pages/others/Faq";
import Terms from "./pages/others/Terms";

import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import ForgotPassword from "./pages/auth/ForgotPassword";

import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import { AuthPaths, RedirectAs404 } from "./utils";
import CreatePassword from "./pages/auth/CreatePassword";
import ConfirmationPage from "./pages/auth/Confirmation";
import ResetPassword from "./pages/auth/ResetPassword";
import { Storage } from "./services/storage/storage";
import { useDispatch } from "react-redux";
import { loadCureentUser } from "./store/authSlice";
import { useSelector } from "react-redux";
import { LoadingComponent } from "./components/Component";

const App = (props) => {
  const data = useSelector((state) => state.auth);
  let user = Storage.getLoggedInUser();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      setLoading(true);
      dispatch(loadCureentUser());
      // dispatch(loadFetchMasterList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data?.loading === false) {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
    {!loading ? (
      <Switch>
        {user && AuthPaths.some((item) => item === props.location.pathname) && (
          <Redirect to={`${process.env.PUBLIC_URL}`} />
        )}

        {user === undefined && !AuthPaths.some((item) => item === props.location.pathname) && (
          <Redirect to={`${process.env.PUBLIC_URL}/auth-login`} />
        )}

        {/* Auth Pages */}
        <Route exact path={`${process.env.PUBLIC_URL}/auth-reset`} component={ForgotPassword}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/auth-register`} component={Register}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/auth-login`} component={Login}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/auth-create-password`} component={CreatePassword}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/confirmation`} component={ConfirmationPage}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/reset-password`} component={ResetPassword}></Route>

        {/* Helper pages */}
        <Route exact path={`${process.env.PUBLIC_URL}/auths/terms`} component={Terms}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/auths/faq`} component={Faq}></Route>

        {/*Error Pages*/}
        <Route exact path={`${process.env.PUBLIC_URL}/errors/404-classic`} component={Error404Classic}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/errors/504-modern`} component={Error504Modern}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/errors/404-modern`} component={Error404Modern}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/errors/504-classic`} component={Error504Classic}></Route>

        {/*Main Routes*/}
        <PrivateRoute exact path="" component={Layout}></PrivateRoute>
        <Route component={RedirectAs404}></Route>
      </Switch>
    ) : (
      <></>
    )}
      <LoadingComponent isLoading={loading} isFade={false} />
    </>
  );
};
export default withRouter(App);
