import React from "react";
import ReactDatePicker from "react-datepicker";
import FormDatePicker from "./FormDatePicker";

export const DateRangePicker = ({
  start,
  end,
  onChangeStart,
  onChangeEnd,
  ...props
}) => {
  return (
    <div className="w-100">
      <div className="input-daterange date-picker-range input-group date-range-picker">
        {/* <Icon className="input-group-text" name="calender-date" /> */}
        <FormDatePicker
          selected={start}
          startDate={start}
          endDate={end}
          isShowLeftIcon={false}
          selectsStart
          className="form-control rounded-0 rounded-start"
          wrapperClassName="start-m"
          onChange={onChangeStart} // Update the prop name to onChange
          {...props}
        />
        <div className="input-group-addon">TO</div>
        <FormDatePicker
          selected={end}
          startDate={start}
          endDate={end}
          isShowLeftIcon={false}
          selectsEnd
          className="form-control rounded-0 rounded-end"
          onChange={onChangeEnd} // Update the prop name to onChange
          wrapperClassName="end-m"
          {...props}
        />
      </div>
    </div>
  );
};
