import React, { useState } from "react";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  PreviewCard,
} from "../../components/Component";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { useLocation, Link } from "react-router-dom";
import { generateSession, resendVerifyEmail } from "./AuthData";
import { Spinner } from "reactstrap";
import { Storage } from "../../services/storage/storage";

export const ConfirmationType = {
  forgotPassword: "forgot password",
  createPassword: "create password",
};

const ConfirmationPage = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [errorVal, setError] = useState(null);

  function resendEmail() {
    setLoading(true);
    let params = {
      email: location.state.email,
      tokenType: "RESET_PASSWORD",
    };
    resendVerifyEmail(params, (_, error) => {
      setLoading(false);
      if (error !== null) {
        setError(error.message);
      }
    });
  }

  function fetchSessionData() {
    setLoading(true);
    let params = { token: location.state.token };
    generateSession(params, (auth, error) => {
      setLoading(false);
      if (error === null) {
      } else {
        Storage.clearAll();
      }
      checkAndLoad();
    });
  }

  function checkAndLoad() {
    window.history.pushState(
      `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`,
      "auth-login",
      `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`
    );
    window.location.reload();
  }

  return (
    <React.Fragment>
      <Head title="Confirmation" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center mx-4">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <img
                className="logo-light logo-img logo-img-lg min-h-120"
                src={Logo}
                alt="logo"
              />
              <img
                className="logo-dark logo-img logo-img-lg min-h-120"
                src={LogoDark}
                alt="logo-dark"
              />
            </Link>
          </div>
          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            {location.state.type === ConfirmationType.forgotPassword ? (
              <ForgotPasswordConfirmationView onResend={() => resendEmail()} />
            ) : location.state.type === ConfirmationType.createPassword ? (
              <CreatePasswordConfirmationView
                loading={loading}
                onDashboard={() => fetchSessionData()}
              />
            ) : null}
          </PreviewCard>
        </Block>
        <div className="gap-100px" />
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default ConfirmationPage;

const ForgotPasswordConfirmationView = ({ onResend }) => {
  return (
    <React.Fragment>
        <BlockContent>
          <BlockTitle tag="h5">
            Thanks, check your email for instructions to reset your password
          </BlockTitle>
          <BlockDes>
            <br />
            <p>
              If you haven't received an email in 5 minutes, check your spam or{" "}
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                href={undefined}
                id="#cannot"
                className="text-azure"
                onClick={onResend}
                style={{ cursor: "pointer" }}
              >
                resend
              </a>
              .
            </p>
          </BlockDes>
        </BlockContent>
    </React.Fragment>
  );
};

const CreatePasswordConfirmationView = ({ loading, onDashboard }) => {
  return (
    <React.Fragment>
        <BlockContent className={"mb-2"}>
          <BlockTitle tag="h5">
            You've successfully changed your password
          </BlockTitle>
        </BlockContent>
      <BlockHeadContent>
        <Button
          color="primary"
          className="center custom-solid-btn-lg"
          onClick={onDashboard}
        >
          {loading ? <Spinner size="sm" color="dark" /> : "Continue"}
        </Button>
      </BlockHeadContent>
    </React.Fragment>
  );
};
