import React, { useState } from "react";
import { Icon } from "../../Component";
import DatePicker, { registerLocale } from "react-datepicker";
import { enGB } from "date-fns/locale";
// import YearsDropDown from "../../dropdowns/YearsDropDown";
// import MonthDropDown from "../../dropdowns/MonthDropDown";
import moment from "moment/moment";
import { MONTHS } from "../../../utils/Utils";
import YearsDropDown from "../../dropdowns/YearsDropDown";
import MonthDropDown from "../../dropdowns/MonthsDropDown";

registerLocale(enGB);

const FormDatePicker = ({
  selected,
  onChange,
  maxDate,
  minDate,
  error,
  showMonthYearPicker,
  isShowLeftIcon = true,
  ...props
}) => {
  const [year, setYear] = useState();
  const [month, setMonth] = useState();

  return (
    <div className="form-control-wrap form-date-picker">
      <div className="input-group">
        <div className="input-group-prepend w-100">
          {isShowLeftIcon && (
            <Icon className="input-group-text" name="calender-date" />
          )}
          <DatePicker
            locale={enGB}
            selected={selected}
            onChange={onChange}
            placeholderText="Select a date"
            dateFormat="dd-MM-yyyy"
            className={
              isShowLeftIcon
                ? `form-control rounded-0 rounded-end border-start-0 `
                : props?.className ?? ""
            }
            showMonthYearPicker={showMonthYearPicker}
            maxDate={maxDate}
            minDate={minDate}
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <span className="">
                  <div className="input-group">
                    <span
                      className="input-group-text px-1"
                      onClick={() => {
                        decreaseMonth();
                        setMonth();
                        setYear();
                      }}
                      disabled={prevMonthButtonDisabled}
                    >
                      <Icon className="" name="chevron-left" />
                    </span>
                    <YearsDropDown
                      value={
                        year ?? {
                          id: "",
                          value: moment(date).format("YYYY"),
                          label: moment(date).format("YYYY"),
                        }
                      }
                      placeholder={"years"}
                      className="w-100px"
                      onSelect={(e) => {
                        setYear(e);
                        changeYear(e?.value);
                      }}
                    />
                    {showMonthYearPicker ? (
                      <></>
                    ) : (
                      <MonthDropDown
                        value={month}
                        placeholder={"months"}
                        className="w-120px"
                        selectedValue={MONTHS.find(
                          (item) => item.value === moment(date).format("MMMM")
                        )}
                        onSelect={(e) => {
                          setMonth(e);
                          changeMonth(e?.id - 1);
                        }}
                      />
                    )}
                    <span
                      className="input-group-text px-1"
                      onClick={() => {
                        increaseMonth();
                        setMonth();
                        setYear();
                      }}
                      disabled={nextMonthButtonDisabled}
                    >
                      <Icon className="" name="chevron-right" />
                    </span>
                  </div>
                </span>
              </div>
            )}
            {...props}
          />
        </div>
      </div>
      {error && <span className="invalid">{error}</span>}
    </div>
  );
};

export default FormDatePicker;
