import React from "react";
import classNames from "classnames";
import Icon from "../icon/Icon";

// Aviable sizes {xs,sm,md,lg,xl}
/* Aviable theme {blue-dim, blue, azure-dim, azure, indigo-dim, indigo, purple-dim, purple, pink-dim, pink, orange-dim, orange, teal-dim, teal, 
    primary-dim, primary, secondary-dim, secondary, success-dim, success, info-dim, info, warning-dim, warning, danger-dim, danger, dark-dim, dark, gray-dim, gray, lighter, light} */

const UserAvatar = ({
  className,
  name,
  email,
  size,
  theme,
  icon,
  text,
  image,
  relations,
  imageAlt,
  ...props
}) => {
  const classes = classNames({
    "user-avatar ": true,
    [`${className}`]: className,
    [`user-avatar-${size} `]: size,
    [`bg-${theme}`]: theme,
  });

  return (
    <div className="d-flex align-items-center user-card justify-content-end nk-tb-list">
      <div className={classes}>
        {icon ? <Icon name={icon} /> : null}
        {image && <img src={image} alt={imageAlt} />}
        {text && !image && <span>{text}</span>}
        {props.children}
      </div>

      {name ? (
        <div className="user-info">
          <span className="tb-lead">{name}</span>
          {email && <span>{email}</span>}
          {relations && <span>{relations}</span>}
        </div>
      ) : null}
    </div>
  );
};

export default UserAvatar;
