import React, { useEffect, useState } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { Icon } from "../../../components/Component";
import CustomButton from "../../../components/button/CustomButton";

export const Unpresent = ({ selected, options }) => {
  return (
    <UncontrolledDropdown>
      <DropdownToggle tag="a" className="text-soft dropdown-toggle">
        <CustomButton label={selected ?? options[0].text} />
      </DropdownToggle>
      <DropdownMenu end className="link-list-opt no-bdr">
        {options.map((item, index) => {
          return (
            <React.Fragment key={index}>
              {item.hide === true ? (
                <></>
              ) : (
                <DropdownItem
                  tag="a"
                  className="align-items-center"
                  onClick={(ev) => {
                    ev.preventDefault();
                    item.action();
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <span>{item.text}</span>
                </DropdownItem>
              )}
            </React.Fragment>
          );
        })}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
