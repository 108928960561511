export const AppText = {
  copyright: `© ${new Date().getFullYear()} ${
    process.env.REACT_APP_APP_NAME
  }. All rights reserved.`,
  invalidEmail: "Invalid email address",
  invalidPhone: "Invalid phone address",
  resetYourPassword: "Reset your password",
  newpassword: "New password",
  connectError: "Please check your network connect and try again",
  maxFileSizeError: "You can upload max 100 MB file",

  // Success Message
  GradeAddedSuccessfully: "Grade have been added successfully",
  EditedSuccessfully: " have been edited successfully",
  AddedSuccessfully: " have been added successfully",

  // Error Message
};
