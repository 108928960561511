import React from "react";
// import {
//   AnnsModal,
//   Col,
//   FormInput,
//   FormInputType,
// } from "../../../../components/Component";
import { Form } from "reactstrap";
import { useState } from "react";
import { useEffect } from "react";
import {
  AnnsModal,
  Col,
  FormInput,
  FormInputType,
  Row,
} from "../../../components/Component";
import { fetchAppSetting } from "./DashboardData";
import { covertToNumberOnly, showErrorToast } from "../../../utils";
import { useDispatch } from "react-redux";
import { setRecoSetting } from "../../../store/authSlice";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const Setting = ({
  visible,
  carerId,
  item,
  onSaved,
  onClosed,
  loading,
  start_Date,
  balance,
}) => {
  const location = useLocation();
  const [isDisable, setIsDisable] = useState(false);
  const [settingData, setSettingData] = useState({
    lowBalanceAlertAmout: localStorage.getItem("lowBalanceAlertAmout") || "",
  });
  const [isSaving, setIsSaving] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    reset();
    return () => {
      setSettingData({});
      setIsDisable();
      setIsSaving();
    };
  }, []);

  useEffect(() => {
    if (item?.id && visible) {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, visible]);

  const reset = () => {};

  const onFormSubmit = () => {
    let params = {
      residentLowBalance:
        covertToNumberOnly(settingData?.lowBalanceAlertAmout).toString() ??
        null,
    };
    setIsSaving(true);
    fetchAppSetting(params, (data, error) => {
      if (error === null) {
        if (location?.pathname === `${process.env.PUBLIC_URL}/`) {
          dispatch(setRecoSetting(true));
        }
        onSaved(settingData);
        onClosed();
        // });
      } else {
        showErrorToast(error.message);
      }
      setIsSaving(false);
    });
    localStorage.setItem(
      "lowBalanceAlertAmout",
      settingData.lowBalanceAlertAmout
    );
  };

  const close = () => {
    reset();
    onClosed();
  };
  return (
    <AnnsModal
      size={"md"}
      isOpen={visible}
      className={"modal-dialog-scrollable"}
      toggle={() => close()}
      title={item ? "Settings" : "Settings"}
      onClick={() => onFormSubmit()}
      disabled={isDisable}
      loading={isSaving}
    >
      <Form className="row form-validation">
        <div className="">
          <Row className="mx-auto ">
            <Col className="col-md-6 mb-2">
              <FormInput
                label={"Low balance alert amount"}
                // required={true}
                type={FormInputType.amount}
                value={settingData?.lowBalanceAlertAmout}
                onError={(isError) => {}}
                onTextChange={(text) => {
                  setSettingData({
                    ...settingData,
                    lowBalanceAlertAmout: text,
                  });
                }}
              />
            </Col>
          </Row>
        </div>
      </Form>
    </AnnsModal>
  );
};

export default Setting;
