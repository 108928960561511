import classNames from "classnames";
import React from "react";

const Icon = ({ name, id, className, style, onClick, ...props }) => {
  const iconClass = classNames({
    [`${className}`]: className,
    icon: true,
    ni: true,
    [`ni-${name}`]: true,
  });
  return (
    <em
      className={iconClass}
      id={id}
      style={style}
      {...props}
      onClick={onClick}
    ></em>
  );
};
export default Icon;
