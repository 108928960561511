import React from "react";
import {
  AnnsModal,
  Col,
  FormCheckbox,
  FormInput,
  FormInputType,
  RadioButtonOptions,
} from "../../../../components/Component";
import { Form } from "reactstrap";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useEffect } from "react";
import { addBranchAPI, addLocationAPI } from "./LocationTableData";
import { AppText, RadioOptionTypes, showErrorToast, showSuccessToast } from "../../../../utils";

const AddLocation = ({ visible, item, onSaved, onClosed }) => {
  const [locationData, setLocationData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(true);

  useEffect(() => {
    if ((locationData?.name?.length ?? 0) === 0 || (locationData?.totalBeds?.length ?? 0) === 0) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  }, [locationData]);

  useEffect(() => {
    if (visible) {
      if (item) {
        setLocationData({ ...locationData, id: item?.id, name: item?.name, totalBeds: "" + item?.totalBeds , isActive: item?.isActive || false });
      } else {
        setLocationData({ ...locationData, name: "", isActive: true });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, item]);

  const close = () => {
    onClosed();
    setLoading(false);
    setLocationData({});
  };

  const onFormSubmit = () => {
    if (isDisable) return;
    setLoading(true);
    addLocationAPI(locationData, (_, error) => {
      if (error === null) {
        let text = "Location" + (item ? AppText.EditedSuccessfully : AppText.AddedSuccessfully);
        showSuccessToast(text);
        onSaved();
        close();
      } else {
        showErrorToast(error.message);
      }
      setLoading(false);
    });
  };

  const { errors, register, handleSubmit } = useForm();
  return (
    <>
      <AnnsModal
        size={"sm"}
        isOpen={visible}
        toggle={() => onClosed()}
        title={item ? "Edit Location" : "Add Location"}
        onClick={() => onFormSubmit()}
        disabled={isDisable}
        loading={loading}
      >
        <Form className="row gy-4 form-validation" onSubmit={handleSubmit(onFormSubmit)}>
          <Col md="12">
            <div className="form-group">
              <div className="form-control-wrap">
                <FormInput
                  label={"Location"}
                  type={FormInputType.default}
                  value={locationData?.name}
                  onTextChange={(text) => {
                    setLocationData((prev) => ({
                      ...prev,
                      name: text,
                    }));
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md="12">
            <div className="form-group">
              <div className="form-control-wrap">
                <FormInput
                  label={"Bed"}
                  type={FormInputType.number}
                  value={locationData?.totalBeds}
                  onTextChange={(text) => {
                    setLocationData((prev) => ({
                      ...prev,
                      totalBeds: text,
                    }));
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md="12">
            <div className="form-group">
              <div className="form-control-wrap">
                <RadioButtonOptions
                  name="status"
                  optionType={RadioOptionTypes.status}
                  onChange={(index) => {
                    setLocationData((prev) => ({ ...prev, isActive: index === 0 }));
                  }}
                  activeIndex={locationData.isActive ? 0 : 1}
                />
              </div>
            </div>
          </Col>
        </Form>
      </AnnsModal>
    </>
  );
};

export default AddLocation;
