import React, { useEffect, useState } from "react";
import { RSelect } from "../Component";
import { locationDropdownData } from "./DropDownData";

export const LocationSelectDropdown = ({
  value,
  onSelect,
  placeholder,
  isMulti = false,
  disabled = false,
  ...props
}) => {
  const [list, setList] = useState();

  useEffect(() => {
    !disabled && loadLocationList();
  }, []);

  const loadLocationList = () => {
    locationDropdownData((data, error) => {
      if (error === null) {
        setList(data);
      }
    });
  };

  //   useEffect(() => {
  //     if (selectedId) {
  //       const selectedOption = list.find((option) => option.id === selectedId);
  //       if (selectedOption) {
  //         // onSelect(selectedOption);
  //         dispatch(setCurrentLocation(selectedOption));
  //       }
  //     }
  //   }, [list]);

  return (
    <RSelect
      options={list ?? []}
      placeholder={placeholder}
      isMulti={isMulti}
      isDisabled={disabled}
      value={value ?? null}
      onChange={(e) => {
        onSelect(e);
      }}
      {...props}
    />
  );
};
