import { UserAccountType } from "../../utils/Utils";
import { Storage } from "../storage/storage";

export class APIDataConverter {
  toLegalDocs(legalDocsResponse) {
    return {
      termsAndConditions: legalDocsResponse?.termsAndConditions ?? "",
      privacyPolicy: legalDocsResponse?.privacyPolicy ?? "",
    };
  }

  toAvatar(response) {
    let avatar = {};
    avatar.thumb = response.thumbnailUrl;
    avatar.url = response.imageUrl ?? "";
    avatar.mimetype = response.mimetype ?? "";
    return avatar;
  }

  toPageInfo(pageInfo) {
    let isNextPageExist = pageInfo.currentPage < pageInfo.totalPages ? true : false;
    return {
      isNextPageExist: isNextPageExist,
      currentPage: pageInfo.currentPage,
      totalPages: pageInfo.totalPages,
      totalItems: pageInfo.totalItems,
    };
  }
}