import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchCurrentUser } from "../pages/auth/AuthData";
import { convertDropValue } from "../utils";
import { PermissionType } from "../components/dropdowns/PermissionDropdown";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    loading: false,
  },
  reducers: {
    logout(state, action) {
      state.logintime = null;
      state.sessionUser = null;
    },
    setCurrentLocation(state, action) {
      state.currentLocation = action.payload;
    },
    setLoggedInUser(state, action) {
      state.sessionUser = action.payload?.sessionUser;
      let locations = action.payload?.sessionUser?.locations
      if (state.sessionUser?.role?.type === PermissionType.staff && locations?.length === 1) {
        let loc = convertDropValue(locations[0])
        state.currentLocation = loc;
      } else {
        state.currentLocation = null;
      }
      state.logintime = new Date();
      state.loading = false;
    },
    setRecoSetting(state, action) {
      state.comRecoSettings = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadCureentUser.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(loadCureentUser.fulfilled, (state, action) => {
      state.sessionUser = action.payload?.sessionUser;
      let locations = action.payload?.sessionUser?.locations
      if (state.sessionUser?.role?.type === PermissionType.staff && locations?.length === 1) {
        let loc = convertDropValue(locations[0])
        state.currentLocation = loc;
      } else {
        state.currentLocation = null;
      }
      state.logintime = new Date();
      state.loading = false;
    });
    builder.addCase(loadCureentUser.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const { logout, setLoggedInUser, setCurrentLocation, setRecoSetting } =
  authSlice.actions;
export default authSlice.reducer;

// THUNK-------------------->
export const loadCureentUser = createAsyncThunk("current-user-api", () => {
  let data = fetchCurrentUser();
  return data;
});
