import { DropdownToggle, UncontrolledDropdown } from "reactstrap";
import {
  BalanceBadge,
  Icon,
  MoreOptions,
  StatusBadge,
  TableTitle,
  UserAvatar,
} from "../../../components/Component";
import { apiService } from "../../../services/api";
import { APIServicePath } from "../../../services/api/api-service-impl";
import {
  convertDropValue,
  findUpper,
  formatPhoneNumber,
  numberFormatter,
  removeTimeFromServerDateString,
  toCapitalize,
} from "../../../utils/Functions";
import {
  ColorsArray,
  File_TYPE,
  LeaveReason,
  ServerStatusType,
} from "../../../utils/Utils";
import { toPageInfo } from "../../_common/DataConverter";
import moment from "moment";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { COUNTRY_CODE, CURRENCY_CODE } from "../../../utils";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import {
  UserActionType,
  UserPermissionType,
  isAllowToAccess,
} from "../../../services/permission/permission";
// import { toPageInfo } from "../../_common/DataConverter";

export const ResidentTableData = (isDashboard, onEdit) => {
  const location = useLocation();
  return [
    {
      name: <TableTitle title={"Name"} />,
      id: "name",
      selector: (row) => (
        <Link
          to={{
            pathname: `${process.env.PUBLIC_URL}/transaction/${
              row.accountId +
              "-" +
              row?.firstName?.toLowerCase() +
              "-" +
              row?.lastName?.toLowerCase()
            }`,
            state: { row },
          }}
        >
          <UserAvatar
            theme={ColorsArray[Math.floor(Math.random() * ColorsArray?.length)]}
            text={
              findUpper(toCapitalize(row?.firstName ?? " ")) +
              findUpper(toCapitalize(row?.lastName ?? " "))
            }
            name={
              toCapitalize(row?.firstName ?? " ") +
              " " +
              toCapitalize(row?.lastName ?? " ")
            }
          ></UserAvatar>
        </Link>
      ),
      sortable: true,
      grow: 3,
    },
    {
      name: <TableTitle title={"Room No"} />,
      cell: (row) => row?.roomNo,
      // omit: location.pathname === "/" ? true : false,
    },
    {
      name: <TableTitle title={"Resident No"} />,
      cell: (row) => row?.residentNo,
      // omit: location.pathname === "/" ? true : false,
    },

    {
      name: <TableTitle title={"Date Of Birth"} />,
      cell: (row) =>
        row?.birthDate && moment(row?.birthDate).format("DD/MM/YYYY"),
      // omit: location.pathname === "/" ? true : false,
    },
    {
      name: <TableTitle title={"NOK Name"} />,
      selector: (row) => (
        <UserAvatar
          className={"d-none"}
          name={toCapitalize(row?.kins[0]?.kinName ?? " ")}
          relations={toCapitalize(row?.kins[0]?.kinRelation ?? " ")}
        ></UserAvatar>
      ),
    },
    {
      name: <TableTitle title={"NOK Phone"} />,
      cell: (row) => formatPhoneNumber(COUNTRY_CODE + " ", row?.kins[0]?.phone),
    },
    {
      name: <TableTitle title={"Balance"} />,
      cell: (row) => (
        <BalanceBadge
          balance={numberFormatter(
            CURRENCY_CODE,
            "" + (row?.balance ?? row?.openingBalance)
          )}
          isLow={isDashboard}
          isDashboard={isDashboard}
        />
      ),
    },
    isAllowToAccess(UserPermissionType.resident, UserActionType.edit) && {
      name: <TableTitle title={""} align="end" />,
      right: true,
      omit: location.pathname === "/" ? true : false,
      cell: (row) => (
        <UncontrolledDropdown>
          <DropdownToggle
            tag="a"
            className="text-soft dropdown-toggle btn btn-icon btn-trigger table-edit-btn"
          >
            <span
              onClick={() => {
                onEdit(row);
              }}
            >
              <Icon name="edit"></Icon>
            </span>
          </DropdownToggle>
        </UncontrolledDropdown>
      ),
    },
  ];
};

// ----------------------------API---------------------------

export function fetchResidentSave(params, callback) {
  apiService
    .postCall(APIServicePath.residentSave, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function fetchResidentList(params, callback) {
  apiService
    .postCall(APIServicePath.residentList, params)
    .then((data) => {
      let list = toResidentList(data?.list);
      let pageInfo = toPageInfo(data?.pageInfo);
      callback({ list, pageInfo }, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function fetchResidentDetail(params, callback) {
  apiService
    .postCall(APIServicePath.residentDetail, params)
    .then((data) => {
      let cData = toResidentDetails(data?.data);
      callback(cData, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
export function fetchLocationDetail(params, callback) {
  apiService
    .postCall(APIServicePath.locationDetail, params)
    .then((data) => {
      let cData = toLocationDetail(data);
      callback(cData, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function fetchResidentExport(fileType, params, callback) {
  apiService
    .postCall(
      fileType === File_TYPE.xlxs
        ? APIServicePath.residentExportXlsx
        : APIServicePath.residentExportPdf,
      params
    )
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

const toResidentDetails = (data) => {
  let ckinsDetails = data?.kins?.map((value) => ({
    name: value?.kinName ?? "",
    email: value?.email ?? "",
    phoneNumber: {
      dialCode: value?.countryCode ?? "",
      phoneNumber: value?.phone ?? "",
    },
    Address: value?.address ?? "",
    relationship: value?.kinRelation ?? "",
  }));
  let object = {
    id: data?.id,
    firstName: data?.firstName ?? "",
    lastName: data?.lastName ?? "",
    dob: data?.birthDate
      ? moment(removeTimeFromServerDateString(data?.birthDate)).toDate()
      : null,
    location: data?.locations ? convertDropValue(data?.locations[0]) : null,
    residentNumber: data?.residentNo ?? "",
    roomNo: data?.roomNo ?? "",
    discription: data?.description ?? "",
    admissionDate: data?.admissionDate
      ? moment(removeTimeFromServerDateString(data?.admissionDate)).toDate()
      : null,
    kinContacts: ckinsDetails,
    isActive: ServerStatusType.active === data?.status ? true : false,
  };

  if (!object?.isActive) {
    object.Reason = data?.leaveReason
      ? data?.leaveReason === LeaveReason.Leave
        ? { value: LeaveReason.Leave, label: "leave" }
        : data?.leaveReason === LeaveReason.Death
        ? { value: LeaveReason.Death, label: "Death" }
        : ""
      : "";
    object.leaveDate = data?.leaveDate
      ? moment(removeTimeFromServerDateString(data?.leaveDate)).toDate()
      : "";
    object.reasonDiscription = data?.leaveDescription ?? "";
  }
  return object;
};

const toResidentList = (item) => {
  let list = [];
  item?.length > 0 &&
    item?.map((lItem) => {
      let object = {
        id: lItem?.id,
        firstName: lItem?.firstName,
        lastName: lItem?.lastName,
        birthDate: lItem?.birthDate,
        residentNo: lItem?.residentNo,
        roomNo: lItem?.roomNo,
        discription: lItem?.discription,
        currentBalance: lItem?.currentBalance,
        status: lItem?.status === ServerStatusType.active ? true : false,
        kins: lItem?.kins,
        accountId: lItem?.account?.id,
        balance: lItem?.account?.lastTransaction?.balance,
        openingBalance: lItem?.account?.openingBalance,
      };
      return list.push(object);
    });
  return list;
};
const toLocationDetail = (item) => {
  let accountList = [];

  item?.data?.accounts?.length > 0 &&
    item?.data?.accounts?.map((aItem) => {
      let object = {
        id: aItem?.id,
        residentId: aItem?.residentId,
        locationId: aItem?.locationId,
        type: aItem?.type,
        name: aItem?.name,
        balance: aItem?.balance,
        createdBy: aItem?.createdBy,
        status: aItem?.status,
        openingBalance: aItem?.openingBalance,
      };
      return accountList.push(object);
    });
  let detailsObject = {
    id: item?.data?.id,
    locationName: item?.data?.locationName,
    totalBeds: item?.data?.totalBeds,
    totalActiveBeds: item?.data?.totalActiveBeds,
    status: item?.data?.status,
    accounts: accountList,
    totalResidentBalance: item?.totalResidentBalance,
    totalActiveResidentBalance: item?.totalActiveResidentBalance,
    totalInactiveResidentBalance: item?.totalInactiveResidentBalance,
    totalResidentBalance: item?.totalResidentBalance,
  };

  return detailsObject;
};
