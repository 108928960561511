import React, { useState } from "react";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";

import {
  ActionButton,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  LoadingComponent,
  PreviewCard,
  ReactDataTable,
  RoleDropDown,
  StatusActiveDropDown,
} from "../../../../components/Component";
import AddUser from "./AddStaff";
import { listStaffAPI, listUserAPI, userDataTableColumns } from "./StaffData";
import { useEffect } from "react";
import { PER_PAGE_ITEMS, ServerStatusType } from "../../../../utils";
import {
  UserActionType,
  UserPermissionType,
  isAllowToAccess,
  isCheckToList,
} from "../../../../services/permission/permission";

const StaffList = () => {
  const [staffListData, setStaffListData] = useState([]);
  const [status, setStatus] = useState();
  const [searchText, setSearchText] = useState("");
  const [role, setRole] = useState();
  const [modal, setModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [initScreen, setInitScreen] = useState(true);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    loadUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (initScreen) return;
    if (currentPage === 1) {
      loadUsers();
    } else {
      setCurrentPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, searchText]);

  const onEdit = (row) => {
    setSelectedItem(row);
    setModal(true);
  };

  function loadUsers() {
    isCheckToList(UserPermissionType.staff) && setInitScreen(true);
    listStaffAPI(
      {
        status: status?.value ?? ServerStatusType.active,
        search: searchText,
        pageInfo: {
          pageIndex: currentPage,
          itemsPerPage: PER_PAGE_ITEMS,
        },
      },
      (data, error) => {
        setInitScreen(false);
        if (error === null) {
          setStaffListData(data.list);
          if (currentPage === 1) {
            setTotalItems(data?.pageInfo?.totalItems ?? 0);
          }
        }
        setInitScreen(false);
      }
    );
  }
  return (
    <React.Fragment>
      <Head title="Staff" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Staff</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="d-flex align-items-center">
                <span className="me-2">
                  <input
                    className="form-control"
                    type="text"
                    name="search"
                    value={searchText}
                    placeholder="Search"
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                  />
                </span>
                {/* <span className="me-2">
                  <RoleDropDown
                    value={role}
                    className="w-100px"
                    onSelect={(e) => {
                      setRole(e);
                    }}
                  />
                </span> */}
                <span className="me-2">
                  <StatusActiveDropDown
                    value={status}
                    className="w-100px "
                    onSelect={(e) => {
                      setStatus(e);
                    }}
                  />
                </span>
                {isAllowToAccess(
                  UserPermissionType.staff,
                  UserActionType.add
                ) && (
                  <span>
                    <ActionButton
                      title="Add"
                      size="md"
                      onClick={() => setModal(true)}
                    />
                  </span>
                )}
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <PreviewCard>
            <ReactDataTable
              data={staffListData}
              isAllowed={isCheckToList(UserPermissionType.staff)}
              columns={userDataTableColumns(onEdit)}
              totalItemCount={totalItems}
              pagination
              onPageChanged={(page, count) => {
                setCurrentPage(page);
              }}
            />
          </PreviewCard>
        </Block>
        <AddUser
          visible={modal}
          item={selectedItem}
          onClosed={() => {
            setModal(false);
            setSelectedItem();
          }}
          onSaved={() => {
            loadUsers();
          }}
        />
      </Content>
      <LoadingComponent isLoading={initScreen} />
    </React.Fragment>
  );
};

export default StaffList;
