import { Button, Icon } from "../Component";

const IconButton = ({ onClick, icon, className, size, color, disabled }) => {
  return (
    <Button
      className={`btn-round btn-icon ${className}`}
      type="button"
      color={color}
      size={size}
      onClick={onClick}
      disabled={disabled}
    >
      <Icon name={icon} />
    </Button>
  );
};

export default IconButton;
