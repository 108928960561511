import React, { Suspense, useLayoutEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { RedirectAs404 } from "../utils";

import Faq from "../pages/others/Faq";
import Terms from "../pages/others/Terms";
import Dashboard from "../pages/tabs/dashboard/Dashboard";

import UserRoleList from "../pages/tabs/others/user-role/UserRoleList";

import AddUserRole from "../pages/tabs/others/user-role/AddUserRole";

import VerifyPassword from "../pages/auth/CheckPassword";
import CreatePassword from "../pages/auth/CreatePassword";
import Resident from "../pages/tabs/Resident/Resident";
import Transaction from "../pages/tabs/Transaction/Transaction";
import Reco from "../pages/tabs/Reco/Reco";

import AddTransaction from "../pages/tabs/Transaction/AddTransaction";

import StaffList from "../pages/tabs/others/Staff/StaffList";
import LocationList from "../pages/tabs/others/Location/LocationList";
import CustomTransaction from "../pages/tabs/account/AccountTransaction";
import AuditTrail from "../pages/tabs/AuditTrail/AuditTrail";

const Pages = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <Suspense fallback={<div />}>
      <Switch>
        {/*Dashboards*/}
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/`}
          component={Dashboard}
        ></Route>

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/location`}
          component={LocationList}
        ></Route>

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/staff`}
          component={StaffList}
        ></Route>

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/roles`}
          component={UserRoleList}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/add-userrole`}
          component={AddUserRole}
        ></Route>

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/check-password`}
          component={VerifyPassword}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/change-password`}
          component={CreatePassword}
        ></Route>

        {/*Demo Pages*/}
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/pages/terms-policy`}
          component={Terms}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/pages/faq`}
          component={Faq}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/resident`}
          component={Resident}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/transaction`}
          component={Transaction}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/transaction/:id`}
          component={Transaction}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/add-transaction`}
          component={AddTransaction}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/account`}
          component={CustomTransaction}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/reco`}
          component={Reco}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/audit-trail`}
          component={AuditTrail}
        ></Route>
        <Route component={RedirectAs404}></Route>
      </Switch>
    </Suspense>
  );
};
export default Pages;
