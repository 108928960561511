import React from "react";

export const TableTitle = ({
  title,
  align,
  filterable,
  className,
  onTextChange = () => {},
}) => {
  return (
    <ul>
      <li>
        <div
          className={`d-flex fw-medium text-white container p-0 ${
            align ? `px-1 justify-content-${align}` : "justify-content-left"
          } ${className ? className : ""}`}
        >
          {title}
        </div>
      </li>
      {filterable && (
        <li>
          <input
            type="text"
            className="my-1"
            onChange={(e) => {
              e.preventDefault();
              onTextChange(e.target.value);
            }}
            style={{ width: "90%" }}
          />
        </li>
      )}
    </ul>
  );
};
