import React from "react";
import {
  AnnsModal,
  Col,
  FormInput,
  FormInputType,
  LocationSelectDropdown,
  RadioButtonOptions,
  RoleDropDown,
} from "../../../../components/Component";
import { Form } from "reactstrap";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useEffect } from "react";
import { AppText, RadioOptionTypes } from "../../../../utils";
import { showErrorToast, showSuccessToast } from "../../../../utils/Functions";
import { addStaffAPI } from "./StaffData";
import {
  PermissionDropdown,
  PermissionType,
} from "../../../../components/dropdowns/PermissionDropdown";

const Addstaff = ({ visible, item, onSaved, onClosed }) => {
  const [staffData, setStaffData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(true);



  useEffect(() => {
    if (item) {
      setStaffData(item);
    } else {
      setStaffData({
        firstName: "",
        lastName: "",
        email: "",
        role: null,
        permission: null, // Update this line with the default value
        extraInfo: "",
        location: null,
        isActive: true,
      });
    }
  }, [visible, item]);

  useEffect(() => {
    const hasEmptyFields =
      staffData.firstName === "" ||
      staffData.lastName === "" ||
      staffData.email === "";

    const hasErrors =
      staffData.isFirstNameError ||
      staffData.isLastNameError ||
      staffData.isEmailError;

    setIsDisable(hasEmptyFields || hasErrors);
  }, [staffData]);

  const close = () => {
    onClosed();
    setLoading(false);
    setStaffData({});
  };

  const onFormSubmit = () => {
    if (isDisable) return;
    setLoading(true);
    addStaffAPI(staffData, (_, error) => {
      if (error === null) {
        let text =
          "User" +
          (item ? AppText.EditedSuccessfully : AppText.AddedSuccessfully);
        showSuccessToast(text);
        onSaved();
        close();
      } else {
        showErrorToast(error.message);
      }
      setLoading(false);
    });
  };

  const { handleSubmit } = useForm();

  return (
    <>
      <AnnsModal
        size={"md"}
        isOpen={visible}
        toggle={() => onClosed()}
        title={item ? "Edit Staff" : "Add Staff"}
        loading={loading}
        onClick={() => onFormSubmit()}
        disabled={isDisable}
      >
        <Form
          className="row gy-4 form-validation"
          onSubmit={handleSubmit(onFormSubmit)}
        >
          <Col md="6">
            <div className="form-group">
              <div className="form-control-wrap">
                <FormInput
                  label={"First Name"}
                  placeholder={"First Name"}
                  type={FormInputType.name}
                  value={staffData?.firstName ?? ""}
                  onError={(isError) => {
                    setStaffData((prev) => ({
                      ...prev,
                      isFirstNameError: isError,
                    }));
                  }}
                  onTextChange={(firstName) => {
                    setStaffData((prev) => ({
                      ...prev,
                      firstName: firstName,
                    }));
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <div className="form-control-wrap">
                <FormInput
                  label={"Last Name"}
                  placeholder={"Last Name"}
                  type={FormInputType.name}
                  value={staffData?.lastName ?? ""}
                  onError={(isError) => {
                    setStaffData((prev) => ({
                      ...prev,
                      isLastNameError: isError,
                    }));
                  }}
                  onTextChange={(lastName) => {
                    setStaffData((prev) => ({
                      ...prev,
                      lastName: lastName,
                    }));
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <div className="form-control-wrap">
                <FormInput
                  label={"Email"}
                  placeholder={"Email"}
                  type={FormInputType.email}
                  value={staffData?.email}
                  onError={(isError) => {
                    setStaffData((prev) => ({
                      ...prev,
                      isEmailError: isError,
                    }));
                  }}
                  onTextChange={(email) => {
                    setStaffData((prev) => ({
                      ...prev,
                      email: email,
                    }));
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <label className="form-label">Permission</label>
              <div className="form-control-wrap">
                <PermissionDropdown
                  value={staffData?.permission ?? ""}
                  onSelect={(permission) => {
                    setStaffData((prev) => ({
                      ...prev,
                      permission: permission,
                      location: null,
                      role: null,
                    }));
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <label className="form-label">Role</label>

              <div className="form-control-wrap">
                <RoleDropDown
                  userType={staffData?.permission?.value}
                  value={staffData?.role ?? ""}
                  selectedId={staffData?.role}
                  onSelect={(role) => {
                    setStaffData((prev) => ({
                      ...prev,
                      role: role,
                    }));
                  }}
                />
              </div>
            </div>
          </Col>

          {(!staffData?.permission ||
            staffData?.permission?.label === "Home Admin" ||
            staffData?.permission?.label === "Manager") && (
            <Col md="6">
              <div className="form-group">
                <label className="form-label">Home Location</label>
                <div className="form-control-wrap">
                  <LocationSelectDropdown
                    value={staffData?.location}
                    isMulti={
                      staffData?.permission?.value === PermissionType.manager
                    }
                    onSelect={(location) => {
                      setStaffData((prev) => ({
                        ...prev,
                        location:
                          staffData?.permission?.value ===
                          PermissionType.manager
                            ? location
                            : staffData?.permission?.value ===
                              PermissionType.staff
                            ? [location]
                            : [],
                      }));
                    }}
                  />
                </div>
              </div>
            </Col>
          )}
          <Col md="12">
            <div className="form-group">
              <div className="form-control-wrap">
                <textarea
                  className="form-control"
                  placeholder="Extra Info (optional)"
                  value={staffData?.extraInfo ?? ""}
                  onChange={(e) => {
                    setStaffData((prev) => ({
                      ...prev,
                      extraInfo: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md="12">
            <div className="form-group">
              <div className="form-control-wrap">
                <RadioButtonOptions
                  name="status"
                  optionType={RadioOptionTypes.status}
                  onChange={(index) => {
                    setStaffData((prev) => ({
                      ...prev,
                      isActive: index === 0,
                    }));
                  }}
                  activeIndex={staffData.isActive ? 0 : 1}
                />
              </div>
            </div>
          </Col>
        </Form>
      </AnnsModal>
    </>
  );
};
export default Addstaff;
