import {
  MoreOptions,
  StatusBadge,
  TableTitle,
} from "../../../../components/Component";
import { apiService } from "../../../../services/api";
import { APIServicePath } from "../../../../services/api/api-service-impl";
import {
  UserActionType,
  UserPermissionType,
  isAllowToAccess,
} from "../../../../services/permission/permission";
import { ServerStatusType } from "../../../../utils";
import { toPageInfo } from "../../../_common/DataConverter";

export const LocationTableData = (onEdit, onRemove) => {
  return [
    {
      name: <TableTitle title={"Name"} />,
      selector: (row) => row?.name ?? "",
      sortable: true,
    },
    {
      name: <TableTitle title={"Beds"} />,
      selector: (row) => row?.totalActiveBeds + "/" + row?.totalBeds,
      sortable: true,
    },
    {
      name: <TableTitle title={"Status"} />,
      cell: (row) => <StatusBadge status={row?.isActive} />,
    },
    isAllowToAccess(UserPermissionType.location, UserActionType.edit) && {
      name: <TableTitle title={""} align="end" />,
      right: true,
      cell: (row) => (
        <MoreOptions
          options={[
            {
              icon: "edit",
              text: "Edit",
              action: () => {
                onEdit(row);
              },
            },
            // {
            //   icon: "trash",
            //   text: "Remove",
            //   action: () => {
            //     onRemove(row);
            //   },
            // },
          ]}
        />
      ),
    },
  ];
};

//***************************API's*********************************/
export function addLocationAPI(data, callback) {
  let params = {
    id: data.id,
    name: data.name,
    totalBeds: data.totalBeds ? parseInt(data.totalBeds) : null,
    status: data.isActive ? ServerStatusType.active : ServerStatusType.inactive,
  };
  apiService
    .postCall(APIServicePath.addLocation, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function listLocationAPI(params, callback) {
  apiService
    .postCall(APIServicePath.listLocation, params)
    .then((data) => {
      let list = toBranchList(data?.list);
      let pageInfo = toPageInfo(data?.pageInfo);
      let result = {
        list: list,
        pageInfo: pageInfo,
      };
      callback(result, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

//***************************Converters*********************************/

function toBranchList(data) {
  let list = [];
  data?.forEach((element) => {
    list.push(toBranch(element));
  });
  return list;
}

function toBranch(data) {
  return {
    id: data.id,
    name: data?.locationName ?? "",
    totalBeds: data?.totalBeds,
    totalActiveBeds: data?.totalActiveBeds,
    isActive: data?.status === ServerStatusType.active ? true : false,
  };
}
