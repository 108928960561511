import React from "react"; // Replace 'your-ui-library' with the actual UI library you are using
import Button from "./Button"; // Make sure to adjust the import path if needed
import { Icon } from "../Component";
import { CustomButtonTypes } from "../../utils/Utils";
import { Spinner } from "reactstrap";

const CustomButton = ({ label, type, onClick, loading }) => {
  return (
    <Button
      color="light"
      type="button"
      outline
      className="bg-white d-none d-sm-inline-flex fw-normal"
      disabled={loading}
      onClick={onClick}
    >
      {type === CustomButtonTypes.Print && (
        <Icon className={""} name="printer-fill"></Icon>
      )}
      {loading ? <Spinner size="sm" color="primary" /> : label}
    </Button>
  );
};

export default CustomButton;
