import React, { useEffect, useState } from "react";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  FormInput,
  PreviewCard,
  ReactDataTable,
  LoadingComponent,
} from "../../../components/Component";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  AccountTableData,
  fetchAccountExport,
  listAccountAPI,
} from "./AccountTableData";
import ExportButton from "../../../components/button/ExportButton";
import {
  CustomButtonTypes,
  EXPORT_TYPE,
  File_TYPE,
  ServerStatusType,
} from "../../../utils/Utils";
import CustomButton from "../../../components/button/CustomButton";
import { PER_PAGE_ITEMS } from "../../../utils";
import TranscationType, {
  TranscationTypes,
} from "../Transaction/TranscationType";
import { openLinkInNewTab } from "../../../utils/Functions";
import { useSelector } from "react-redux";
import {
  UserActionType,
  UserPermissionType,
  isAllowToAccess,
  isCheckToList,
} from "../../../services/permission/permission";

const AccountTransaction = () => {
  const location = useSelector((state) => state?.auth?.currentLocation);
  const [searchText, setSearchText] = useState();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [initScreen, setInitScreen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    loadAccount();
  }, [currentPage]);

  useEffect(() => {
    if (currentPage === 1) {
      loadAccount();
    } else {
      setCurrentPage(1);
    }
  }, [searchText, location]);

  // const onExport = (fType) => {
  //   if (fType === File_TYPE.xlxs) {
  //     loadAccountExportXlsx();
  //   }
  // };

  // API -----------------------------------

  function loadAccount() {
    if (!location) return;
    setInitScreen(true);
    isCheckToList(UserPermissionType.account) &&
      listAccountAPI(
        {
          locationId: location ? location.id : null,
          search: searchText,
          pageInfo: {
            pageIndex: currentPage,
            itemsPerPage: PER_PAGE_ITEMS,
          },
          accountType: [TranscationTypes.other],
        },
        (data, error) => {
          setInitScreen(false);
          if (error === null) {
            setData(data.list);
            if (currentPage === 1) {
              setTotalItems(data?.pageInfo?.totalItems ?? 0);
            }
          }
          setInitScreen(false);
        }
      );
  }

  const loadAccountExport = (fType) => {
    let params = {
      search: searchText,
      locationId: location ? location.id : null,
      accountType: [TranscationTypes.other],
    };
    let fileType = fType;
    fetchAccountExport(fileType, params, (data, error) => {
      setIsLoading(false);
      if (error === null) {
        openLinkInNewTab(data?.fileUrl);
      }
    });
  };

  return (
    <React.Fragment>
      <Head title="Account" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Account Transaction</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="d-flex align-items-center">
                <span className="me-2">
                  <FormInput
                    labelGroupClass="m-0"
                    value={searchText ?? ""}
                    placeholder="Search "
                    onTextChange={(text) => setSearchText(text)}
                  />
                </span>
                {/* <span className="me-2">
                  <LocationDropDown
                    placeholder={"Locations"}
                    value={location}
                    className="w-250px "
                    onSelect={(location) => {
                      setLocation(location);
                    }}
                  />
                </span> */}
                {isAllowToAccess(
                  UserPermissionType.account,
                  UserActionType.export
                ) && (
                  <span>
                    <ExportButton
                      className={"me-2"}
                      title={"Export"}
                      options={EXPORT_TYPE}
                      onChange={(fType) => {
                        loadAccountExport(fType);
                      }}
                    />
                  </span>
                )}
                {isAllowToAccess(
                  UserPermissionType.account,
                  UserActionType.print
                ) && (
                  <span className="">
                    <CustomButton
                    label=""
                    type={CustomButtonTypes.Print}
                    loading={isLoading}
                    onClick={() => {
                      setIsLoading(true);
                      loadAccountExport();
                    }}
                  />
                  </span>
                )}
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <PreviewCard>
            <ReactDataTable
              data={data}
              isAllowed={isCheckToList(UserPermissionType.account)}
              columns={AccountTableData()}
              totalItemCount={totalItems}
              pagination
              onPageChanged={(page, count) => {
                setCurrentPage(page);
              }}
            />
          </PreviewCard>
        </Block>
      </Content>
      <LoadingComponent isLoading={initScreen} />
    </React.Fragment>
  );
};

export default AccountTransaction;
