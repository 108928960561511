import React from "react";
import { Col, Icon, Row } from "../Component";
import { RadioOptionTypes } from "../../utils";
import { JobStatus } from "../../utils/Utils";

export const RadioButtonOptions = ({
  optionType,
  size = 6,
  onChange,
  activeIndex,
  name,
  disabled,
}) => {
  const handleRadioChange = (index) => {
    if (onChange) {
      onChange(index);
    }
  };

  const getTypeOptions = (optionType) => {
    switch (optionType) {
      case RadioOptionTypes.status:
        return ["Active", "Inactive"];
      case RadioOptionTypes.hospital:
        return ["Hospital", "Home care"];
      case RadioOptionTypes.yesorno:
        return ["Yes", "No"];
      case RadioOptionTypes.ChequeStatus:
        return ["Unpresented", "Clear"];
      case RadioOptionTypes.weekorMonth:
        return ["Monthly", "Custom"];
      case RadioOptionTypes.jobStatus:
        return JobStatus;
      default:
        return [];
    }
  };
  const options = getTypeOptions(optionType);

  return (
    <Row>
      {options.map((item, index) => {
        return (
          <Col key={index} size={size}>
            <label className="radio-label align-items-center d-flex align-items-center">
              <input
                className="me-1"
                type="radio"
                name={name}
                onChange={() => handleRadioChange(index)}
                checked={index === activeIndex}
                disabled={disabled}
              />
              {/* <span className="radio-indicator"></span> */}
              <span className="radio-label-text">
                {/* <Icon className="me-2" name={item.icon} /> */}
                {item}
              </span>
            </label>
          </Col>
        );
      })}
    </Row>
  );
};
