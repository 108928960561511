import React, { useEffect, useRef, useState } from "react";
import {
  ActionButton,
  BackButton,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewCard,
} from "../../../components/Component";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { MonthDropDown } from "../../../components/dropdowns/MonthDropDown";
import { LocationDropDown } from "../../../components/dropdowns/LocationDropDown";
import { NavigationTabs } from "../../../components/navigationTabs/NavigationTabs";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import CustomButton from "../../../components/button/CustomButton";
import TranscationType, { TranscationTypes } from "./TranscationType";
import ExportButton from "../../../components/button/ExportButton";
import {
  CustomButtonTypes,
  EXPORT_TYPE,
  File_TYPE,
} from "../../../utils/Utils";
import { useSelector } from "react-redux";
import { DateRangePicker } from "../../../components/input/date-time/DateRangePicker";
import moment from "moment/moment";
import {
  fetchTransactionExportXlsx,
  fetchTransactionTransPdf,
} from "./TransactionData";
import { openLinkInNewTab } from "../../../utils/Functions";
import {
  UserActionType,
  UserPermissionType,
  isAllowToAccess,
  isCheckToList,
} from "../../../services/permission/permission";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Transaction = ({ match }) => {
  const locations = useSelector((state) => state?.auth?.currentLocation);
  const location = useLocation();
  const [activeTab, setActiveTab] = useState();
  const [month, setMonth] = useState();
  const [accountId, setAccountId] = useState(location?.state?.row?.accountId);
  const [isLoading, setIsLoading] = useState(false);
  const tabs = [{ text: "Cash" }, { text: "Bank" }, { text: "All" }];
  const history = useHistory();

  function auditTrail() {
    history.push({
      pathname: `${process.env.PUBLIC_URL}/audit-trail`,
    });
  }

  useEffect(() => {
    const idStr = match.params.id;
    if (idStr !== undefined || null || "") {
      let list = idStr.split("-");
      const id = list.length > 0 ? parseInt(list[0]) : "";
      setAccountId(id);
    } else {
      setAccountId();
      setActiveTab(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id]);

  useEffect(() => {
    if (accountId) {
    }
  }, [accountId]);

  const exportFile = (fileType) => {
    if (File_TYPE?.pdf === fileType) {
      exportPdf();
    } else if (File_TYPE?.xlxs === fileType) {
      exportXlxs();
    }
  };
  // API------------------------------------->
  const exportPdf = () => {
    let params = {
      accountTypes:
        !activeTab && activeTab !== 0
          ? location?.state?.row?.accountTypes === TranscationTypes.other
            ? [TranscationTypes.other]
            : location?.state?.row?.accountTypes === TranscationTypes.bank
            ? [TranscationTypes.bank]
            : location?.state?.row?.accountTypes === TranscationTypes.cash
            ? [TranscationTypes.cash]
            : [TranscationTypes.resident]
          : activeTab === 2
          ? ["", TranscationTypes.cash, TranscationTypes.bank]
          : activeTab === 0
          ? [TranscationTypes.cash]
          : [TranscationTypes.bank],
      locationId: locations?.id,
      accountId: accountId,
      startDate: month?.startDate
        ? moment(month?.startDate).format("YYYY-MM-DD")
        : null,
      endDate: month?.endDate
        ? moment(month?.endDate).format("YYYY-MM-DD")
        : null,
    };
    fetchTransactionTransPdf(params, (data, error) => {
      setIsLoading(false);
      if (error === null) {
        openLinkInNewTab(data?.fileUrl);
      }
    });
  };

  const exportXlxs = () => {
    let params = {
      accountTypes:
        !activeTab && activeTab !== 0
          ? location?.state?.row?.accountTypes === TranscationTypes.other
            ? [TranscationTypes.other]
            : location?.state?.row?.accountTypes === TranscationTypes.bank
            ? [TranscationTypes.bank]
            : location?.state?.row?.accountTypes === TranscationTypes.cash
            ? [TranscationTypes.cash]
            : [TranscationTypes.resident]
          : activeTab === 2
          ? ["", TranscationTypes.cash, TranscationTypes.bank]
          : activeTab === 0
          ? [TranscationTypes.cash]
          : [TranscationTypes.bank],
      locationId: locations?.id,
      accountId: accountId,
      startDate: month?.startDate
        ? moment(month?.startDate).format("YYYY-MM-DD")
        : null,
      endDate: month?.endDate
        ? moment(month?.endDate).format("YYYY-MM-DD")
        : null,
    };
    fetchTransactionExportXlsx(params, (data, error) => {
      if (error === null) {
        openLinkInNewTab(data?.fileUrl);
      }
    });
  };

  return (
    <React.Fragment>
      <Head title="Transaction" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>
                {accountId
                  ? location?.state?.row?.firstName
                    ? `${location?.state?.row?.firstName} ${location?.state?.row?.lastName}`
                    : location?.state?.row?.accountName
                    ? location?.state?.row?.accountName
                    : "Transaction"
                  : "Transaction"}
              </BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="d-flex align-items-center">
                {isCheckToList(UserPermissionType.auditTrail) && (
                  <>
                    <span className="me-1">
                      <ActionButton
                        title={"Audit Trail"}
                        size={"md"}
                        onClick={() => auditTrail()}
                      />
                    </span>
                  </>
                )}
                {month?.value === "CUSTOM" && (
                  <div className="form-group mx-1 my-0">
                    <div className="form-control-wrap">
                      <DateRangePicker
                        start={month?.startDate}
                        end={month?.endDate}
                        dateFormat="dd-MM-yyyy"
                        maxDate={new Date(month?.startDate)?.setFullYear(
                          new Date(month?.startDate).getFullYear() + 1
                        )}
                        onChangeStart={(date) => {
                          setMonth({
                            ...month,
                            startDate: date,
                            endDate: date,
                          });
                        }}
                        onChangeEnd={(date) => {
                          setMonth({ ...month, endDate: date });
                        }}
                      />
                    </div>
                  </div>
                )}
                <span className="me-1">
                  <MonthDropDown
                    value={month}
                    className="w-150px "
                    onSelect={(e) => {
                      if (e.value === "CUSTOM") {
                        setMonth((previous) => ({
                          ...previous,
                          startDate: previous.startDate,
                          endDate: previous.endDate,
                          label: e.label,
                          value: e.value,
                        }));
                      } else {
                        setMonth(e);
                      }
                    }}
                  />
                </span>
                {isAllowToAccess(
                  UserPermissionType.transaction,
                  UserActionType.export
                ) && (
                  <span>
                    <ExportButton
                      title={"Export"}
                      options={EXPORT_TYPE}
                      onChange={(fileType) => exportFile(fileType)}
                    />
                  </span>
                )}

                {isAllowToAccess(
                  UserPermissionType.transaction,
                  UserActionType.print
                ) && (
                  <span>
                    <CustomButton
                      label={""}
                      type={CustomButtonTypes.Print}
                      loading={isLoading}
                      onClick={() => {
                        setIsLoading(true);
                        exportPdf();
                      }}
                    />
                  </span>
                )}
                {accountId && (
                  <span className="ms-1">
                    <BackButton />
                  </span>
                )}
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <PreviewCard bodyClass={"mt-n4"} className={"mt-2"}>
            {!accountId && activeTab >= 0 ? (
              <NavigationTabs
                tabs={tabs}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            ) : null}
            {(accountId || activeTab >= 0) && (
              <TranscationType
                accountId={accountId}
                locationId={locations?.id}
                startDate={month?.startDate}
                endDate={month?.endDate}
                type={
                  !activeTab && activeTab !== 0
                    ? location?.state?.row?.accountTypes ===
                      TranscationTypes.other
                      ? TranscationTypes.other
                      : location?.state?.row?.accountTypes ===
                        TranscationTypes.bank
                      ? TranscationTypes.bank
                      : location?.state?.row?.accountTypes ===
                        TranscationTypes.cash
                      ? TranscationTypes.cash
                      : TranscationTypes.resident
                    : activeTab === 2
                    ? ""
                    : activeTab === 0
                    ? TranscationTypes.cash
                    : TranscationTypes.bank
                }
              />
            )}
          </PreviewCard>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default Transaction;
