import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchMasterList } from "../pages/auth/AuthData";

const masterListSlice = createSlice({
  name: "masterList",
  initialState: {},
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder.addCase(loadFetchMasterList.pending, (state, action) => {
    });
    builder.addCase(loadFetchMasterList.fulfilled, (state, action) => {
      state.masterList = action.payload?.list;
    });
    builder.addCase(loadFetchMasterList.rejected, (state, action) => {
    });
  },
});

// export const { setLoginUser, getLoginUser, getSessionToken, setSessionToken } = masterListSlice.actions;
export default masterListSlice.reducer;

// THUNK-------------------->
export const loadFetchMasterList = createAsyncThunk("master-list-API", () => {
  let data = fetchMasterList();
  return data;
});
