import moment from "moment";
import {
  MoreOptions,
  StatusBadge,
  TableTitle,
} from "../../../../components/Component";
import { APIServicePath } from "../../../../services/api/api-service-impl";
import { apiService } from "../../../../services/api";
import { ServerStatusType, convertDropValue } from "../../../../utils";
import {
  PermissionList,
  PermissionType,
} from "../../../../components/dropdowns/PermissionDropdown";
import {
  UserActionType,
  UserPermissionType,
  isAllowToAccess,
} from "../../../../services/permission/permission";

// export const UserRoleData = [
//   { id: 1, title: "Hospital", isActive: false, date: new Date() },
//   { id: 2, title: "Hospital", isActive: false, date: new Date() },
//   { id: 3, title: "Hospital", isActive: false, date: new Date() },
// ];

export const userRoleTableData = (onEdit, onRemove) => {
  return [
    {
      name: <TableTitle title={"Title"} />,
      selector: (row) => row?.name ?? "",
      // sortable: true,
    },
    {
      name: <TableTitle title={"Status"} />,
      cell: (row) => (
        <StatusBadge
          status={row?.status === ServerStatusType.active ? true : false}
        />
      ),
    },
    // {
    //   name: <TableTitle title={"Last update"} />,
    //   selector: (row) => moment(row?.date).format("DD-MM-YYYY"),
    //   sortable: true,
    // },
    isAllowToAccess(UserPermissionType.userRole, UserActionType.edit) && {
      name: <TableTitle title={""} align="end" />,
      right: true,
      cell: (row) => (
        <MoreOptions
          options={[
            {
              icon: "edit",
              text: "Edit",
              action: () => {
                onEdit(row);
              },
            },
          ]}
        />
      ),
    },
  ];
};

export function fetchRoleList(params, callback) {
  apiService
    .postCall(APIServicePath.roleList, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function userMasterAPI(callback) {
  apiService
    .postCall(APIServicePath.roleMaster)
    .then((data) => {
      let list = toUserMasterList(data.list);
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function addUserRoleAPI(data, callback) {
  let MODULE = data?.moduleStatuses;
  let moduleValues = Object.entries(MODULE).map(
    ([moduleValue, permissions]) => {
      return {
        value: moduleValue,
        permissionValues: permissions,
      };
    }
  );
  let params = {
    id: data?.id ?? 0,
    name: data?.name ?? "string",
    status: data?.isActive
      ? ServerStatusType.active
      : ServerStatusType.inactive,
    type: data?.type?.value,
    modules: moduleValues.map(({ value, permissionValues }) => {
      return {
        value,
        permissionValues: permissionValues.map((permission) => permission),
      };
    }),
  };

  apiService
    .postCall(APIServicePath.roleSave, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function getRoleDetailAPI(params, callback) {
  apiService
    .postCall(APIServicePath.roleDetail, params)
    .then((data) => {
      let role = toUserRoleDetail(data.data);
      callback(role, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

//***************************Converters*********************************/

function toUserRoleList(data) {
  let list = [];
  data?.forEach((element) => {
    list.push(toUserRole(element));
  });
  return list;
}

function toUserRole(data) {
  return {
    id: data?.id ?? "",
    name: data?.name ?? "",
    isActive: data?.status === ServerStatusType.active ? true : false,
  };
}

function toUserRoleDetail(data) {
  if (!data) return;

  let moduleStatuses = {};
  data?.modules?.forEach((module) => {
    moduleStatuses[module?.value] = module.permissions.map(
      (permission) => permission.value
    );
  });

  let object = {
    id: data?.id,
    name: data?.name ?? "",
    isActive: data?.status === ServerStatusType.active,
    moduleStatuses: moduleStatuses,
    type: data?.type
      ? PermissionList.find((item) => item.value === data?.type)
      : null,
  };
  return object;
}

function toUserMasterList(data) {
  let list = [];
  data?.forEach((element) => {
    list.push(toUserMasterItem(element));
  });
  return list;
}

function toUserMasterItem(data) {
  let permission = [];
  data?.permissions?.forEach((element) => {
    permission.push({
      id: element.id,
      name: element?.name ?? "",
      value: element?.value ?? "",
    });
  });

  return {
    id: data?.id ?? "",
    name: data?.name ?? "",
    value: data?.value ?? "",
    permissions: permission,
  };
}
