import React, { useEffect, useState } from "react";
import { Form, Label } from "reactstrap";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router";
import Head from "../../../../layout/head/Head";
import Content from "../../../../layout/content/Content";
import {
  ActionButton,
  BackButton,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Col,
  FormCheckbox,
  FormInput,
  FormInputType,
  PreviewAltCard,
  RadioButtonOptions,
  Row,
} from "../../../../components/Component";
import {
  addUserRoleAPI,
  getRoleDetailAPI,
  userMasterAPI,
} from "./UserRoleTableData";
import {
  AppText,
  RadioOptionTypes,
  ServerStatusType,
  showErrorToast,
  showSuccessToast,
} from "../../../../utils";
import { PermissionDropdown } from "../../../../components/dropdowns/PermissionDropdown";

const AddUserRole = () => {
  const location = useLocation();
  const selected = location.state;
  const history = useHistory();
  const [userMaster, setUserMaster] = useState();

  const [userRoleData, setUserRoleData] = useState({
    name: "",
    isActive: true,
    moduleStatuses: {},
  });
  const [loading, setLoading] = useState(false);
  const [isDisble, setIsDisable] = useState(true);

  useEffect(() => {
    if ((userRoleData?.name?.length ?? 0) === 0) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  }, [userRoleData]);

  useEffect(() => {
    userMasterAPI((data, error) => {
      if (error === null) {
        setUserMaster(data);
      }
    });
  }, []);

  useEffect(() => {
    let id = location.state?.data?.id;
    if (id) {
      loadRoleDetail(id);
    }
  }, [location.state?.data]);

  const onFormSubmit = () => {
    setLoading(true);
    addUserRoleAPI(userRoleData, (_, error) => {
      if (error === null) {
        let text =
          "User Role" +
          (selected ? AppText.EditedSuccessfully : AppText.AddedSuccessfully);
        showSuccessToast(text);
        history.push(`${process.env.PUBLIC_URL}/roles`);
      } else {
        showErrorToast(error.message);
      }
      setLoading(false);
    });
  };

  function loadRoleDetail(id) {
    getRoleDetailAPI({ roleId: id }, (data, error) => {
      if (error === null) {
        setUserRoleData(data);
      }
    });
  }

  const { handleSubmit } = useForm();
  return (
    <React.Fragment>
      <Head title="Add user role" />
      <Content>
        <Block className={"justify-center"}>
          <BlockHead className="w-100 wide-sm">
            <BlockBetween className={"mb-4"}>
              <BlockHeadContent className="">
                <BlockTitle tag="h3" page>
                  {selected ? "Edit user role" : "Add user role"}
                </BlockTitle>
              </BlockHeadContent>
              <BlockHeadContent>
                <BackButton />
              </BlockHeadContent>
            </BlockBetween>
            <PreviewAltCard>
              <Form
                className="row gy-4 form-validation"
                onSubmit={handleSubmit(onFormSubmit)}
              >
                <Col md="6">
                  <div className="form-group">
                    <div className="form-control-wrap">
                      <FormInput
                        label={"Role name"}
                        type={FormInputType.default}
                        value={userRoleData?.name ?? ""}
                        placeholder={"e.g. Manager"}
                        onError={(isError) => {
                          setUserRoleData((prev) => ({
                            ...prev,
                            isNameError: isError,
                          }));
                        }}
                        onTextChange={(name) => {
                          setUserRoleData((prev) => ({
                            ...prev,
                            name: name,
                          }));
                        }}
                      />
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Role Type</label>
                    <div className="form-control-wrap">
                      <PermissionDropdown
                        value={userRoleData?.type ?? ""}
                        onSelect={(rType) => {
                          setUserRoleData((prev) => ({
                            ...prev,
                            type: rType,
                          }));
                        }}
                      />
                    </div>
                  </div>
                </Col>
                {userMaster?.map((userMasterItem, id) => (
                  <React.Fragment key={id}>
                    <Col md="12" className="mb-n2">
                      <div className="form-group">
                        <FormCheckbox
                          label={userMasterItem.name}
                          className="me-2"
                          name="status"
                          checked={
                            userRoleData?.moduleStatuses[
                              userMasterItem?.value
                            ] ?? false
                          }
                          onChange={(e) => {
                            let object = { ...userRoleData?.moduleStatuses };
                            if (e.target.checked) {
                              object[userMasterItem?.value] = [];
                              setUserRoleData({
                                ...userRoleData,
                                moduleStatuses: object,
                              });
                            } else {
                              delete object[userMasterItem?.value];
                              setUserRoleData({
                                ...userRoleData,
                                moduleStatuses: object,
                              });
                            }
                          }}
                        />
                      </div>
                    </Col>
                    <Row className="ms-2 mt-2">
                      {(userRoleData?.moduleStatuses[userMasterItem?.value] ??
                        false) &&
                        userMasterItem?.permissions?.map(
                          (permissionsItem, permissionsIndex) => {
                            const value = permissionsItem.value; // Assuming permissionId is the identifier for the permission
                            return (
                              <Col sm="3" md="2" key={permissionsIndex}>
                                <div className="form-group">
                                  <FormCheckbox
                                    className="me-1"
                                    label={permissionsItem?.name}
                                    name="status"
                                    checked={userRoleData?.moduleStatuses[
                                      userMasterItem?.value
                                    ]?.some((item) => item === value ?? false)}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        let updateuserRoleData = {
                                          ...userRoleData.moduleStatuses,
                                        };

                                        if (
                                          !updateuserRoleData[
                                            userMasterItem?.value
                                          ]?.includes(value)
                                        ) {
                                          updateuserRoleData[
                                            userMasterItem?.value
                                          ].push(value);
                                        }

                                        setUserRoleData({
                                          ...userRoleData,
                                          moduleStatuses: updateuserRoleData,
                                        });
                                      } else {
                                        let updateuserRoleData = {
                                          ...userRoleData.moduleStatuses,
                                        };
                                        const valueIndex =
                                          updateuserRoleData[
                                            userMasterItem?.value
                                          ]?.indexOf(value);

                                        if (valueIndex !== -1) {
                                          updateuserRoleData[
                                            userMasterItem?.value
                                          ].splice(valueIndex, 1);
                                        }

                                        setUserRoleData({
                                          ...userRoleData,
                                          moduleStatuses: updateuserRoleData,
                                        });
                                      }
                                    }}
                                  />
                                </div>
                              </Col>
                            );
                          }
                        )}
                    </Row>
                  </React.Fragment>
                ))}
                <Col md="12">
                  <div className="form-group">
                    <div className="form-control-wrap">
                      <RadioButtonOptions
                        name="status"
                        optionType={RadioOptionTypes.status}
                        onChange={(index) => {
                          setUserRoleData((prev) => ({
                            ...prev,
                            isActive: index === 0,
                          }));
                        }}
                        activeIndex={userRoleData.isActive ? 0 : 1}
                      />
                    </div>
                  </div>
                </Col>
                <Col md="12">
                  <ul className="align-center justify-content-end flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <li>
                      <ActionButton
                        title="Save"
                        loading={loading}
                        disabled={isDisble}
                      />
                    </li>
                  </ul>
                </Col>
              </Form>
            </PreviewAltCard>
          </BlockHead>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default AddUserRole;
