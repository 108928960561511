import React from "react";
import Button from "./Button";
import { Spinner } from "reactstrap";

const ActionButton = ({
  className,
  type,
  title,
  size,
  loading,
  disabled,
  ...props
}) => {
  return (
    <Button
      size={size}
      className={`btn-block ${
        disabled
          ? "btn-light"
          : size
          ? `custom-solid-btn-${size}`
          : "custom-solid-btn-sm"
      } ${className}`}
      color={disabled && "light"}
      disabled={loading || disabled}
      onClick={props.onClick}
      type={type}
    >
      {loading ? <Spinner size="sm" color="light" /> : title}
    </Button>
  );
};
export default ActionButton;

export const CancelButton = ({ onCancel, className, type, ...props }) => {
  return (
    <Button
      className={`btn-block btn-light ${className}`}
      onClick={onCancel}
      type={type}
      {...props}
    >
      Cancel
    </Button>
  );
};
export const DeleteButton = ({
  onDelete,
  className,
  title,
  type = "button",
  ...props
}) => {
  return (
    <Button
      className={`btn-block btn-danger ${className}`}
      onClick={onDelete}
      type={type}
      {...props}
    >
      {title ?? "Delete"}
    </Button>
  );
};
