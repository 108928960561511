import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { RSelect } from "../Component";

const LeaveReasonDropdown = ({
  value,
  isMulti = false,
  isShowAll = false,
  onSelect,
  selectedId,
  placeholder,
  ...props
}) => {
  const [list, setList] = useState();
  useEffect(() => {
    setList([
      { value: "LEAVE", label: "Leave" },
      { value: "DEATH", label: "Death" },
    ]);
  }, []);

  useEffect(() => {
    if (selectedId) {
      const selectedOption = list.find((option) => option.id === selectedId);
      if (selectedOption) {
        onSelect(selectedOption);
      }
    }
  }, [list]);

  return (
    <RSelect
      options={list ?? []}
      isMulti={isMulti}
      placeholder={placeholder}
      value={value ?? (isShowAll ? (list ? list[0] : null) : null)}
      onChange={(e) => {
        if (isShowAll === false) {
          onSelect(e);
        } else {
          e.id === 0 ? onSelect(undefined) : onSelect(e);
        }
      }}
      {...props}
    />
  );
};

export default LeaveReasonDropdown;
