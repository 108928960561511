import store from "../../store/store";
import { convertToNotNull } from "../../utils";
export const UserPermissionType = {
  staff: "staff",
  location: "location",
  userRole: "user role",
  resident: "resident",
  account: "account list",
  transaction: "transaction",
  reco: "reco",
  auditTrail: "audit trail",
};

export const UserActionType = {
  add: "add",
  edit: "edit",
  remove: "remove",
  delete: "delete",
  export: "export",
  print: "print",
  lock: "lock",
  unlock: "unlock",
  signoff: "signoff",
};

export function isAllowToAccess(type, action) {
  let roles = store.getState()?.auth?.sessionUser?.rolePermissions;
  if (!roles) return false;
  if (!roles[type]) return false;
  if (!roles[type].find((item) => item === action)) return false;
  return true;
}

export function isCheckToList(type) {
  let roles = store.getState()?.auth?.sessionUser?.rolePermissions;
  if (!roles) return false;
  if (!roles[type]) return false;
  return true;
}

export const menuItems = () => {
  let roles = store.getState()?.auth?.sessionUser?.rolePermissions ?? {};
  let menu = [];
 
  menu.push({
    text: "Dashboard",
    link: "/",
  });

  roles[UserPermissionType.resident] &&
    menu.push({
      text: "Resident",
      link: "/resident",
    });

  roles[UserPermissionType.account] &&
    menu.push({
      text: "Account",
      link: "/account",
    });

  roles[UserPermissionType.transaction] &&
    menu.push({
      text: "Transaction",
      link: "/transaction",
    });

  roles[UserPermissionType.reco] &&
    menu.push({
      text: "Reco",
      link: "/reco",
    });

  if (roles[UserPermissionType.staff] || roles[UserPermissionType.location] || roles[UserPermissionType.userRole]) {
    let submenu = [];

    roles[UserPermissionType.staff] &&
      submenu.push({
        text: "Staff",
        link: "/staff",
      });

    roles[UserPermissionType.location] &&
      submenu.push({
        text: "Location",
        link: "/location",
      });

      roles[UserPermissionType.userRole] &&
      submenu.push({
        text: "User Role",
        link: "/roles",
      });

    let item = {
      text: "Other",
      subMenu: convertToNotNull(submenu),
    };
    menu.push(item);
  }
  return menu;
};
