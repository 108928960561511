import { apiService } from "../../../services/api";
import { APIServicePath } from "../../../services/api/api-service-impl";

export function fetchAppSetting(params, callback) {
  apiService
    .postCall(APIServicePath.appSetting, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
