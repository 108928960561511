import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ActionButton, CancelButton } from "../Component";

export const AnnsModal = ({
  className,
  isOpen,
  toggle,
  title,
  loading,
  size,
  disabled,
  onClick,
  isFooterHide,
  ...props
}) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className={`modal-dialog-centered ${className}`}
      size={size}
    >
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>{props.children}</ModalBody>
      {!isFooterHide && (
        <ModalFooter>
          <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
            <li>
              {/* <button className="btn btn-primary" onClick={onSave}>
              Save
            </button> */}
              <CancelButton onCancel={toggle} />
            </li>
            <li>
              {/* <button className="btn btn-primary" onClick={onSave}>
              Save
            </button> */}
              <ActionButton
              
                title="Save"
                loading={loading}
                disabled={disabled}
                onClick={onClick}
              />
            </li>
          </ul>
        </ModalFooter>
      )}
    </Modal>
  );
};
