import React from "react";
import Select from "react-select";

const RSelect = ({ disabled, ...props }) => {
  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        fontFamily: "Poppins",
      };
    },

    singleValue: (styles, { data }) => ({
      ...styles,
      fontFamily: "Poppins",
    }),
  };
  return (
    <div className="form-control-select">
      <Select
        className={`react-select-container ${
          props.className ? props.className : ""
        }`}
        classNamePrefix="react-select"
        isDisabled={disabled}
        styles={colourStyles}
        {...props}
      />
    </div>
  );
};

export default RSelect;
