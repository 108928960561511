import React, { useState } from "react";
import UserAvatar from "../../../../components/user/UserAvatar";
import { DropdownToggle, DropdownMenu, Dropdown } from "reactstrap";
import { Icon } from "../../../../components/Component";
import { LinkList, LinkItem } from "../../../../components/links/Links";
import { handleSignout } from "../../../../utils/Utils";
import { Link, useHistory } from "react-router-dom";
import { Storage } from "../../../../services/storage/storage";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../../store/authSlice";
import { toCapitalize } from "../../../../utils/Functions";
import { PermissionType } from "../../../../components/dropdowns/PermissionDropdown";
import Setting from "../../../../pages/tabs/dashboard/Setting";
// import Setting from "../../../../pages/tabs/dashboard/Setting";

const User = () => {
  const sessionUser = useSelector((state) => state?.auth?.sessionUser);
  const userType = sessionUser?.userType;
  const role = sessionUser?.role;
  const location = sessionUser?.location;
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen((prevState) => !prevState);
  const user = Storage.getLoggedInUser();
  const history = useHistory();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [lowBalanceAlertAmount, setLowBalanceAlertAmount] = useState(0);

  function onLogout(e) {
    e.preventDefault();
    handleSignout();
    dispatch(logout());
    history.push(`${process.env.PUBLIC_URL}/auth-login`);
  }

  function getSubText() {
    return toCapitalize(role?.name ?? "");
  }

  return (
    <>
      <Dropdown isOpen={open} className="user-dropdown" toggle={toggle}>
        <DropdownToggle
          tag="a"
          href="#toggle"
          className="dropdown-toggle"
          onClick={(ev) => {
            ev.preventDefault();
          }}
        >
          <div className="user-toggle">
            <UserAvatar icon="user-alt" className="sm" />
          </div>
        </DropdownToggle>
        <DropdownMenu end className="dropdown-menu-md dropdown-menu-s1">
          <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
            <div className="user-card sm">
              <div className="user-info">
                <span className="lead-text">{user?.fullName ?? ""}</span>
                <span className="sub-text">{user?.email ?? ""}</span>
                <span className="sub-text">{getSubText()}</span>
              </div>
            </div>
          </div>
          <div className="dropdown-inner">
            <LinkList>
              <LinkItem
                link={"/check-password"}
                icon="setting-alt"
                onClick={toggle}
              >
                Change Password
              </LinkItem>
            </LinkList>
          </div>
          {userType !== PermissionType.staff && (
            <div className="dropdown-inner">
              <LinkList>
                <LinkItem link={"#"} icon="opt" onClick={() => setModal(true)}>
                  Settings
                </LinkItem>
              </LinkList>
            </div>
          )}
          <div className="dropdown-inner">
            <LinkList>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              {/* <a href={undefined} onClick={onLogout}>
                <Icon name="signout"></Icon>
                <span>Sign Out</span>
              </a> */}
              <LinkItem link={"#"} icon="signout" onClick={onLogout}>
                Sign Out
              </LinkItem>
            </LinkList>
          </div>
        </DropdownMenu>
      </Dropdown>
      <Setting
        item={"selectedItem"}
        lowBalanceAlertAmount={lowBalanceAlertAmount}
        visible={modal}
        onClosed={() => {
          setModal(false);
        }}
        onSaved={(data) => {
          setModal(false);
        }}
      />
    </>
  );
};

export default User;
