export const StorageKey = {
  authInfo: "com.anns.crm.auth.info",
  accessToken: "com.anns.crm.auth.access.token",
};

const BaseStorage = {
  getItem: (key) => {
    let result = localStorage.getItem(key);
    return JSON.parse(result);
  },
  setItem: (key, value) => {
    const item = JSON.stringify(value);
    return localStorage.setItem(key, item);
  },
  removeItem: (key) => {
    return localStorage.removeItem(key);
  },
  removeAll: () => {
    localStorage.clear();
  },
};

export const Storage = {
  setUserToken: (value) => {
    BaseStorage.setItem(StorageKey.accessToken, value);
  },
  getUserToken: () => {
    let value = BaseStorage.getItem(StorageKey.accessToken);
    return value;
  },
  setLoggedInUser: (value) => {
    BaseStorage.setItem(StorageKey.authInfo, value);
  },
  getLoggedInUser: () => {
    let value = BaseStorage.getItem(StorageKey.authInfo);
    return value;
  },
  clearAll() {
    BaseStorage.removeAll();
  },
};
