import React from "react";
import "./LoadingComponent.css";
import { Spinner } from "reactstrap";

export const LoadingComponent = ({ isLoading, isFade = true }) => {
  return (
    <>
      {isLoading && (
        <div className={`loading-overlay + ${isFade ? "" : " loading-overlay-fill-bg"}`}>
          <div className="spinner">
            <Spinner className="spinner-border-md" color="primary" />
          </div>
        </div>
      )}
    </>
  );
};

export default LoadingComponent;
