import React, { useState } from "react";
import { Modal, ModalBody, Button, ModalHeader, ModalFooter } from "reactstrap";
import { ActionButton, CancelButton, FormDatePicker } from "../Component";
import { useEffect } from "react";

const ClearDateModal = ({ isOpen, onClose, onSave, item }) => {
  const [selectedDate, setSelectedDate] = useState();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen && item) {
      setSelectedDate(item);
    }
  }, [item, isOpen]);

  const handleSave = () => {
    onSave(selectedDate);
  };

  const close = () => {
    onClose();
  };

  return (
    <Modal isOpen={isOpen} toggle={() => close()}>
      <ModalHeader toggle={() => close()}>Select Clear Date</ModalHeader>
      <ModalBody>
        <FormDatePicker
          selected={selectedDate}
          onChange={(date) => {
            setSelectedDate(date);
          }}
        />
      </ModalBody>
      <ModalFooter>
        <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
          <li>
            <CancelButton onCancel={() => close()} />
          </li>
          <li>
            <ActionButton
              title={"Save"}
              size="md"
              loading={loading}
              onClick={handleSave}
            />
          </li>
        </ul>
      </ModalFooter>
    </Modal>
  );
};

export default ClearDateModal;
