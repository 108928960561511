import React, { useEffect, useState } from "react";
import { RSelect } from "../Component";
import moment from "moment/moment";

export const MonthDropDown = ({ value, onSelect, placeholder, ...props }) => {
  const [list, setList] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  useEffect(() => {}, [value]);

  useEffect(() => {
    const statusValues = [
      { id: 1, label: "This Month", value: "THISMONTH" },
      { id: 2, label: "Last Month", value: "LASTMONTH" },
      { id: 3, label: "Last Three Months", value: "LASTTHREEMONTHS" },
      { id: 4, label: "Last Six Months", value: "LASTSIXMONTHS" },
      { id: 5, label: "Custom", value: "CUSTOM" },
    ];
    setList(statusValues);
  }, []);

  useEffect(() => {
    if (list && !value) {
      updateSelectedDateToClient(list[0]);
    }
  }, [value, list]);
  function getDates(type) {
    let startDate = null;
    let endDate = null;

    switch (type) {
      case "THISMONTH":
        // Get the start date of the current month
        startDate = moment().startOf("month").toDate();
        endDate = moment().toDate();
        break;
      case "LASTMONTH":
        // Calculate the start of the last month by subtracting one month
        startDate = moment().subtract(1, "months").startOf("month").toDate();

        // Calculate the end of the last month
        endDate = moment().subtract(1, "months").endOf("month").toDate();

        break;
      case "LASTTHREEMONTHS":
        startDate = moment().subtract(3, "months").startOf("month").toDate();

        // Calculate the end of the last month
        endDate = moment().subtract(1, "months").endOf("month").toDate();
        break;
      case "LASTSIXMONTHS":
        startDate = moment().subtract(6, "months").startOf("month").toDate();

        // Calculate the end of the last month
        endDate = moment().subtract(1, "months").endOf("month").toDate();

        break;
      case "CUSTOM":
        // code block

        break;
      default:
      // code block
    }

    let dates = {
      startDate,
      endDate,
    };
    return dates;
  }

  function updateSelectedDateToClient(e) {
    let dates = getDates(e.value);
    let object = e;
    object.startDate = dates.startDate;
    object.endDate = dates.endDate;
    onSelect(object);
  }

  return (
    <RSelect
      options={list ?? []}
      placeholder={placeholder}
      value={value}
      onChange={(e) => {
        updateSelectedDateToClient(e);
      }}
      {...props}
    />
  );
};
