export {
  RedirectAs404,
  setDateForPicker,
  windowReload,
  setDeadlineDays,
  findUpper,
  showSuccessToast,
  showErrorToast,
  formatPhoneNumber,
  dateToShow,
  dateStringToShowFormat,
  convertDropValue,
  covertToNumberOnly,
  convertNameToDropdownList,
  numberFormatter,
  convertToNotNull,
  toFindDuplicates,
} from "./Functions";
export {
  AuthPaths,
  NewPasswordType,
  PASSWORD_TOOL_TIP,
  ServerStatusType,
  RadioOptionTypes,
  TransactionMoneyType,
  ChequeStatusType,
} from "./Utils";
export { AppText } from "./AppText";

export const PHONE_REGX = /^[0-9]{3} [0-9]{3} [0-9]{4}$/i;
export const PASSWORD_REGX =
  /^(?=.*\d)(?=.*[!@#$%&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
export const EMAIL_REGX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const POSTALCODE_REGX = /^([A-Z]{1,2}\d{1,2}[A-Z]?)\s?(\d[A-Z]{2})$/i;
export const PER_PAGE_ITEMS = 10;
export const COUNTRY_CODE = "+44";
export const CURRENCY_CODE = "£";
export const DATE_FORMATE = "DD-MM-YYYY";
