import React from "react"; // Replace 'your-ui-library' with the actual UI library you are using
import Button from "./Button";
import { Icon } from "../Component";
const LockButton = ({ onClick, title, isUnlock, loading }) => {
  return (
    <>
      <Button
        color="primary"
        type="button"
        className="d-none d-sm-inline-flex me-1"
        onClick={onClick}
        loading={loading}
      >
        <>
          {isUnlock ? <Icon name="unlock" /> : <Icon name="lock" />}
          <span>{title}</span>
        </>
      </Button>
    </>
  );
};

export default LockButton;
