import Swal from "sweetalert2";

export const ConfirmationAlert = (title, message, onConfirmation) => {
  Swal.fire({
    title: title ?? "",
    text: message,
    // icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes",
  }).then((result) => {
    if (result.isConfirmed) {
      onConfirmation();
    }
  });
};
