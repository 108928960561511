import classNames from "classnames";
import React from "react";

const Icon = ({ name, id, className, style, color, ...props }) => {
  const iconClass = classNames({
    [`${className}`]: className,
    icon: true,
    ni: true,
    [`ni-${name}`]: true,
  });

  const combinedStyle = {
    ...style,
    color: color, // Apply the color
  };

  return <em className={iconClass} id={id} style={combinedStyle} {...props}></em>;
};

export default Icon;
