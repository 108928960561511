import React from "react";
import { useState } from "react";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import { AnnsBadge } from "../../../components/Component";
import { CURRENCY_CODE } from "../../../utils";

const CustomPopOver = ({ data, ...props }) => {
  const [mouseEnter, setMouseEnter] = useState(false);
  return (
    <React.Fragment>
      <div
        className=""
        id={`data-${data.transId}`}
        onMouseEnter={() => setMouseEnter(true)}
        onMouseLeave={() => setMouseEnter(false)}
      >
        {props.children}
      </div>{" "}
      <Popover
        placement="right"
        isOpen={mouseEnter}
        target={`data-${data.transId}`}
      >
        <PopoverHeader></PopoverHeader>
        <PopoverBody>
          <div className="d-flex flex-column justify-content-center">
            {data?.transactionAccounts?.map((item, index) => (
              <div className="d-flex align-items-center" key={index}>
                <span className="">{item?.label}</span>
                <span className="mx-1">•</span>
                <span>{CURRENCY_CODE + item?.amount}</span>
              </div>
            ))}
          </div>
        </PopoverBody>
      </Popover>
    </React.Fragment>
  );
};

export default CustomPopOver;
