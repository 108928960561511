import React from "react";
import { Route, Redirect } from "react-router-dom";
import { Storage } from "../services/storage/storage";
import { apiService } from "../services/api";


const auth = Storage.getUserToken();
apiService.setUserToken(auth);

const PrivateRoute = ({ exact, component: Component, ...rest }) => {
  return (
    <Route
      exact={exact ? true : false}
      rest
      render={(props) =>
        auth ? (
          <Component {...props} {...rest}></Component>
        ) : (
          <Redirect to={`${process.env.PUBLIC_URL}/auth-login`}></Redirect>
        )
      }
    ></Route>
  );
};

export default PrivateRoute;
