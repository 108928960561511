import { TranscationTypes } from "../../pages/tabs/Transaction/TranscationType";
import { apiService } from "../../services/api";
import { APIServicePath } from "../../services/api/api-service-impl";
import {
  CURRENCY_CODE,
  ServerStatusType,
  convertDropValue,
  numberFormatter,
} from "../../utils";

export function rolesDropDownData(params, callback) {
  apiService
    .postCall(APIServicePath.roleList, params)
    .then((data) => {
      let list = [];
      for (let index = 0; index < data.list?.length ?? 0; index++) {
        const element = data.list[index];
        list.push(convertDropValue(element));
      }
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function locationDropdownData(callback) {
  apiService
    .postCall(APIServicePath.listLocation, { status: ServerStatusType.active })
    .then((data) => {
      let list = [];
      for (let index = 0; index < data.list?.length ?? 0; index++) {
        const element = data.list[index];

        list.push({
          id: element.id,
          label: element.locationName,
          value: element.locationName,
        });
      }
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function accountDropdownData(
  searchText,
  accountTypes,
  locationId,
  callback
) {
  apiService
    .postCall(APIServicePath.accountList, {
      search: searchText,
      locationId: locationId,
      accountType: accountTypes,
    })
    .then((data) => {
      let list = [];
      for (let index = 0; index < data.list?.length ?? 0; index++) {
        const element = data.list[index];
        let name =
          element.accountType === TranscationTypes.resident && element.resident
            ? element.resident.firstName + " " + element.resident.lastName
            : element.accountType !== TranscationTypes.resident
            ? element.accountName
            : null;
        let label =
          element.accountType === TranscationTypes.resident && element.resident
            ? element.resident.firstName +
              " " +
              element.resident.lastName +
              " " +
              `(${numberFormatter(CURRENCY_CODE, "" + element?.balance ?? "")})`
            : element.accountType !== TranscationTypes.resident
            ? element.accountName +
              " " +
              `(${numberFormatter(CURRENCY_CODE, "" + element?.balance ?? "")})`
            : null;
        if (name) {
          list.push({
            id: element.accountId,
            label: label,
            value: name,
            accountType: element?.accountType,
            balance: element?.balance,
          });
        }
      }
      list.sort((a, b) => (a.label > b.label ? 1 : -1));
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

// converter ------------------->
export const convertAccountDropValue = (data) => {
  let name =
    data.type === TranscationTypes.resident
      ? data?.resident?.firstName + " " + data?.resident?.lastName
      : data?.name;
  let label =
    data.type === TranscationTypes.resident
      ? data?.resident?.firstName +
        " " +
        data?.resident?.lastName +
        " " +
        `(${numberFormatter(CURRENCY_CODE, "" + data?.balance ?? "")})`
      : data?.name +
        " " +
        `(${numberFormatter(CURRENCY_CODE, "" + data?.balance ?? "")})`;
  let object = {
    id: data?.id,
    label: label,
    value: name,
    accountType: data?.type,
    balance: data?.balance,
  };
  return object;
};
