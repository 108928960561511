import { useDispatch } from "react-redux";
import { apiService } from "../../services/api";
import { APIServicePath } from "../../services/api/api-service-impl";
import { Storage } from "../../services/storage/storage";

export function signIn(data, callback) {
  apiService
    .postPublicCall(APIServicePath.signin, data)
    .then((data) => {
      apiService.handleAuthResponse(data);
      let _ = toAuthorization(data);
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function resetPasssword(data, callback) {
  apiService
    .postPublicCall(APIServicePath.forgotPassword, data)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function checkPassword(data, callback) {
  apiService
    .postCall(APIServicePath.checkPassword, data)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
export function changePassword(data, callback) {
  apiService
    .postCall(APIServicePath.changePassword, data)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function recoverNewPassword(data, callback) {
  apiService
    .postPublicCall(APIServicePath.recoverPassword, data)
    .then((auth) => {
      callback(auth, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function verifyResetPasswordToken(data, callback) {
  apiService
    .postPublicCall(APIServicePath.verifyResetPasswordToken, data)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function resendVerifyEmail(data, callback) {
  apiService
    .postPublicCall(APIServicePath.resendEmail, data)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function generateSession(data, callback) {
  apiService
    .postPublicCall(APIServicePath.generateSession, data)
    .then((data) => {
      apiService.handleAuthResponse(data);
      let user = toAuthorization(data);
      callback(user, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function fetchUserInfo(callback) {
  apiService
    .fetchCurrentUser()
    .then((list) => {
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
export async function fetchCurrentUser() {
  return apiService.postCall(APIServicePath.currentUser);
}
export async function fetchMasterList() {
  let params = {
    dataType: ["GRADE", "GENDER", "SHIFT_TIME"],
  };
  return apiService.postCall(APIServicePath.masterList, params);
}

//*******************************DATA CONVERTER******************************************/

function toAuthorization(authResponse) {
  let accessToken = authResponse.accessToken ?? null;
  let user = toUser(authResponse.sessionUser);
  Storage.setLoggedInUser(user);
  Storage.setUserToken(accessToken);
  return user;
}

function toUser(response) {
  let user = {};
  user.id = response.userId;
  user.userType = response.userType;
  user.email = response.email;
  user.firstName = response.firstName;
  user.lastName = response.lastName;
  user.fullName = response.firstName + " " + user.lastName;
  user.isEmailVerified = response.isEmailVerified;
  return user;
}
