import React, { useState, useEffect } from "react";

import {
  Block,
  BlockDes,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  StatusActiveDropDown,
  ActionButton,
  PreviewCard,
  ReactDataTable,
  LocationDropDown,
  FormInput,
  FormDatePicker,
  LoadingComponent,
} from "../../../components/Component";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import AddResident from "./AddResident";
import {
  ResidentTableData,
  fetchLocationDetail,
  fetchResidentExport,
  fetchResidentList,
} from "./ResidentData";
import {
  CURRENCY_CODE,
  PER_PAGE_ITEMS,
  ServerStatusType,
  numberFormatter,
} from "../../../utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useSelector } from "react-redux";
import ExportButton from "../../../components/button/ExportButton";
import CustomButton from "../../../components/button/CustomButton";
import { CustomButtonTypes, EXPORT_TYPE } from "../../../utils/Utils";
import { TranscationTypes } from "../Transaction/TranscationType";
import moment from "moment";
import { openLinkInNewTab } from "../../../utils/Functions";
import {
  UserActionType,
  UserPermissionType,
  isAllowToAccess,
  isCheckToList,
} from "../../../services/permission/permission";

const Resident = () => {
  const history = useHistory();
  const sessionUser = useSelector((state) => state?.auth?.sessionUser);
  const location = useSelector((state) => state?.auth?.currentLocation);

  const [modal, setModal] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [initScreen, setInitScreen] = useState(false);
  const [searchText, setSearchText] = useState();
  const [totalItems, setTotalItems] = useState(0);
  const [residentList, setResidentList] = useState();
  const [status, setStatus] = useState();
  const [locationDetails, setLocationDetails] = useState();
  const [date, setDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [sortId, setSortId] = useState();
  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    return () => {
      setResidentList();
      setModal();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadResidentList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    loadList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, searchText, date, sortId, sortOrder]);

  useEffect(() => {
    // setInitScreen(true);
    loadScreenData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const loadScreenData = () => {
    loadList();
    loadLocationDetail();
  };

  const loadList = () => {
    if (currentPage === 1) {
      loadResidentList();
    } else {
      setCurrentPage(1);
    }
  };

  const onEdit = (row) => {
    setModal(true);
    setSelectedItem(row);
  };

  // API-------------------------------------------->

  const loadResidentExport = (fType) => {
    let params = {
      locationId: location ? location.id : null,
      search: searchText ?? null,
      status: status?.value ?? ServerStatusType.active,
      date: moment(date).format("YYYY-MM-DD"),
    };
    let fileType = fType;
    fetchResidentExport(fileType, params, (data, error) => {
      setIsLoading(false);
      if (error === null) {
        openLinkInNewTab(data?.fileUrl);
      }
    });
  };

  const loadResidentList = () => {
    if (!location) return;
    setInitScreen(true);
    let params = {
      pageInfo: {
        pageIndex: currentPage,
        itemsPerPage: PER_PAGE_ITEMS,
      },
      sorting: {
        field: sortId ?? null,
        order: sortOrder ?? null,
      },
      search: searchText ?? null,
      status: status?.value ?? ServerStatusType.active,
      locationId: location ? location.id : null,
      date: moment(date).format("YYYY-MM-DD"),
    };
    isCheckToList(UserPermissionType.resident) &&
      fetchResidentList(params, (data, error) => {
        // setInitScreen(true);
        if (error === null) {
          setResidentList(data?.list);
          if (currentPage === 1) {
            setTotalItems(data?.pageInfo?.totalItems ?? 0);
          }
        }
        setInitScreen(false);
      });
  };
  const loadLocationDetail = () => {
    if (!location) return;
    let params = {
      locationId: location?.id ?? sessionUser?.location?.id,
    };
    fetchLocationDetail(params, (data, error) => {
      if (error === null) {
        setLocationDetails(data);
      }
    });
  };

  let cash = locationDetails?.accounts?.find(
    (item) => item?.type === TranscationTypes.cash
  );
  let bank = locationDetails?.accounts?.find(
    (item) => item?.type === TranscationTypes.bank
  );

  let totalActiveResidentBalance =
    locationDetails?.totalActiveResidentBalance ?? 0;

  let totalInactiveResidentBalance =
    locationDetails?.totalInactiveResidentBalance ?? 0;

  let totalResidentBalance = locationDetails?.totalResidentBalance ?? 0;

  let balanceToShow = 0;
  const defaultStatus = { value: ServerStatusType.active, label: "Active" };
  if (!status) {
    setStatus(defaultStatus);
  } else {
    if (status?.value === ServerStatusType.active) {
      balanceToShow = totalActiveResidentBalance;
    } else if (status?.value === ServerStatusType.inactive) {
      balanceToShow = totalInactiveResidentBalance;
    } else {
      balanceToShow = totalResidentBalance;
    }
  }

  return (
    <React.Fragment>
      <Head title="Resident" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Resident</BlockTitle>
              <BlockDes>
                <Block className={""}></Block>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="d-flex align-items-center">
                <div className="d-flex flex-column  me-2">
                  {isAllowToAccess(
                    UserPermissionType.resident,
                    UserActionType.export
                  ) && (
                    <span className="">
                      <ExportButton
                        title={"Export"}
                        options={EXPORT_TYPE}
                        onChange={(data) => {
                          loadResidentExport(data);
                        }}
                      />
                    </span>
                  )}
                </div>
                <div className="d-flex flex-column me-2">
                  {isAllowToAccess(
                    UserPermissionType.resident,
                    UserActionType.print
                  ) && (
                    <span className="">
                      <CustomButton
                        label=""
                        type={CustomButtonTypes.Print}
                        loading={isLoading}
                        onClick={() => {
                          setIsLoading(true);
                          loadResidentExport();
                        }}
                      />
                    </span>
                  )}
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <div className="d-flex flex-wrap justify-content-between align-items-center">
            <div className="mb-2 mb-lg-0">
              <div className="d-flex align-items-center flex-wrap">
                <div className="d-flex flex-column  me-2">
                  <span className="resident-block">
                    <span className="fw-medium text-success fs-16px">
                      {/* {numberFormatter(
                        CURRENCY_CODE,
                        "" + (parseFloat(cash) + parseFloat(bank))
                      )} */}
                      {/* {status === status?.value ?? ServerStatusType.active
                        ? numberFormatter(
                            CURRENCY_CODE,
                            "" + totalActiveResidentBalance
                          )
                        : numberFormatter(
                            CURRENCY_CODE,
                            "" + totalInactiveResidentBalance
                          )} */}
                      {numberFormatter(CURRENCY_CODE, "" + balanceToShow)}
                    </span>
                    <span className=" fs-11px">Balance</span>
                  </span>
                </div>
                <div className="d-flex flex-column  me-2">
                  <span className="resident-block">
                    <span className="fw-medium text-success fs-16px">
                      {numberFormatter(
                        CURRENCY_CODE,
                        "" + cash?.balance ?? cash?.openingBalance
                      )}
                    </span>

                    <span className=" fs-11px">Cash</span>
                  </span>
                </div>
                <div className="d-flex flex-column  me-2">
                  <span className="resident-block ">
                    <span className=" fw-medium text-success fs-16px">
                      {numberFormatter(
                        CURRENCY_CODE,
                        "" + bank?.balance ?? bank?.openingBalance
                      )}
                    </span>
                    <span className=" fs-11px">Bank</span>
                  </span>
                </div>
                <div className="d-flex flex-column  ">
                  <span className="resident-block">
                    <span className="fw-medium text-success fs-16px">
                      {`${
                        (locationDetails?.totalBeds ?? 0) -
                        (locationDetails?.totalActiveBeds ?? 0)
                      }`}
                    </span>
                    <span className=" fs-11px">Vacant Beds</span>
                  </span>
                </div>
              </div>
            </div>
            <div className="d-flex  align-items-center">
              <div className="d-flex flex-column me-2">
                <label className="">Search</label>
                <span className="">
                  <FormInput
                    labelGroupClass="m-0"
                    value={searchText ?? ""}
                    placeholder="Search "
                    inputClass={"h-36"}
                    onTextChange={(text) => setSearchText(text)}
                  />
                </span>
              </div>
              <div className="d-flex flex-column  me-2">
                <label className="">Closing Balance as on</label>
                <span className="">
                  <FormDatePicker
                    selected={date}
                    onChange={(selectedDate) => {
                      setDate(selectedDate);
                    }}
                    maxDate={new Date()}
                  />
                </span>
              </div>
              <div className="d-flex flex-column  me-2">
                <label className="">Status</label>
                <span className="">
                  <StatusActiveDropDown
                    value={status}
                    isShowAll={true}
                    placeholder={"Status"}
                    className="w-100px "
                    onSelect={(e) => {
                      setStatus(e);
                    }}
                  />
                </span>
              </div>
              <div className="d-flex flex-column  ">
                <span>&nbsp;</span>
                {isAllowToAccess(
                  UserPermissionType.resident,
                  UserActionType.add
                ) && (
                  <span className="">
                    <ActionButton
                      title="Add Resident"
                      size="md"
                      onClick={() => setModal(true)}
                    />
                  </span>
                )}
              </div>
            </div>
          </div>
        </Block>
        <Block>
          <PreviewCard>
            <ReactDataTable
              data={residentList}
              columns={ResidentTableData(false, onEdit)}
              isAllowed={isCheckToList(UserPermissionType.resident)}
              expandableRows
              totalItemCount={totalItems}
              pagination
              onPageChanged={(page, count) => {
                setCurrentPage(page);
              }}
              onSort={(id) => {
                setSortId(id);
                const newSortOrder = sortOrder === "desc" ? "asc" : "desc";
                setSortOrder(newSortOrder);
              }}
            />
          </PreviewCard>
        </Block>
      </Content>
      <AddResident
        item={selectedItem}
        visible={modal}
        onClosed={() => {
          setModal(false);
          setSelectedItem();
        }}
        onSaved={() => {
          setModal(false);
          setSelectedItem();
          loadScreenData();
        }}
      />
      <LoadingComponent isLoading={initScreen} />
    </React.Fragment>
  );
};

export default Resident;
