import React from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  BackButton,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
} from "../../../components/Component";
import AuditTrailDatatable from "./AuditTrailDatatable";
import { useState } from "react";
import { DateRangePicker } from "../../../components/input/date-time/DateRangePicker";
import { MonthDropDown } from "../../../components/dropdowns/MonthDropDown";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const AuditTrail = () => {
  const [month, setMonth] = useState();
  const history = useHistory();

  return (
    <React.Fragment>
      <Head title="Audit Trail" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Audit Trail</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="d-flex align-items-center">
                {month?.value === "CUSTOM" && (
                  <div className="form-group mx-1 my-0">
                    <div className="form-control-wrap">
                      <DateRangePicker
                        start={month?.startDate}
                        end={month?.endDate}
                        dateFormat="dd-MM-yyyy"
                        maxDate={new Date(month?.startDate)?.setFullYear(
                          new Date(month?.startDate).getFullYear() + 1
                        )}
                        onChangeStart={(date) => {
                          setMonth({
                            ...month,
                            startDate: date,
                            endDate: date,
                          });
                        }}
                        onChangeEnd={(date) => {
                          setMonth({ ...month, endDate: date });
                        }}
                      />
                    </div>
                  </div>
                )}
                <span className="me-1">
                  <MonthDropDown
                    value={month}
                    className="w-150px "
                    onSelect={(e) => {
                      if (e.value === "CUSTOM") {
                        setMonth((previous) => ({
                          ...previous,
                          startDate: previous.startDate,
                          endDate: previous.endDate,
                          label: e.label,
                          value: e.value,
                        }));
                      } else {
                        setMonth(e);
                      }
                    }}
                  />
                </span>
                <BackButton backUrl={`${process.env.PUBLIC_URL}/transaction`} />
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <AuditTrailDatatable
            startDate={month?.startDate}
            endDate={month?.endDate}
            onRowClicked={(row) => {
              history.push({
                pathname: `${process.env.PUBLIC_URL}/add-transaction`,
                state: {
                  TransactionId: row?.transId,
                  isEditTransaction: true,
                  isAuditTrailScreen: true,
                },
              });
            }}
          />
        </Block>
      </Content>
      {/* <LoadingComponent isLoading={initScreen} /> */}
    </React.Fragment>
  );
};

export default AuditTrail;
