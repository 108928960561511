import React from "react";
import { Card } from "reactstrap";
import { Icon } from "../../Component";

const DataCard = ({ title, amount, backgroundcolor, percentChange, up }) => {
  return (
    <Card className={`bg-${backgroundcolor} text-white`}>
      <div className="nk-ecwg nk-ecwg6">
        <div className="card-inner">
          <div className="card-title-group">
            <div className="card-title">
              <h6 className="title">{title}</h6>
            </div>
          </div>
          <div className="data mt-2">
            <div className="data-group">
              <div className="amount fs-1">{amount}</div>
            </div>
            {/* <div className="info">
              <span
                className={`change ${
                  up ? "up text-success" : "down text-danger"
                }`}
              >
                <Icon name={`arrow-long-${up ? "up" : "down"}`}></Icon>
                {percentChange}%
              </span>
              <span> vs. last week</span>
            </div> */}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default DataCard;
